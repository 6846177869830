<div class="page-content-wrapper">
  <!-- Toaster -->
  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="('calibration.TITLE' | translate)" [adminBack]="true"
    [hasTabs]="false" [adminPageSubtitle]="('calibration.USER_LIST' | translate)"
    [adminActionLabel]="('calibration.DOWNLOAD_REPORT' | translate)" (onAdminAction)="downloadReport()"
    [adminActionDisabled]="isDownloadingReport" adminActionLabel="assets/img/icons/file/xls.svg"
    adminActionLabelType="success"></header-dossier>
  <div class="main-content-wrapper">
    <div class="admin-table-container column-spacing">

      <p class="section-title">{{ ('calibration.USER_LIST_IN_PROCESS' | translate) }}</p>
      <!-- anagrafica -->
      <div class="gray-box-container">
        <ng-container *ngIf="!isLoadingCounters && counterData">
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.usersSelfAssessmentNotSentCount }}</p>
              <p class="description" translate="calibration.HAS_TO_SEND_SELF_ASSESSMENT_COUNTER"></p>
            </div>
          </div>
          <!-- Inclusi -->
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.usersAppraisalNotSentCount }}</p>
              <p class="description" translate="calibration.COUNTER_CALIBRATION"></p>
            </div>
          </div>
          <!-- Esclusi -->
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.postCalibrationNotSentCount }}</p>
              <p class="description" translate="calibration.COUNTER_PERFORMANCE_LEVEL"></p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoadingCounters">
          <div class="loader-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
      </div>

      <!-- Filtri -->
      <anag-filters (searchedTextChanged)="searchedTextChanged($event)" [isLoadingUsers]="isLoadingUsers"
        (updateSelectedFilters)="updateSelectedFilters($event)"
        (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getUsersList($event)"
        [showManager]="true">
      </anag-filters>

      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingTranslations && !isLoadingUsers && usersList && usersList.length">

        <div class="table-content ">
          <table>
            <thead>
              <tr>
                <th>
                  <alloy-check (onCheck)="selectAll()"></alloy-check>
                </th>
                <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                <!--th translate="{{ ('adminTable.CID' | translate)}}"></th-->
                <th translate="{{ ('calibration.TEAM_MEMBERS' | translate)}}"></th>
                <th class="center" translate="{{ ('calibration.SELF_ASSESSMENT_SCHEDULE' | translate)}}"></th>
                <th class="center" translate="{{ ('calibration.APPRISAL_SCHEDULE' | translate)}}"></th>
                <th class="center" translate="adminTable.POST_CALIBRATION_SCHEDULE"></th>
                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of usersList | 
                          slice:0:usersNumRecords | 
                          paginate: { id: 'userList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                <td>
                  <alloy-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></alloy-check>
                </td>
                <td>{{ user.surname + ' ' + user.forename }}</td>
                <!--td>{{ user.cid || '' }}</td-->
                <td>
                  <div class="team-filter">
                    <ng-container *ngIf="user.membersCount > 0">
                      {{user.membersCount}}
                    </ng-container>
                    <ng-container *ngIf="!user.membersCount">
                      <p translate="calibration.NONE"></p>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div class="status-evaluation">
                    <ng-container *ngIf="!user.selfAssessmentReceived">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'monitoring.NOT_RECEIVED' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.selfAssessmentReceived">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'monitoring.RECEIVED' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </div>
                </td>
                <!-- Scheda appraisal -->
                <td>
                  <div class="status-evaluation">
                    <ng-container *ngIf="!user.appraisalReceived">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'monitoring.NOT_RECEIVED' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.appraisalReceived">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'monitoring.RECEIVED' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </div>
                </td>
                <!-- Scheda post calibration -->
                <td>
                  <div class="status-evaluation" (click)="openScheduleProgressModal()">
                    <ng-container *ngIf="user.postCalibrationStatus == 'WAITING'">
                      <item-list-team-status customContainerClass="min-width-unset pointer" styleItem="border"
                        type="disabled" [message]="'monitoring.WAITING' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.postCalibrationStatus == 'TO_CHECK'">
                      <item-list-team-status customContainerClass="min-width-unset pointer" styleItem="border"
                        type="toStart" [message]="'monitoring.TO_SEND' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.postCalibrationStatus == 'TO_SEND'">
                      <item-list-team-status customContainerClass="min-width-unset pointer" styleItem="border"
                        type="inProgress" [message]="'monitoring.TO_SEND' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.postCalibrationStatus == 'SENT'">
                      <item-list-team-status customContainerClass="min-width-unset pointer" styleItem="border"
                        type="completed" [message]="'monitoring.SENT' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </div>
                </td>
                <td class="action">
                  <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                    (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                  </svg-icon>
                  <ng-container *ngIf="user.isMenuOpen">
                    <div class="dropdown-menu-table">
                      <ng-container *ngFor="let item of menuOptions">
                        <alloy-dropdown-item (click)="onMenuOptionsClicked(item, user)" class="full-width"
                          [item]="item">
                        </alloy-dropdown-item>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="bottom-actions">
          <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </alloy-select>
          </div>
        </div>
        <div class="button-actions sticky" *ngIf="selectedUserList && selectedUserList.length > 1">
          <div class="selected-container">
            <p class="selected-count">{{ selectedUserList.length || 0}}</p>
            <p class="selected-count-label" translate="generic.SELECTEDS"></p>
          </div>
          <!-- Compara nella matrice -->
          <alloy-button [label]="'calibration.COMPARE_MATRIX' | translate" type="success" [disabled]="!canGoToMatrix()"
            (onButtonClicked)="goToMatrix(false)"></alloy-button>
          <!-- Invia schede -->
          <alloy-button [label]="'calibration.SEND_CARDS' | translate" type="secondary"
            (onButtonClicked)="openMassiveSend()" [disabled]="!canConfrimUserList()">
          </alloy-button>
        </div>

      </ng-container>

      <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
        <div class="admin-no-data-wrapper">
          <p translate="generic.NO_DATA_FOUND"></p>
        </div>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsers || isLoadingTranslations">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--modal id="recapConfirm" (onCancel)="closeConfirmModal()" (onClose)="closeConfirmModal()"
  (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
</modal-->
<modal id="massive-send" (onClose)="closeMassiveSend()"
  [confirmLabel]="selectedUserList.length <= 1 ? ('verifySend.modals.SEND_ACTION' | translate) : ('verifySend.modals.SEND_ACTION_MANY' | translate)"
  (onConfirm)="closeMassiveSend(true)" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeMassiveSend()">

  <ng-container *ngIf="selectedUserList && selectedUserList.length > 1">
    <modal-text-content [modalId]="'ms002'" [title]="'verifySend.modals.SEND_TITLE_MANY' | translate"
      [subtitle]="'verifySend.modals.SEND_SUBTITLE_MANY' | translate" [text]="massiveModalText"></modal-text-content>
  </ng-container>

  <!-- Se singolo utente -->
  <ng-container *ngIf="selectedUserList && selectedUserList.length <= 1 && massiveModalData">
    <modal-text-content modalId="ms001" [title]="'verifySend.modals.SEND_TITLE' | translate"
      [subtitle]="'verifySend.modals.SEND_SUBTITLE' | translate" [text]="'verifySend.modals.SEND_DESCR' | translate">
    </modal-text-content>
  </ng-container>
</modal>

<modal id="schedule-progress-status" modalId="mul001" (onClose)="closeScheduleProgressModal()">
  <p class="modal-title">
    {{ ('monitoring.PROGRESS_STATUS' | translate)}}</p>

  <div class="status-list-container">
    <div class="status-section">
      <item-list-team-status styleItem="border" type="disabled" [message]="'monitoring.WAITING'">
      </item-list-team-status>
      <p translate="monitoring.WAITING_DESCR"></p>
    </div>
    <div class="status-section">
      <item-list-team-status styleItem="border" type="toStart" [message]="'monitoring.TO_SEND'"></item-list-team-status>
      <p translate="monitoring.TO_CHECK_DESCR"></p>
    </div>
    <div class="status-section">
      <item-list-team-status styleItem="border" type="inProgress" [message]="'monitoring.TO_SEND'">
      </item-list-team-status>
      <p translate="monitoring.TO_SEND_DESCR"></p>
    </div>
    <div class="status-section">
      <item-list-team-status styleItem="border" type="completed" [message]="'monitoring.SENT'"></item-list-team-status>
      <p translate="monitoring.SENT_DESCR"></p>
    </div>
  </div>

  <div class="modal-action-container single-button">
    <alloy-button [label]="'generic.CLOSE' | translate" type="success" (onButtonClicked)="closeScheduleProgressModal()">
    </alloy-button>
  </div>
</modal>

<modal id="reopen-schedule" (onClose)="closeReopenScheduleModal()"
  [confirmLabel]="'monitoring.modals.REOPEN' | translate" (onConfirm)="closeReopenScheduleModal(true)"
  [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeReopenScheduleModal()">
  <ng-container *ngIf="reopenModal && reopenModal.text">
    <modal-text-content modalId="re001" [title]="reopenModal.title" [subtitle]="reopenModal.subtitle"
      [text]="reopenModal.text">
    </modal-text-content>
  </ng-container>
</modal>