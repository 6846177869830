import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as moment from 'moment';
import { ErrorCodes, PerformanceAssessmentAlloyStatuses, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
@Component({
    selector: 'app-admin-create-update-process',
    templateUrl: './createUpdateProcess.component.html',
    styleUrls: ['./createUpdateProcess.component.scss']
})
export class CreateUpdateProcessComponent implements OnInit {
    runningYear$: Subscription = new Subscription();
    runningYear: any;
    processId: string = '';
    isLoadingProcess: boolean = true;

    processName: string = '';
    processNameCopy: string = '';

    semesterOptions: any;
    selectedSemester: any;
    selectedYearCopy: any;

    processStatus!: string;
    processYear!: number;
    processYearCode: string = '';
    readonly: boolean = false;

    statusOptions: any;
    selectedStatus: any;
    selectedStatusCopy: any;

    notes: string = '';
    notesCopy: string = '';
    isUpdate: boolean = false;
    getProcessData$: Subscription = new Subscription;


    constructor(
        public translate: TranslateService,
        public adminService: AdminService,
        public redirectService: RedirectService,
        private store: Store<fromApp.AppState>,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
            if (this.runningYear) {
                this.route.params
                    .subscribe(
                        (params: Params) => {
                            this.processId = params.processId;
                            this.processYear = params.processYear;
                            this.processYearCode = params.processYearCode;
                            if (!this.processId) {
                                this.isLoadingProcess = false;
                            } else {
                                this.readonly = true;
                                this.getProcessData();
                            }
                        }
                    );
            }
        });
    }

    ngOnInit() {
        this.translate.get(
            [
                'process.status.ACTIVE',
                'process.status.STANDBY',
                'process.status.CLOSED'
            ])
            .subscribe(translations => {
                this.processStatus = translations['process.status.STANDBY'];

                this.semesterOptions = [];
                this.getDataSemester();
                this.statusOptions = [
                    {
                        id: PerformanceAssessmentAlloyStatuses.RUNNING,
                        title: translations['process.status.ACTIVE']
                    },
                    {
                        id: PerformanceAssessmentAlloyStatuses.STANDBY,
                        title: translations['process.status.STANDBY']
                    },
                    {
                        id: PerformanceAssessmentAlloyStatuses.HISTORY,
                        title: translations['process.status.CLOSED']
                    }
                ];
                if (!this.processId) {
                    // this.selectedStatus = this.statusOptions[1];
                    this.isLoadingProcess = false;
                } else {
                    this.isUpdate = true;
                    this.getProcessData();
                }
            })

    }

    nameChanged(text: string) {
        this.processName = text;
    }

    changeSemester(value: any) {
        this.selectedSemester = value;
    }

    changeStatus(statusItem: any) {
        this.selectedStatus = statusItem;
    }

    isProcessValid() {
        if (this.processId) {
            return this.selectedStatusCopy.id == PerformanceAssessmentAlloyStatuses.HISTORY ? true : false;
        } else {
            return (this.processName != undefined && this.selectedSemester != undefined) ? false : true
        }
    }

    noteChanged(text: string) {
        this.notes = text;
    }

    getProcessData() {
        this.isLoadingProcess = true;

        if (this.getProcessData$) {
            this.getProcessData$.unsubscribe();
        }

        this.getProcessData$ = this.adminService.getProcess(this.processId)
            .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "057",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
                } else {
                    this.processName = data.response.processTitle;
                    let year = data.response.processYear;
                    let code = data.response.processYearCode;
                    for (let i = 0; i < this.semesterOptions.length; i++) {
                        if (this.semesterOptions[i].year == year && this.semesterOptions[i].code == code) {
                            this.selectedSemester = this.semesterOptions[i];
                            break;
                        }
                    }

                    this.selectedStatus = this.statusOptions.find((status: any) => status.id == data.response.processStatus);
                    this.selectedStatusCopy = {... this.selectedStatus};

                    this.statusOptions[0].disabled = true;
                    this.statusOptions[1].disabled = true;
                    if (data.response.processStatus == "HISTORY") {
                        this.statusOptions[2].disabled = true;
                    }
                }
                this.isLoadingProcess = false;
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "058",
                    text: this.translate.instant("errors." + ((err && err.message) || err)),
                    title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingProcess = false;
            });
    }


    // Gestisce quando crei/aggiorni un'evidenza
    goToUpdateProcess(isBack?: boolean) {
        this.openUpdateModal();
    }

    openUpdateModal() {
        this.modalService.open('processUpdateModal');
    }

    closeUpdateModal() {
        this.modalService.close('processUpdateModal');
    }

    createUpdate() {
        if (this.processId) {
            this.modalService.open('update-process');
        } else {
            this.modalService.open('create-process');
        }
    }

    closeCreateProcess(confirm?: boolean) {
        this.modalService.close('create-process');
        if (confirm) {
            this.isLoadingProcess = true;
            this.adminService.createProcess(this.selectedSemester.year, this.selectedSemester.code, this.processName)
                .subscribe((data: SenecaResponse<any>) => {
                    if (data && data.error) {
                        let messageObj: ApplicationModalMessage;
                        if (data.error == ErrorCodes.PERFALLOY_CANNOT_CREATE_ASSESSMENT_BEFORE_RUNNING_PROCESS) {
                            // Vedo se c'è la traduzione dell'errore
                            messageObj = {
                                modalId: "a003",
                                text: this.translate.instant("process.create.ERROR_INVALID_SEMESTER"),
                                title: this.translate.instant("generic.WARNING")
                            }
                        } else {
                            // Vedo se c'è la traduzione dell'errore
                            messageObj = {
                                modalId: "a003",
                                text: this.translate.instant("errors." + data.error),
                                title: this.translate.instant("generic.WARNING")
                            }
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    } else {
                        // toaster 
                        let x: any = document.getElementById("snackbar");
                        if (x) {
                            x.className = "show";
                            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
                        }
                    }
                    this.redirectService.goBackBrowser();
                }, (err) => {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "a004",
                        text: this.translate.instant("errors." + ((err && err.message) || err)),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                })
        }
    }


    createProcess() {
        if (this.processName == undefined ||
            this.processStatus == undefined ||
            this.selectedSemester == undefined) {
            return;
        }
    }

    updateProcess() {
    }

    closeUpdateProcess(confirm?: boolean) {
        this.modalService.close('update-process');
        if (confirm) {
            this.isLoadingProcess = true;
            this.adminService.updateProcess(this.processYear, this.processYearCode, this.selectedStatus.id)
                .subscribe((data: SenecaResponse<any>) => {
                    if (data && data.error) {
                        // Vedo se c'è la traduzione dell'errore
                        const messageObj: ApplicationModalMessage = {
                            modalId: "a003",
                            text: this.translate.instant("errors." + data.error),
                            title: this.translate.instant("generic.WARNING")
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    } else {
                        // toaster 
                        let x: any = document.getElementById("snackbar");
                        if (x) {
                            x.className = "show";
                            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
                        }
                    }
                    this.redirectService.goBackBrowser();
                }, (err) => {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "a004",
                        text: this.translate.instant("errors." + ((err && err.message) || err)),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                })
        }
    }

    onCancel() {

    }


    ngOnDestroy() {
        if (this.runningYear$) {
            this.runningYear$.unsubscribe();
        }
    }


    private getDataSemester() {
        this.semesterOptions = [];
        let item = new Date().getFullYear();
        let month = Number(moment().format("M"));
        for (let i = 0; i < 15; i++) {
            if (i == 0) {
                this.semesterOptions.push({
                    id: 'H1-' + item,
                    title: 'H1 ' + (item).toString(),
                    code: 'H1',
                    year: (item).toString()
                })
                this.semesterOptions.push({
                    id: 'H2-' + item,
                    title: 'H2 ' + (item).toString(),
                    code: 'H2',
                    year: (item).toString()
                });
            } else {
                const tmp = item + i;
                this.semesterOptions.push({
                    id: 'H1-' + (tmp).toString(),
                    title: 'H1 ' + (tmp).toString(),
                    code: 'H1',
                    year: new Date((tmp).toString()).getFullYear()
                })
                this.semesterOptions.push({
                    id: 'H2-' + (tmp).toString(),
                    title: 'H2 ' + (tmp).toString(),
                    code: 'H2',
                    year: new Date((tmp).toString()).getFullYear()
                });
            }
        }
    }
}