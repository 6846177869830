<div *ngIf="!readonly" class="alloy-text-input-wrapper"
  [ngClass]="{'disabled': disabled, 'invalid': isInvalid,'valid': isValid, 'search-bar': isSearchBar}">
  <input *ngIf="!hasFormControl" [placeholder]="placeholder" [type]="typeInput || 'text'"
    (ngModelChange)="emitOnModelChanged($event)" [(ngModel)]="bindValue" (keyup.enter)="emitOnIconClicked($event)">
  <input *ngIf="hasFormControl" [formControl]="customFormControl" [placeholder]="placeholder" [type]="typeInput || 'text'"
    (ngModelChange)="emitOnModelChanged($event)" [(ngModel)]="bindValue">
  <ng-container *ngIf="!isSearchBar">
    <svg-icon *ngIf="iconRight" [src]="iconRight" (click)="emitOnIconClicked($event)" class="regular-size"
      [applyClass]="true"></svg-icon>
  </ng-container>
  <ng-container *ngIf="isSearchBar">
    <div class="search-icon-wrapper">
      <svg-icon *ngIf="iconRight" [src]="iconRight" (click)="emitOnIconClicked($event)" class="regular-size"
        [applyClass]="true"></svg-icon>
    </div>
  </ng-container>
</div>

<p *ngIf="readonly">{{bindValue}}</p>