import { GetSubordinatesWithPhaseStatusesForManagerResponse, User } from "../../commonclasses";
import { UserCard } from "./classes.utils";

// Utility per la formattazione dei dati utente per il componente user-card
export class UserDataUtils {

  // Funzione da chiamare nel componente sulla risposta del servizio
  static getUserCardData(users: GetSubordinatesWithPhaseStatusesForManagerResponse[] | Array<User>) {
    let tmp: UserCard[] = [];
    if (users && users.length) {
      for (let i = 0; i < users.length; i++) {
        tmp.push(this.formatUserCardData(users[i]))
      }
    }
    return tmp;
  }

  // funzione di supporto che ritorna la card per 1 utente
  static formatUserCardData(user: GetSubordinatesWithPhaseStatusesForManagerResponse | User) {
    return {
      id: user.userId,
      initials: user.surname[0] + user.forename[0],
      name: user.forename,
      surname: user.surname,
      avatar: user.userOptions ? user.userOptions.avatarImage : '',
      isChecked: false
    }
  }
}