import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PerfAlloyPeerFeedback, PerfAlloyUserGoal, SenecaResponse, User } from 'src/commonclasses';
import { UserCard } from 'src/app/utils/classes.utils';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';

@Component({
  selector: 'app-selfAssessment-createUpdatePeerFeedback',
  templateUrl: './createUpdatePeerFeedback.component.html',
  styleUrls: ['./createUpdatePeerFeedback.component.scss']
})
export class CreateUpdatePeerFeedbackComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  personDetails$: Subscription = new Subscription();
  personDetails: any;

  // Modali
  observationsModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageResponseModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageResponseManagerModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };


  // Recupero utenti
  isFetchingUsers: boolean = false;
  userCardData: {
    counter: number,
    fromRecord: number,
    numRecords: number,
    list: UserCard[],
    page: number
  } = {
      counter: 0,
      fromRecord: 0,
      numRecords: 10,
      list: [],
      page: 1
    };

  // Modali
  modalTitle: string = '';
  modalBtnCancel: string = '';
  modalBtnConfirm: string = '';

  feedbackId: string = '';
  isLoadingFeedback: boolean = false;
  feedbackObject!: PerfAlloyPeerFeedback;

  // Dati utenti
  isLoadingTeam: boolean = false;
  searchUserText: string = '';
  selectedUserList: any[] = [];
  // Obiettivi da associare
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToFeedback$: any;
  objectiveOptions: any[] = [];
  selectedObjective: any;

  requestMessage: string = '';

  isResponse: boolean = false;
  isManagerResponse: boolean = false;
  createPeerFeedback$: Subscription = new Subscription;
  getFeedback$: Subscription = new Subscription;
  getFeedbackById$: Subscription = new Subscription;
  feedbackObjectCopy: any;
  isUpdate: boolean = false;
  answerPeerFeedback$: Subscription = new Subscription;
  isChief: boolean = false;
  isChief$: Subscription = new Subscription;

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, towards?: boolean }> = [];
  cardSelected: any;
  chargeUsersVariable: boolean = true;

  isLoadingListGoal: boolean = false;
  getGoalToAssociate$: Subscription = new Subscription;
  goalList: Array<PerfAlloyUserGoal> = [];

  goalOptions: Array<{ id: string, title: string }> = [];

  senderUser!: UserCard;
  recipientUser!: UserCard;

  countFeedbackTabs: number = 0;

  // modalCreateUpdatePeerFeedbackId: string = '';
  // modalCreateUpdatePeerFeedbackTitle: string = '';
  // modalCreateUpdatePeerFeedbackSubtitle: string = '';
  // modalCreateUpdatePeerFeedbackText: string = '';
  // modalCreateUpdatePeerFeedbackTitleConfirmTextButton: string = '';
  // modalCreateUpdatePeerFeedbackTextCloseTextButton: string = '';


  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {
    this.isChief$ = this.store.select(fromApp.getIsChief).subscribe((isChief: boolean) => {
      this.isChief = isChief;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              // this.userId = params.userId;
              this.feedbackId = params.feedbackId;
              if (window.location.href.indexOf('/response') > -1) {
                this.isResponse = true;
              }
              if (window.location.href.indexOf('manager-response') > -1) {
                this.isManagerResponse = true;
              }
              if (this.feedbackId) {
                this.getFeedbackById();
                // this.getFeedbackRequest();
                // this.getFeedback();
              } else {
                this.getUsers();
                this.getGoalsToAssociate();

              }
            }
          );
      }
    });
  }


  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'peerFeedback.createUpdate.OBJECTIVE_TITLE',
        'peerFeedback.modals.OBJECTIVE_TITLE_INFO',
        'peerFeedback.createUpdate.REQUEST_MESSAGE',
        'peerFeedback.modals.REQUEST_MESSAGE_INFO',
        'peerFeedback.createUpdate.WHO',
        'peerFeedback.modals.WHO_INFO',
        'peerFeedback.modals.TO_WHOM',
        'peerFeedback.modals.NO_GOALS',
        'peerFeedback.modals.REFERS_TO',
        'peerFeedback.modals.INFO_1_TITLE',
        'peerFeedback.modals.INFO_1_SUBTITLE',
        'peerFeedback.modals.INFO_1_DESC',
        'peerFeedback.modals.INFO_2_TITLE',
        'peerFeedback.modals.INFO_2_SUBTITLE',
        'peerFeedback.modals.INFO_2_DESC',
        'peerFeedback.modals.INFO_3_TITLE',
        'peerFeedback.modals.INFO_3_SUBTITLE',
        'peerFeedback.modals.INFO_3_DESC',
        'peerFeedback.modals.INFO_4_TITLE',
        'peerFeedback.modals.INFO_4_SUBTITLE',
        'peerFeedback.modals.INFO_4_DESC',
        'peerFeedback.modals.INFO_5_TITLE',
        'peerFeedback.modals.INFO_5_SUBTITLE',
        'peerFeedback.modals.INFO_5_DESC'
      ])
      .subscribe(translations => {
        this.objectiveTitleModal = {
          modalId: "pf001",
          title: translations['peerFeedback.modals.INFO_2_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_2_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_2_DESC']
        };
        this.requestMessageModal = {
          modalId: "pf002",
          title: translations['peerFeedback.modals.INFO_3_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_3_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_3_DESC']
        };
        this.whoTooltipModal = {
          modalId: "pf010",
          title: translations['peerFeedback.modals.INFO_1_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_1_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_1_DESC']
        };
        this.requestMessageResponseManagerModal = {
          modalId: "pf010",
          title: translations['peerFeedback.modals.INFO_4_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_4_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_4_DESC']
        };
        this.requestMessageResponseModal = {
          modalId: "pf010",
          title: translations['peerFeedback.modals.INFO_5_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_5_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_5_DESC']
        };
      })
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Recupero la lista utenti
  getUsers() {
    // Avvio il loader
    this.isFetchingUsers = true;

    // Inizio a fare la chiamata per il counte degli utenti
    this.selfAssessmentService.countUserForPeerFeedback(this.runningYear.year.toString(), this.searchUserText)
      .subscribe((counter: SenecaResponse<number>) => {
        if (counter && counter.response) {
          this.userCardData.counter = counter.response;
          // Calcolo la paginazione
          this.userCardData.fromRecord = (this.userCardData.page - 1) * this.userCardData.numRecords;

          //Inizio la chimata per recuperare gli utenti 
          this.selfAssessmentService.listUserForPeerFeedback(this.userCardData.fromRecord, this.userCardData.numRecords, this.searchUserText)
            .subscribe((data: SenecaResponse<Array<User>>) => {
              if (data && data.response) {
                this.userCardData.list = UserDataUtils.getUserCardData(data.response)
                this.isFetchingUsers = false;
              } else {
                // Vedo se c'è la traduzione dell'errore
                const messageObj: ApplicationModalMessage = {
                  modalId: "gu11",
                  text: this.translate.instant("errors." + counter.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isFetchingUsers = false;
              }
            }, (err?: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "gu12",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isFetchingUsers = false;
            });
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "gu01",
            text: this.translate.instant("errors." + counter.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingUsers = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "gu02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingUsers = false;
      });
  }

  // Cambio il valore del testo da cercare (utenti)
  changeSearchedTextValue(text: string) {
    this.searchUserText = text;
  }

  // Cambia la pagina attuale
  pageChanged(newPage: any) {
    this.userCardData.page = newPage;
    this.userCardData.list = [];
    // Avvio una nuova ricerca
    this.getUsers();
  }

  // Seleziono un utente o più dalla lista recuperata 
  selectUser(user: any) {
    //Controllo se nella lista esiste già un utente con l'id selezionato
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);

    if (isPresent && isPresent.length) {
      // Rimuvo l'utente con l'id già presente
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      // Aggiungo l'utente
      this.selectedUserList.push(user);
    }
  }

  // Selezione dell'obiettivo su cui è richiesto il feedback
  changeAssociatedObjective(objective: any) {
    this.selectedObjective = objective;
  }
  // Salvo il messaggio per accompagnare la richiesta 
  requestMessageChanged(text: string) {
    this.requestMessage = text;
  }

  // Gestisco la creazione/risposta di un peer feedback
  goToPeerFeedback(isBack?: boolean) {
    if (isBack && !this.checkIfDataIsChanged()) {
      this.goBackBrowser();
    } else {
      this.formatModal();
    }
  }

  // Controllo se i dati inseriti sono cambiati
  checkIfDataIsChanged() {
    // Questo controllo viene fatto solo sulla pagina della risposta ad un peerfeedback dell'utente oppure del manager
    if (this.isResponse || this.isManagerResponse) {
      // Controllo la copia creata in precedenza
      return this.feedbackObject && this.feedbackObjectCopy && this.feedbackObject.motivation != this.feedbackObjectCopy.motivation;
    } else {
      // In caso di creazione sarà sempre falso
      return false;
    }
  }


  // MODALI

  // Recupero dei testi delle modali
  formatModal() {
    this.modalTitle = this.getModalTitle();
    this.modalBtnConfirm = this.getModalBtnConfirm();

    // Apro la modale
    this.openModalForCreatePeerFeedback();
  }

  // Recupero il titolo della modale
  getModalTitle() {
    if (this.isResponse || this.isManagerResponse) {
      return this.translate.instant('peerFeedback.modals.SEND_FEEDBACK');
    } else {
      return this.translate.instant('peerFeedback.modals.INSERT_FEEDBACK_REQUEST');
    }
  }
  // Recupero il testo del pulsante conferma della modale
  getModalBtnConfirm() {
    if (this.isResponse || this.isManagerResponse) {
      return this.translate.instant('peerFeedback.modals.SEND_FEEDBACK');
    } else {
      return this.translate.instant('peerFeedback.modals.SEND_REQUEST');
    }
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreatePeerFeedback() {
    this.feedbackRecap = [
      {
        title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
        avatar: true,
        towards: true
      },
      {
        title: this.translate.instant('peerFeedback.modals.REFERS_TO'),
        text: this.selectedObjective?.id ? this.selectedObjective.title : this.translate.instant('peerFeedback.modals.NO_GOALS'),
      },
      {
        title: this.translate.instant('peerFeedback.modals.MOTIVATION_FOR_REQUEST'),
        text: this.requestMessage
      }];
    if (this.isResponse) {
      this.feedbackRecap = [
        {
          title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
          avatar: true,
          towards: true
        },
        {
          title: this.translate.instant('peerFeedback.modals.REFERS_TO'),
          text: this.feedbackObject.targetGoal?.text ? this.feedbackObject.targetGoal.text : this.translate.instant('peerFeedback.modals.NO_GOALS'),
        },
        {
          title: this.translate.instant('peerFeedback.modals.MOTIVATION_FOR_REQUEST'),
          text: this.feedbackObject.motivation
        },
        {
          title: this.translate.instant('peerFeedback.modals.OBSERVATIONS'),
          text: this.feedbackObject.comment
        }];
    }
    if (this.isManagerResponse) {
      this.feedbackRecap = [
        {
          title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
          avatar: true,
          towards: true
        },
        {
          title: this.translate.instant('peerFeedback.modals.TOWARDS'),
          avatar: true
        },
        {
          title: this.translate.instant('peerFeedback.modals.REFERS_TO'),
          text: this.feedbackObject.targetGoal?.text ? this.feedbackObject.targetGoal.text : this.translate.instant('peerFeedback.modals.NO_GOALS'),
        },
        {
          title: this.translate.instant('peerFeedback.modals.MOTIVATION_FOR_REQUEST'),
          text: this.feedbackObject.motivation
        },
        {
          title: this.translate.instant('peerFeedback.modals.OBSERVATIONS'),
          text: this.feedbackObject.comment
        }];
    }
    this.modalService.open('createFeedback');
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreatePeerFeedback() {
    this.modalService.close('createFeedback');
  }

  confirmModalCreatePeerFeeback() {
    // Chiudo la modale
    this.closeModalCreatePeerFeedback();

    if (!this.isResponse && !this.isManagerResponse) {
      // Creo il mio peerfeedback
      this.createPeerFeedback();
    } else {
      // Rispondo al peerfeedback
      this.answerPeerFeedback();
    }
  }

  // Creo il peerFeedback
  createPeerFeedback() {
    // Avvio il loader
    this.isLoadingFeedback = true;

    // Controllo se il subscribe esiste già
    if (this.createPeerFeedback$) {
      this.createPeerFeedback$.unsubscribe();
    }

    // Salvo gli utenti selezionati
    let intermediateUserIds: Array<string> = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      intermediateUserIds.push(this.selectedUserList[i].id);
    }
    // Salvo l'id dell'obiettivo se è presente 
    let goalId = '';
    if (this.selectedObjective) {
      goalId = this.selectedObjective.id;
    }
    // Inizio a fare la chiamata 
    this.createPeerFeedback$ = this.selfAssessmentService.createPeerFeedback(this.runningYear.year, this.runningYear.yearCode, this.requestMessage, intermediateUserIds, goalId)
      .subscribe(
        (data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "crpeer01",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingFeedback = false;
          // Ritorno alla pagina precedente 
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "crpeer02",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
  }

  // Rispondo al peerFeedback
  answerPeerFeedback() {
    // Avvio il loader
    this.isLoadingFeedback = true;

    // Controllo se il subscribe esiste già
    if (this.answerPeerFeedback$) {
      this.answerPeerFeedback$.unsubscribe();
    }

    // Salvo il comment se è presente 
    let comment: string = '';
    if (this.feedbackObject.comment) {
      comment = this.feedbackObject.comment;
    }

    // Avvio la chiamata 
    this.answerPeerFeedback$ = this.selfAssessmentService.answerPeerFeedback(this.runningYear.year, this.runningYear.yearCode, this.feedbackId, comment)
      .subscribe(
        (data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf020",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingFeedback = false;
          }
          this.isLoadingFeedback = false;
          // Ritorna alla pagina precedente 
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
  }

  // Reset dei dati dell'utente 
  resetUsers() {
    this.userCardData.fromRecord = 0;
    this.userCardData.numRecords = 16;
    this.userCardData.page = 1;
    this.userCardData.counter = 0;
    this.userCardData.list = [];
  }

  // Salvo il testo del campo osservazioni
  observationsChanged(text: string) {
    this.feedbackObject.comment = text;
  }


  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (!this.isResponse && !this.isManagerResponse) {
      if (this.selectedUserList && this.selectedUserList.length && this.requestMessage && this.requestMessage.trim().length) {
        return true;
      }
    } else {
      if (this.feedbackObject.comment) {
        return true;
      }
    }
    return false;
  }


  closeConfirmRequest() {
    this.modalService.close('modalConfrimRequest')
  }


  getFeedbackById() {
    // Avvio il loader
    this.isLoadingFeedback = true;
    // Controllo se esisteva già il subscribe
    if (this.getFeedbackById$) {
      this.getFeedbackById$.unsubscribe();
    }
    // Faccio la chiamata per recuperare un preciso feedback 
    this.getFeedbackById$ = this.selfAssessmentService.getPeerFeedbackById(this.runningYear.year, this.runningYear.yearCode, this.feedbackId)
      .subscribe((data: SenecaResponse<PerfAlloyPeerFeedback>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackObject = data.response;
          if (this.feedbackObject.senderUser) {
            this.senderUser = {
              id: this.feedbackObject?.senderUser.userId,
              initials: this.feedbackObject?.senderUser.surname[0] + this.feedbackObject?.senderUser.forename[0],
              name: this.feedbackObject?.senderUser.forename,
              surname: this.feedbackObject?.senderUser.surname,
              avatar: this.feedbackObject?.senderUser.userOptions ? this.feedbackObject?.senderUser.userOptions.avatarImage : '',
              isChecked: false
            }
          }
          if (this.feedbackObject.recipientUser) {
            this.recipientUser = {
              id: this.feedbackObject?.recipientUser.userId,
              initials: this.feedbackObject?.recipientUser.surname[0] + this.feedbackObject?.recipientUser.forename[0],
              name: this.feedbackObject?.recipientUser.forename,
              surname: this.feedbackObject?.recipientUser.surname,
              avatar: this.feedbackObject?.recipientUser.userOptions ? this.feedbackObject?.recipientUser.userOptions.avatarImage : '',
              isChecked: false
            }
          }
          // if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.length > 0) {
          //   this.isUpdate = true;
          // }
          this.feedbackObjectCopy = JSON.parse(JSON.stringify(this.feedbackObject));
        }
        this.isLoadingFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        })
  }

  // Recupero gli obiettivi da associare
  getGoalsToAssociate() {
    // Avvio il loader
    this.isLoadingGoalsToAssociate = true;
    // Controllo se esisteva già il subscribe
    if (this.getGoalToAssociate$) {
      this.getFeedbackById$.unsubscribe();
    }
    // Faccio la chiamata per recuperare un preciso feedback 
    this.getGoalToAssociate$ = this.selfAssessmentService.getGoalsToAssociate(this.runningYear.year, this.runningYear.yearCode)
      .subscribe((data: SenecaResponse<Array<PerfAlloyUserGoal>>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "goalAssociate1",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.goalList = data.response;
          this.goalOptions = [{ id: '', title: 'Nessuno' }];
          for (let i = 0; i < this.goalList.length; i++) {
            this.goalOptions.push({ id: this.goalList[i].goalId, title: this.goalList[i].text })
          }

          this.selectedObjective = { id: '', title: 'Nessuno' };
        }
        this.isLoadingGoalsToAssociate = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "goalAssociate2",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalsToAssociate = false;
        })
  }

  ngOnDestroy() {
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }
  }
}