export module PasUtils {

    /** Dato un profilo pas, ritorna il numero di star corrispondenti da mostrare */
    export function mapPasProfileToStarRating(pasProfile: string): number {
        // Definisco tutti i possibili profili in ordine
        const allPasProfiles: string[] = [
            "LOW_PERFORMER",
            "DEVELOPING_PERFORMER",
            "SOLID_PERFORMER",
            "ADVANCE_PERFORMER",
            "EXCEPTIONAL_PERFORMER"
        ];

        return allPasProfiles.indexOf(pasProfile) + 1;
    }
}