import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfAlloyCommonFeedback, PerfAlloyManagerFeedback } from 'atfcore-commonclasses';
import * as moment from 'moment';

@Component({
  selector: 'card-feedback-to-manager',
  templateUrl: './card-feedback-to-manager.component.html',
  styleUrls: ['./card-feedback-to-manager.component.scss']
})
export class CardFeedbackToManagerComponent implements OnInit {
  @Input() object!: PerfAlloyManagerFeedback;
  @Input() loggedUser?: any;
  @Input() feedbackStatus!: 'RECEIVED' | 'POSTED';
  @Input() isHistory: boolean = false;
  @Input() userIdSelected: string = '';
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFeedbackSend: EventEmitter<any> = new EventEmitter();
  @Output() onShowRequest: EventEmitter<any> = new EventEmitter();

  creationDateText: string = '';
  isPosted: boolean = false;
  title: string = '';

  constructor(public translate: TranslateService) { }


  ngOnInit(): void {
    this.creationDateText = moment(this.object.creationDate).fromNow();
    this.isPosted = this.object.senderUserId == this.loggedUser.userId

    if (this.object.senderUser?.userId == this.userIdSelected) {
      this.title = this.translate.instant('feedbackSection.feedbackToManager.FEEDBACK_TO_MANAGER_TITLE') + ' <b>' + this.object.intermediateUser?.surname + ' ' + this.object.intermediateUser?.forename;
    } else {
      this.title = this.translate.instant('feedbackSection.feedbackToManager.FEEDBACK_TO_MANAGER_OF') + ' <b>' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
    }
  }

  emitOnEdit(id: any) {
    this.onEdit.emit(id);
  }

  emitOnDelete(id: any) {
    this.onDelete.emit(id);
  }
}
