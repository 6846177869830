import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-peerfeedback',
  templateUrl: './card-peerfeedback.component.html',
  styleUrls: ['./card-peerfeedback.component.scss']
})
export class CardPeerfeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
