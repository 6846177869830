<div class="competence-evaluation-container" id="{{ id + '-container' }}">
  <!-- Titolo categoria -->
  <div class="main-title-container">
    <ng-container *ngIf="competenceList.category">
      <p class="competence-category">{{ competenceList?.category || '' }}</p>
    </ng-container>
    <ng-container *ngIf="titlesRightSection && competenceList.competences[0].selfRating">
      <div class="title-section-container">
        <div class="divisor-vertical"></div>
        <div class="title-section left">
          <p class="title-left"> {{titlesRightSection.left}} </p>
        </div>
        <div class="divisor-vertical"></div>
        <div class="title-section right">
          <p class="title-left"> {{titlesRightSection.right}} </p>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- Lista competenze per categoria-->
  <ng-container *ngFor="let tag of competenceList.competences; let last = last; let competenceIndex = index;">
    <div *ngIf="!tag.isPartialAverage" class="competence-data-container"
      id="{{ 'competenceIndex' + competenceIndex + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="tag.title">
          <div class="title-container">
            <p class="title">{{ tag.title }}</p>

            <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/info.svg"
              (click)="onInfoClicked(tag, competenceList.category)">
            </svg-icon>
          </div>
        </ng-container>
        <p class="description" [innerHTML]="tag.text"></p>
      </div>
      <div class="divisor-vertical"></div>
      <!-- Rating competenza -->
      <ng-container *ngIf="!tag.selfRating">
        <star-rating id="{{ (id || ('competenceIndex' + competenceIndex)) + '-stars' }}" [rating]="tag.rating"
          (onStarClicked)="onRatingChanged(tag)" [scale]="ratingScale" [disabled]="disabled || tag.disabled">
        </star-rating>
      </ng-container>

      <!-- Rating competenza (con recap)-->
      <ng-container *ngIf="tag.selfRating">
        <div class="rating-star-content">
          <star-rating [rating]="tag.selfRating" customContainerClass="small-star-size" [grayColor]="true"
            [scale]="ratingScale" [disabled]="true || tag.disabled">
          </star-rating>

          <div class="divisor-vertical"></div>
          <star-rating [rating]="tag.rating" customContainerClass="small-star-size" [scale]="ratingScale"
            (onStarClicked)="onRatingChanged(tag)" [disabled]="disabled || tag.disabled"></star-rating>
        </div>
      </ng-container>
    </div>

    <!-- Media parziale -->
    <div *ngIf="tag.isPartialAverage && tag.allCompetencesValuated" class="competence-data-container"
      id="{{ 'competenceIndex' + competenceIndex + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="tag.title">
          <div class="title-container">
            <p class="title">{{ tag.title }}</p>

            <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/info.svg"
              (click)="onInfoClicked(tag, competenceList.category)">
            </svg-icon>
          </div>
        </ng-container>
        <div class="title-container">
          <p class="avg-title">{{ tag.avgTitle }}</p>
        </div>
        <p class="description">{{tag.text}}</p>
        <p class="avg-subtitle">{{ competenceList?.category || '' }}</p>
      </div>
      <div class="divisor-vertical"></div>
      <!-- Rating competenza -->
      <ng-container *ngIf="!tag.selfRatingAvgNum">
        <star-rating id="{{ (id || ('competenceIndex' + competenceIndex)) + '-stars' }}" [scale]="ratingScale"
          [disabled]="disabled || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.ratingAvgString"
          [forceRating]="tag.ratingStars">
        </star-rating>
      </ng-container>

      <!-- Rating competenza (con recap)-->
      <ng-container *ngIf="tag.selfRatingAvgNum">
        <div class="rating-star-content">
          <star-rating customContainerClass="small-star-size" [grayColor]="true" [scale]="ratingScale"
            [disabled]="true || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.selfRatingAvgString"
            [forceRating]="tag.selfRatingStars">
          </star-rating>

          <div class="divisor-vertical"></div>
          <star-rating customContainerClass="small-star-size" [scale]="ratingScale"
            [disabled]="disabled || tag.disabled" [showFullNumber]="true" [fullNumber]="tag.ratingAvgString"
            [forceRating]="tag.ratingStars"></star-rating>
        </div>
      </ng-container>
    </div>

    <!-- Divisore orizzontale se ho più competenze -->
    <ng-container *ngIf="!last">
      <div class="divisor-horizontal"></div>
    </ng-container>

  </ng-container>
</div>