import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'text-goal',
  templateUrl: 'text-goal.component.html',
  styleUrls: ['./text-goal.component.scss']
  // tolto il change on push per problemi con l'attivazione delle tab
})
export class TextGoalComponent implements OnInit {
  @Input() title: string = '';
  @Input() tagTitle: string = '';
  @Input() canEdit: boolean = false;
  @Input() editDisabled: boolean | undefined = false;
  @Input() isEvaluation: boolean = false;
  @Input() id?: string = '';
  @Input() evaluation: string = '';
  @Input() showMenu: boolean = true;
  @Input() disabled: boolean = false;
  @Input() isToApprove: boolean = false;
  @Input() countUpdates: number = 0;
  @Input() isNotificationUpdates: boolean = false;
  @Input() textFooter: string = '';
  @Input() customPlaceholder: string = '';
  @Input() customTitle: string = '';
  @Input() evaluationCEO: boolean | undefined = false;
  @Input() evaluationVote: number = 0;

  menuOptions: { id: string, title: string, icon: string }[] = [];
  isMenuOpen: boolean = false;
  @Output() evaluationChanged = new EventEmitter();
  @Output() evaluationVoteChanged = new EventEmitter();
  @Output() menuOptionClicked = new EventEmitter();
  @Output() showModal = new EventEmitter();


  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.translate.get([
      "defineObjectives.APPROVE",
      "defineObjectives.EDIT",
      "defineObjectives.EDIT_AND_APPROVE",
      "defineObjectives.DELETE_OBJECTIVE",
    ]).subscribe((translations) => {
      if (this.isToApprove) {
        this.menuOptions = [
          {
            id: 'approve',
            title: translations['defineObjectives.APPROVE'],
            icon: '/assets/img/icons/clipboard-check.svg',
          },
          {
            id: 'edit',
            title: translations['defineObjectives.EDIT_AND_APPROVE'],
            icon: '/assets/img/icons/edit.svg',
          },
          {
            id: 'delete',
            title: translations['defineObjectives.DELETE_OBJECTIVE'],
            icon: '/assets/img/icons/trash-2.svg',
          }
        ]
      } else {
        if (this.editDisabled) {
          this.menuOptions = [
            {
              id: 'delete',
              title: translations['defineObjectives.DELETE_OBJECTIVE'],
              icon: '/assets/img/icons/trash-2.svg',
            }
          ]
        } else {
          this.menuOptions = [
            {
              id: 'edit',
              title: translations['defineObjectives.EDIT'],
              icon: '/assets/img/icons/edit.svg',
            },
            {
              id: 'delete',
              title: translations['defineObjectives.DELETE_OBJECTIVE'],
              icon: '/assets/img/icons/trash-2.svg',
            }
          ]
        }
      }
    })
  }

  onEvaluationChanged(text: string) {
    this.evaluationChanged.emit(text);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openShowModal() {
    this.showModal.emit();
  }

  onMenuOptionClicked(id: string) {
    this.isMenuOpen = false;
    this.menuOptionClicked.emit(id);
  }

  onStarClicked(index: number) {
    this.evaluationVoteChanged.emit(index + 1);
  }
}