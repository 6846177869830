<page-container>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="'feedbackToMAnager.create.SIDENAV_TITLE' | translate"
            [description]="'feedbackToMAnager.create.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingFeedback">
                <div class="form-container">
                    <!-- Step 1 -->
                    <ng-container *ngIf="!isUserSelected">
                        <!-- Utenti del mio team -->
                        <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                            [isValued]="selectedUser && selectedUser.id"
                            [title]="'peerFeedback.createUpdate.PROVIDE_FEEDBACK' | translate">
                            <!-- ricerca -->
                            <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchTeamUserText"
                                (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchTeamUsers()"
                                placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

                            <div class="user-card-collection-container">
                                <!-- Card utente -->
                                <div *ngIf="!isFetchingManager" class="row-boxes" fxLayout="row wrap"
                                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                                    <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'selectUser',
                                  itemsPerPage: userCardData.numRecords,
                                  currentPage: userCardData.page,
                                  totalItems: userCardData.counter }" [user]="user" [isFeedbackCard]="true"
                                        (onCheck)="selectUser($event)">
                                    </user-card>
                                </div>
                                <div *ngIf="isFetchingManager" class="full-width min-height500" fxLayout="row wrap"
                                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                                    <div class="loader spinner-medium"></div>
                                </div>
                                <div class="full-width"
                                    *ngIf="!isFetchingManager && userCardData && userCardData.list && userCardData.list.length"
                                    fxLayout="row" fxLayoutAlign="center center">
                                    <table-pagination id="selectUser" (pageChanged)="usersTeamPageChanged($event)">
                                    </table-pagination>
                                </div>
                            </div>

                        </input-container>
                        <!-- Pulsante richiedi peerfeedback (primo step)-->
                        <div class="button-container">
                            <alloy-button (onButtonClicked)="clickedRequestFeedback(true)"
                                [disabled]="isLoadingFeedback || !isDisabled()" type="primary default" size="large"
                                [label]="'feedbackToMAnager.create.SEND_FEEDBACK' | translate">
                            </alloy-button>
                        </div>
                    </ng-container>

                    <!-- Step 2 -->
                    <ng-container *ngIf="isUserSelected">
                        <!-- Utente singolo selezionato -->
                        <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                            [isValued]="selectUser" [title]="'peerFeedback.createUpdate.SEND_FEEDBACK_ON' | translate">
                            <div class="selected-user">
                                <user-card [user]="selectedUser" [noActions]="true"
                                    (onDelete)="openConfirmDeleteUser()"></user-card>
                            </div>
                        </input-container>
                        <!-- Obiettivo su cui è richiesto il feedback -->
                        <input-container [isObbligatory]="false" [isLoading]="isLoadingManagersBySubordinateId"
                            [isValued]="true" [readonly]="true"
                            [title]="('feedbackToMAnager.create.RECIPIENT' | translate) + selectedUser?.surname + ' ' + selectedUser?.name"
                            [isWithoutInfoIcon]="true">
                            <div class="custom-container">
                                <div class="more-info-container">
                                    <ng-container *ngIf="managerDetails">
                                        <div class="user-container">
                                            <avatar-img [initials]="managerDetails.initials"
                                                [src]="managerDetails.avatar" size="small"></avatar-img>
                                            <p *ngIf="managerDetails.name && managerDetails.surname">{{
                                                managerDetails.surname
                                                + ' ' +
                                                managerDetails.name |
                                                truncate: [22, '...']}}
                                            </p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </input-container>


                        <!-- Messaggio di richiesta -->
                        <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                            [isValued]="true" [isObbligatory]="true"
                            title="{{'feedbackToMAnager.create.INSERT_FEEDBACK' | translate}}">
                            <alloy-text-area [bindValue]="requestMessage"
                                (onModelChanged)="requestMessageChanged($event)"
                                placeholder="{{'feedbackToMAnager.create.INSERT_FEEDBACK_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                        </input-container>
                        <!-- Pulsante invia feedback -->
                        <div class="button-container">
                            <alloy-button (onButtonClicked)="openModalCreateUpdatePeerFeedback()"
                                [disabled]="isLoadingFeedback || !isDisabledSecondStep()" type="primary default"
                                size="large" [label]="'feedbackToMAnager.create.SEND_FEEDBACK' | translate">
                            </alloy-button>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <!-- Loader caricamento appunto -->
            <ng-container *ngIf="isLoadingFeedback">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>


<!-- Cancellazione utente selezionato -->
<modal id="confirm-remove-user" (onCancel)="closeConfirmDeleteUser()" (onClose)="closeConfirmDeleteUser()"
    (onConfirm)="closeConfirmDeleteUser(true)" [cancelLabel]="'generic.CANCEL' | translate"
    [confirmLabel]="'generic.CONFIRM' | translate">
    <modal-text-content modalId="delPl001" [title]="'peerFeedback.modals.DELETE_USER_TITLE' | translate"
        [text]="'peerFeedback.modals.DELETE_USER_DESC' | translate">
    </modal-text-content>
</modal>
<!-- Modale per la creazione/aggiornamento di un appunto -->
<!-- (onConfirm)="(feedbackId && feedbackId.length) ? updatePeerFeedback() : createPeerFeedback()" -->
<modal id="modalCreateUpdatePeerFeedback" (onCancel)="closeModalCreateUpdatePeerFeedback()"
    (onClose)="closeModalCreateUpdatePeerFeedback()" (onConfirm)="closeModalCreateUpdatePeerFeedback(true)"
    [cancelLabel]="'generic.CLOSE' | translate" [confirmLabel]="'feedbackToMAnager.modals.SEND_FEEDBACK' | translate">
    <modal-text-content [modalId]="'1'" [title]="'feedbackToMAnager.modals.TITLE' | translate">
        <!-- divisore -->
        <div class="divisor-line"></div>

        <!-- Subtitle -->
        <p class="subtitle-text" translate="peerFeedback.modals.REQUEST_FEEDBACK_SUBTITLE"></p>

        <ng-container *ngFor="let feedback of feedbackRecap">
            <div class="custom-container">
                <p class="title-modal-custom">{{feedback.title}}</p>
                <ng-container *ngIf="feedback.avatar">
                    <div class="more-info-container" *ngIf="!feedback.isUserSelected">
                        <div class="user-container">
                            <avatar-img [initials]="managerDetails.initials" [src]="managerDetails.avatar"
                                size="small"></avatar-img>
                            <p *ngIf="managerDetails.name && managerDetails.surname">{{ managerDetails.surname + ' ' +
                                managerDetails.name | truncate: [22,
                                '...']}}
                            </p>
                        </div>
                    </div>
                    <div class="more-info-container" *ngIf="selectedUser && feedback.isUserSelected">
                        <div class="user-container">
                            <avatar-img [initials]="selectedUser.initials" [src]="selectedUser.avatar"
                                size="small"></avatar-img>
                            <p *ngIf="selectedUser.name && selectedUser.surname">{{ selectedUser.surname + ' ' +
                                selectedUser.name |
                                truncate: [22, '...']}}
                        </div>
                    </div>
                </ng-container>

                <p *ngIf="!feedback.avatar" class="text-modal-custom">{{feedback.text}}</p>
            </div>
        </ng-container>
    </modal-text-content>
</modal>