import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SendClapSelfAssessmentComponent } from '../sendClap/sendClap.component';

@Injectable()
export class CanDeactivateUpdateClapSelfAssessmentGuardService implements CanDeactivate<SendClapSelfAssessmentComponent> {
  constructor() { }

  canDeactivate(component: SendClapSelfAssessmentComponent): boolean | Promise<boolean> {
    // if (!component.forceBack) {
    //   if (!component.noDataInserted() && !component.alreadySaved &&
    //     component.checkObbligatoryData()
    //   ) {
    //     component.formatDataOpenUpdateModal();
    //     return new Promise<boolean>((resolve, reject) => {
    //       let clickedButton = document.getElementById("confirmBtn");
    //       if (clickedButton) {
    //         clickedButton.onclick = ((e: any) => {
    //           resolve(false);
    //         });
    //       }
    //     });
    //   } else {
    //     resolve(true);
    //   }
    //   return true;
    // }
    return true;
  }
}