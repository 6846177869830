<div class="page-content-wrapper process-detail">
  <!-- Toaster -->
  <div id="snackbar">
    <p translate="generic.LOADING_SUCCESSFUL"></p>
  </div>

  <ng-container *ngIf="tabList && tabList.length > 0 && currentContentTab && currentContentTab.id">
    <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="'process.TITLE' | translate"
      [adminPageSubtitle]="titleHeaderDossier" [adminBack]="true" [adminActionLabel]="'process.EDIT' | translate"
      (onAdminAction)="goToEditProcess()" adminActionLabelType="success" [menuButtons]="tabList" [customTabList]="true"
      (onMenuButtonClicked)="onContentTabChanged($event)" [currentMenuButton]="currentContentTab"></header-dossier>
  </ng-container>

  <!-- Sidebar con il menù principale -->
  <sidebar [showAppHeader]="false" [hideCloseButton]="false" [sidenavTemplateRef]="anagContent" direction="right"
    [duration]="0.3" [maxWidth]="660">
  </sidebar>

  <ng-template #anagContent>
    <div class="anag-container" *ngIf="sidebarData">
      <p class="anag-title" translate="process.anag.SCHEDULE"></p>
      <!--info anagrafiche -->
      <div class="anag-info-container">
        <p class="title">{{ sidebarData.surname + ' ' + sidebarData.forename }}</p>
        <div class="row-section">
          <!-- Nome e cognome -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.SURNAME_NAME"></p>
            <p class="field-content">{{ sidebarData.surname + ' ' + sidebarData.forename}}</p>
          </div>
        </div>
        <div class="row-section">
          <!-- EMAIL -->
          <div class="field-section">
            <p class="field-title" translate="generic.EMAIL"></p>
            <p class="field-content">{{sidebarData.email || '--'}}</p>
          </div>
          <!--- CF -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.CF"></p>
            <p class="field-content">{{sidebarData.codFisc || '--'}}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row-section">
          <!-- POSITION -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.POSITION"></p>
            <p class="field-content">{{sidebarData.email || '--'}}</p>
          </div>
          <!-- Area Of reference -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.AREAOFREFERENCE"></p>
            <p class="field-content">{{sidebarData.chief || '--'}}</p>
          </div>
        </div>
        <div class="row-section">
          <!-- TEAM -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.TEAM"></p>
            <p class="field-content">{{sidebarData.team || '--'}}</p>
          </div>
          <!-- DEPARTMENT -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.DEPARTMENT"></p>
            <p class="field-content">{{sidebarData.ramo || '--'}}</p>
          </div>
        </div>
        <div class="row-section">
          <!-- OFFICE -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.OFFICE"></p>
            <p class="field-content">{{sidebarData.sedeLavoro || '--'}}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row-section">
          <!-- Nome Ruolo competenze -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.COMPETENCETECH"></p>
            <p class="field-content">{{sidebarData.descrizioneRuoloOperativo || '--'}}</p>
          </div>
          <!-- Nome ruolo comportamenti -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.COMPETENCESOFT"></p>
            <p class="field-content">{{sidebarData.mansioneOrganizzativa || '--'}}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row-section">
          <!-- Valutatore -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.EVALUATOR"></p>
            <p class="field-content">{{sidebarData.performanceManagerName || '--'}}</p>
          </div>
          <!-- CF Valutatore -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.CF_EVALUATOR"></p>
            <p class="field-content">{{sidebarData.performanceManagerCodFisc || '--'}}</p>
          </div>
        </div>
        <div *ngIf="!showEditValutator" class="row-section">
          <!-- cta modifica valutatore-->
          <div class="field-section">
            <alloy-button [disabled]="false" [label]="'process.anag.actions.EDIT_EVALUATOR' | translate" type="success"
              size="medium" (onButtonClicked)="onEditEvaluator()">
            </alloy-button>
          </div>
        </div>
        <!-- select  modifica valutatore-->
        <div *ngIf="showEditValutator" class="change-valutator">
          <h6 translate="process.anag.CHANGE_EVALUATOR_TITLE"></h6>
          <input-container [isWithoutInfoIcon]="true" title="{{'process.anag.CHANGE_EVALUATOR_SUBTITLE' | translate}}"
            [isObbligatory]="true">
            <alloy-select [placeholder]="'process.anag.CHANGE_EVALUATOR_PLACEHOLDER' | translate"
              (onSelectedItem)="onChangeValutator($event)" [items]="users$ | async" bindLabel="title"
              [searchable]="true" [typeahead]="usersService$" [loading]="loadingUsers"></alloy-select>
          </input-container>
          <div class="action-container">
            <alloy-button [disabled]="false" [label]="'generic.CANCEL' | translate" size="small"
              (onButtonClicked)="onCloseEditEvaluator()">
            </alloy-button>
            <alloy-button [disabled]="false" [label]="'process.anag.actions.EDIT_EVALUATOR' | translate" type="success"
              size="small" (onButtonClicked)="openModalChangeValutator()">
            </alloy-button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row-section">
          <!-- Stato processo -->
          <div class="field-section">
            <p class="field-title" translate="peopleAnag.sidebar.STATUS_PROCESS"></p>
            <p class="status-title" *ngIf="sidebarData.isInProcess" translate="process.anag.status.INCLUDED"></p>
            <p class="status-title" *ngIf="!sidebarData.isInProcess" translate="process.anag.status.EXCLUDED"></p>
          </div>
        </div>
      </div>
      <div class="process-list-container">
        <p class="title" translate="process.anag.fields.PROCESS_LIST"></p>
        <div class="list-container">
          <ng-container *ngFor="let process of sidebarData.includedInProcessData">
            <p class="item">{{ process.year + ' ' + process.yearCode + ' - ' + process.title }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="main-content-wrapper" *ngIf="currentContentTab && currentContentTab.id">
    <!-- Tab anagrafica -->
    <ng-container *ngIf="currentContentTab.id == 'anag'">

      <div class="admin-table-container column-spacing">

        <p class="section-title">{{ ('calibration.USER_LIST_IN_PROCESS' | translate) }}</p>
        <!-- anagrafica -->
        <div class="gray-box-container">
          <ng-container *ngIf="!isLoadingCounters && counterData">
            <!---->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredTotalUserCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_IN_ANAG"></p>
              </div>
              <div class="excels" (click)="downloadCurrentList()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.DOWNLOAD_CURRENT"></p>
              </div>
            </div>
            <!-- Inclusi -->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredUsersInProcessCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_INCLUDED"></p>
              </div>
              <div *ngIf="showIncludeExclude" class="excels" (click)="openIncludeModalImport()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.INCLUDE_PEOPLE"></p>
              </div>
            </div>
            <!-- Esclusi -->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredUsersNotInProcessCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_EXCLUDED"></p>
              </div>
              <div *ngIf="showIncludeExclude" class="excels" (click)="openExcludeModalImport()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.EXCLUDE_PEOPLE"></p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isLoadingCounters">
            <div class="loader-container">
              <div class="loader spinner-big"></div>
            </div>
          </ng-container>
        </div>

        <!-- Filtri -->
        <anag-filters (searchedTextChanged)="searchedTextChanged($event)" [isLoadingUsers]="isLoadingUsers"
          (updateSelectedFilters)="updateSelectedFilters($event)"
          (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getUsersList($event)"
          [showSelfAssessmentStatus]="false" [showAppraisalStatus]="false" [showCalibrationStatus]="false"
          [showManager]="true">
        </anag-filters>

        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingTranslations && !isLoadingUsers && usersList && usersList.length">

          <div class="table-content ">
            <table>
              <thead>
                <tr>
                  <th *ngIf="showIncludeExclude">
                    <alloy-check (onCheck)="selectAll()"></alloy-check>
                  </th>
                  <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.CF' | translate)}}"></th>
                  <th class="center" translate="adminTable.STATUS" style="width:1%"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}" style="width:1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of usersList | 
                          slice:0:usersNumRecords | 
                          paginate: { id: 'userList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                  <td *ngIf="showIncludeExclude">
                    <alloy-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></alloy-check>
                  </td>
                  <td>{{ user.surname + ' ' + user.forename }}</td>
                  <td>{{ user.codFisc }}</td>
                  <td>
                    <div class="status-evaluation">
                      <ng-container *ngIf="!user.isInProcess">
                        <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                          [message]="'process.anag.status.EXCLUDED' | translate">
                        </item-list-team-status>
                      </ng-container>
                      <ng-container *ngIf="user.isInProcess">
                        <item-list-team-status customContainerClass="min-width-unset" styleItem="border"
                          type="completed" [message]="'process.anag.status.INCLUDED' | translate">
                        </item-list-team-status>
                      </ng-container>
                    </div>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                      (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                    </svg-icon>
                    <ng-container *ngIf="user.isMenuOpen">
                      <div class="dropdown-menu-table">
                        <ng-container *ngFor="let item of menuOptions">
                          <alloy-dropdown-item (click)="onMenuOptionsClicked(item, user)" class="full-width"
                            [item]="item">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
          <div class="admin-no-data-wrapper">
            <p translate="generic.NO_DATA_FOUND"></p>
          </div>
        </ng-container>
        <!-- loader -->
        <ng-container *ngIf="isLoadingUsers || isLoadingTranslations">
          <div class="loader-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
      </div>


    </ng-container>





    <!-- Tab fasi -->
    <ng-container *ngIf="currentContentTab.id == 'phases'">
      <div class="admin-table-container column-spacing">

        <p class="section-title">{{ ('process.phases.TITLE' | translate) }}</p>
        <ng-container *ngIf="isLoadingPhasesData">
          <div class="spinner-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isLoadingPhasesData">

          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.PROCESS_PHASE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.START_DATE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.END_DATE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.CLOSE_DATE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody *ngFor="let data of phases.people">
                <tr>
                  <td>{{data.label}}</td>
                  <td>{{ data.startDate | date: 'dd.MM.YYYY' }}</td>
                  <td>{{ data.endDate | date: 'dd.MM.YYYY' }}</td>
                  <td>{{ data.deadlineDate | date: 'dd.MM.YYYY' }}</td> <!-- FIX IMPOSTARE CAMPO CORRETTO -->
                  <td>
                    <ng-container *ngIf="!data.isOpen">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'process.phases.status.INACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="data.isOpen">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'process.phases.status.ACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" [applyClass]="true" (click)="openClosePhasesMenu(data)"
                      (clickOutside)="closePhasesMenu(data)" src="/assets/img/icons/more-horizontal.svg">
                    </svg-icon>
                    <ng-container *ngIf="data.isMenuOpen">
                      <div class="dropdown-menu-table">
                        <ng-container *ngFor="let item of phasesMenuOption">
                          <alloy-dropdown-item (click)="onPhaseMenuOptionsClicked(item, data)" class="full-width"
                            [item]="item">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--div class="bottom-actions">
              <table-pagination id="dataList" (pageChanged)="modalPageChanged($event)" [noPadding]="true">
              </table-pagination>
              <div class="per-page-selector">
                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeModalNumRecords($event)"
                  [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                  [tablePagination]="true">
                </alloy-select>
              </div>
            </div-->
        </ng-container>




      </div>
    </ng-container>

  </div>

  <!-- Loader tab -->
  <ng-container *ngIf="!currentContentTab || !currentContentTab.id">
    <div class="loader-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>

  <div class="action-include-exclude" *ngIf="selectedUserList && selectedUserList.length >= 1">
    <div class="action-include-exclude-container">
      <div>
        <p class="selected-count">{{ selectedUserList.length || 0}}</p>
        <p class="selected-count-label" translate="generic.SELECTEDS"></p>
      </div>
      <!-- CTA Escludi -->
      <alloy-button [label]="'process.anag.actions.EXCLUDE' | translate" size="medium" type="success"
        [disabled]="!canExcludeMassive()" (onButtonClicked)="openModalMassive(false)"
        iconLeft="assets/img/icons/user-minus-white.svg">
      </alloy-button>
      <!-- CTA Includi -->
      <alloy-button [label]="'process.anag.actions.INCLUDE' | translate" size="medium" type="success"
        [disabled]="!canIncludeMassive()" (onButtonClicked)="openModalMassive(true)"
        iconLeft="assets/img/icons/user-plus-white.svg"></alloy-button>
    </div>
  </div>
</div>

<!-- Modale import inclusione persone -->
<modal *ngIf="import" id="import-modal-include" (onClose)="closeModalImportInclude()"
  (onConfirm)="closeModalImportInclude(true)" [confirmLabel]="'process.anag.actions.INCLUDED' | translate"
  (onCancel)="closeModalImportInclude()" [cancelLabel]="'generic.CANCEL' | translate">
  <div class="sti-modal-container">
    <p class="title" [translate]="'process.anag.IMPORT_MODAL_TITLE'">
    </p>
    <div class="divisor-line"></div>

    <div class="modal-action-container">
      <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
        (onButtonClicked)="downloadTrack()">
      </alloy-button>
    </div>

    <!-- Upload lista persone da includere -->
    <input-container [isWithoutInfoIcon]="true" [isValued]="importIncludeFile" [isObbligatory]="true"
      [title]="'process.anag.IMPORT_MODAL_SUBTITLE' | translate">
      <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFileInclude($event)" [acceptedMimeTypes]="'.xls,.xlsx'">
      </alloy-upload>
    </input-container>
  </div>
</modal>

<!-- Modale import esclusione persone -->
<modal *ngIf="!import" id="import-modal-exclude" (onClose)="closeExcludeModalImport()"
  (onConfirm)="closeExcludeModalImport(true)" [confirmLabel]="'process.anag.actions.EXCLUDED' | translate"
  (onCancel)="closeExcludeModalImport()" [cancelLabel]="'generic.CANCEL' | translate">
  <div class="sti-modal-container">
    <p class="title" [translate]="'process.anag.EXCLUDE_IMPORT_MODAL_TITLE'">
    </p>
    <div class="divisor-line"></div>

    <div class="modal-action-container">
      <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
        (onButtonClicked)="downloadTrack()">
      </alloy-button>
    </div>

    <!-- Upload lista persone da escludere -->
    <input-container [isWithoutInfoIcon]="true" [isValued]="exportIncludeFile" [isObbligatory]="true"
      [title]="'process.anag.EXCLUDE_IMPORT_MODAL_SUBTITLE' | translate">
      <alloy-upload [isToReset]="resetExcluedUpload" (onUpload)="assignFileExclude($event)" [acceptedMimeTypes]="'.xls,.xlsx'">
      </alloy-upload>
    </input-container>
  </div>
</modal>


<modal id="modal-single" (onClose)="closeSingleModal()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeSingleModal(true)" (onCancel)="closeSingleModal()">
  <ng-container *ngIf="singleModalData && singleModalData.title">
    <modal-text-content [modalId]="'si001'" [title]="singleModalData.title" [text]="singleModalData.text">
    </modal-text-content>
  </ng-container>
</modal>

<!-- Inclusione/esclusione massiva -->
<modal id="modal-massive" (onClose)="closeModalMassive()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeModalMassive(true)" (onCancel)="closeModalMassive()">
  <ng-container *ngIf="massiveModalData && massiveModalData.title">
    <modal-text-content [modalId]="'me001'" [title]="massiveModalData.title" [text]="massiveModalData.text">
    </modal-text-content>
  </ng-container>
</modal>

<!-- conferma cambio valutatorew-->
<modal id="modal-change-valutator" (onClose)="closeModalChangeValutator()"
  [confirmLabel]="'generic.CONFIRM' | translate" [cancelLabel]="'generic.CANCEL' | translate"
  (onConfirm)="closeModalChangeValutator(true)" (onCancel)="closeModalChangeValutator()">
  <modal-text-content [modalId]="'ce001'" [title]="'process.anag.changeEvaluator.CHANGE_EVALUATOR_TITLE' | translate"
    [text]="'process.anag.changeEvaluator.CHANGE_EVALUATOR_TEXT' | translate">
  </modal-text-content>
</modal>