<div class="page-content-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="('peopleAnag.HEADER_TITLE' | translate)"
        [hasTabs]="false" [adminPageSubtitle]="('peopleAnag.SUBTITLE' | translate)" [adminBack]="true">
    </header-dossier>

    <!-- Sidebar con il menù principale -->
    <sidebar [showAppHeader]="false" [hideCloseButton]="false" [sidenavTemplateRef]="anagContent" direction="right"
        [duration]="0.3" [maxWidth]="660">
    </sidebar>

    <ng-template #anagContent>
        <div class="anag-container" *ngIf="sidebarData">
            <p class="anag-title" translate="peopleAnag.sidebar.DETAIL"></p>
            <!--info anagrafiche -->
            <div class="anag-info-container">
                <p class="title">{{ sidebarData.surname + ' ' + sidebarData.forename }}</p>
                <div class="row-section">
                    <!-- Nome e cognome -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.SURNAME_NAME"></p>
                        <p class="field-content">{{ sidebarData.surname + ' ' + sidebarData.forename}}</p>
                    </div>
                </div>
                <div class="row-section">
                    <!-- EMAIL -->
                    <div class="field-section">
                        <p class="field-title" translate="generic.EMAIL"></p>
                        <p class="field-content">{{sidebarData.email || '--'}}</p>
                    </div>
                    <!--- CF -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.CF"></p>
                        <p class="field-content">{{sidebarData.codFisc || '--'}}</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row-section">
                    <!-- POSITION -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.POSITION"></p>
                        <p class="field-content">{{sidebarData.defTpDip || '--'}}</p>
                    </div>
                    <!-- Area Of reference -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.AREAOFREFERENCE"></p>
                        <p class="field-content">{{sidebarData.chief || '--'}}</p>
                    </div>
                </div>
                <div class="row-section">
                    <!-- TEAM -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.TEAM"></p>
                        <p class="field-content">{{sidebarData.team || '--'}}</p>
                    </div>
                    <!-- DEPARTMENT -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.DEPARTMENT"></p>
                        <p class="field-content">{{sidebarData.ramo || '--'}}</p>
                    </div>
                </div>
                <div class="row-section">
                    <!-- OFFICE -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.OFFICE"></p>
                        <p class="field-content">{{sidebarData.sedeLavoro || '--'}}</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row-section">
                    <!-- Nome Ruolo competenze -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.COMPETENCETECH"></p>
                        <p class="field-content">{{sidebarData.descrizioneRuoloOperativo || '--'}}</p>
                    </div>
                    <!-- Nome ruolo comportamenti -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.COMPETENCESOFT"></p>
                        <p class="field-content">{{sidebarData.mansioneOrganizzativa || '--'}}</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row-section">
                    <!-- Valutatore -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.EVALUATOR"></p>
                        <p class="field-content">{{sidebarData.performanceManagerName || '--'}}</p>
                    </div>
                    <!-- CF Valutatore -->
                    <div class="field-section">
                        <p class="field-title" translate="peopleAnag.sidebar.CF_EVALUATOR"></p>
                        <p class="field-content">{{sidebarData.performanceManagerCodFisc || '--'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="main-content-wrapper admin">
        <!-- Home anagrafica -->
        <ng-container>
            <ng-container>
                <div class="admin-table-container">
                    <div class="structure-recap">
                        <p class="section-title">{{ ('peopleAnag.PEOPLE_LIST' | translate) }}</p>
                        <!-- Filtri -->
                        <anag-filters (updateSelectedFilters)="updateSelectedFilters($event)"
                            (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)"
                            [showSelfAssessmentStatus]="false" [showAppraisalStatus]="false"
                            [showCalibrationStatus]="false" (startSearch)="getUsersDataFilter()"
                            (searchedTextChanged)="searchedTextChanged($event)"
                            [showManager]="true">
                        </anag-filters>
                        <div class="action-row">
                            <div class="actions-container">
                                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                                <p translate="peopleAnag.DOWNLOAD_ANAG_LIST" (click)="emitOnDownloadList()"></p>
                            </div>
                            <div class="actions-container">
                                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                                <p translate="peopleAnag.IMPORT_ANAG_LIST" (click)="emitOnImportList()"></p>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!isLoadingUserData && userData.list.length > 0">
                        <div  class="table-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                                        <th translate="{{ ('adminTable.CF_SHORT' | translate)}}"></th>
                                        <th class="max-width100"  translate="{{ ('adminTable.EMAIL' | translate)}}"></th>
                                        <th translate="{{ ('adminTable.POSITION' | translate)}}"></th>
                                        <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of userData.list | 
                                slice:0:userData.numRecords | 
                                paginate: { id: 'userList',
                                        itemsPerPage: userData.numRecords,
                                        currentPage: userData.page,
                                        totalItems: userData.counter }">
                                        <td>{{user.surname}} {{user.forename}}</td>
                                        <td>{{ user.codFisc }}</td>
                                        <td>{{ user.email}}</td>
                                        <td>{{ user.defTpDip}}</td>
                                        <td class="detail" (click)="openSidenavDetail(user)">
                                            {{ ('generic.DETAIL' | translate) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="bottom-actions" >
                            <table-pagination id="userList" (pageChanged)="userPageChanged($event)" [noPadding]="true">
                            </table-pagination>
                            <div class="per-page-selector">
                                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                                <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                    [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                    bindLabel="id" [tablePagination]="true">
                                </alloy-select>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoadingUserData && userData.list.length <= 0">
                        <div class="admin-no-data-wrapper">
                            <p translate="generic.NO_DATA_FOUND"></p>
                        </div>
                    </ng-container>
                    <!-- loader -->
                    <ng-container *ngIf="isLoadingUserData">
                        <div class="loader-container">
                            <div class="loader spinner-big"></div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- Modale import inclusione persone -->
<modal id="import-modal-include" (onClose)="closeModalImportInclude()" (onConfirm)="closeModalImportInclude(true)"
    [confirmLabel]="'peopleAnag.modal.IMPORT_CTA' | translate" (onCancel)="closeModalImportInclude()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <div class="sti-modal-container">
        <p class="title" [translate]="'peopleAnag.modal.IMPORT_MODAL_TITLE'">
        </p>
        <div class="divisor-line"></div>

        <div class="modal-action-container">
            <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="primary bordless" size="medium"
                (onButtonClicked)="downloadTrack()" class="m-auto">
            </alloy-button>
        </div>

        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
            [title]="'peopleAnag.modal.IMPORT_MODAL_SUBTITLE' | translate">
            <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"
                [acceptedMimeTypes]="'.xls,.xlsx'">
            </alloy-upload>
        </input-container>
        <p class="footer" [translate]="'peopleAnag.modal.IMPORT_MODAL_FOOTER'"></p>
    </div>
</modal>

<!-- Modale filtri -->
<modal id="filtersModal" (onClose)="closeFiltersModal()" (onConfirm)="applyFilters()"
    [confirmLabel]="('generic.APPLY_FILTERS' | translate)" (onCancel)="clearFilters()"
    [cancelLabel]="('generic.DELETE_FILTERS' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="filter-recap">
            <p class="subtitle" translate="process.manage.APPLY_FILTERS"></p>
            <p>{{ 1 + ('process.manage.FILTER_ACTIVE' | translate ) }}</p>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per sti -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STI"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p>STI ON</p>
                </div>
                <div class="filter-card">
                    <p>STI OFF</p>
                </div>
                <div class="filter-card">
                    <p>NO STI</p>
                </div>
            </div>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per stato -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STATUS"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p translate="process.details.STATUS_INCLUDED"></p>
                </div>
                <div class="filter-card">
                    <p translate="process.details.STATUS_EXCLUDED"></p>
                </div>
            </div>
        </div>
        <!-- Filtro per definizione (struttura) -->
        <div class="filter-container">
            <p class="name" translate="process.manage.DEFINITION"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
            </div>
            <p class="more-link" translate="process.manage.SHOW_ALL"></p>
            <p class="more-link" translate="process.manage.SHOW_LESS"></p>
        </div>
    </div>
</modal>