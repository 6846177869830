import { Component, Input } from '@angular/core';


@Component({
  selector: 'competence-average',
  templateUrl: 'competence-average.component.html',
  styleUrls: ['./competence-average.component.scss']
})
export class CompetenceAverageComponent {
  @Input() competenceAvg: any;
  @Input() id?: any;
  @Input() ratingScale: number = 5;
  @Input() disabled: boolean = false;

  constructor() { }

  showSelfRating() {
    return this.competenceAvg && this.competenceAvg.selfRatingAvgNum && this.competenceAvg.isPostCalibrationOrFinalStepCompleted;
  }
}