import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { map, switchMap, mergeMap } from "rxjs/operators";
import { from } from "rxjs";
import { catchError } from "rxjs/operators";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../core/profile/ngrx/profile.actions";
import { Store } from "@ngrx/store";
import * as AuthActions from "./auth.actions";
import { AuthService } from "../services/auth.service";
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, UserAcknowledges } from "../../../commonclasses";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from "@ngx-translate/core";
import { ApplicationModalMessage } from "src/app/core/ngrx/core.reducers";

@Injectable()
export class AuthEffects {
  token: string = '';
  tinyToken: string = '';
  redirectUrl: string = '';
  isLdap: boolean | undefined = false;
  authDataMail: string = '';
  tinyTokenObj = { response: '' };

  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService
  ) {
    this.store.select(fromApp.getRedirectUrl).subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });
  }

  authLogin$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.DoLogin),
        switchMap(
          (authData: {
            email?: any;
            password?: any;
            isLdap?: boolean;
            oauthCode?: any;
          }) => {
            this.isLdap = authData.isLdap;
            this.authDataMail = authData.email;
            const deviceInfo = this.deviceService.getDeviceInfo();
            const userAgent = deviceInfo && deviceInfo.userAgent;
            let deviceType;
            if (this.deviceService.isMobile()) {
              // Salvo il fatto che è uno smartphone
              deviceType = "P";
            } else if (this.deviceService.isTablet()) {
              // Salvo il fatto che è un tablet
              deviceType = "T";
            } else if (this.deviceService.isDesktop()) {
              // Salvo il fatto che è un computer desktop
              deviceType = "D";
            }
            if (authData.oauthCode && authData.oauthCode.length) {
              return from(
                this.authService.loginFromOAuthCallback(
                  authData.oauthCode,
                  deviceType,
                  userAgent
                )
              );
            } else if (this.isLdap) {
              return from(
                this.authService.loginProd(
                  authData.email,
                  authData.password,
                  deviceType,
                  userAgent
                )
              );
            } else {
              return from(
                this.authService.login(
                  authData.email,
                  authData.password,
                  deviceType,
                  userAgent
                )
              );
            }
          }
        ),
        switchMap((tinyTokenObj: any) => {
          if (tinyTokenObj.error) {
            throw new Error(tinyTokenObj.error);
          }
          this.tinyTokenObj = tinyTokenObj;
          return from(this.authService.getJWTToken(tinyTokenObj.response));
        }),
        map((tinyTokenObj?: any) => {
          return this.store.dispatch(AuthActions.SetToken({ payload: this.tinyTokenObj.response }));
        }),
        map((data?: any) => {
          return this.store.dispatch(AuthActions.SetUserAuthenticated());
        }),
        map((data?: any) => {
          return [
            this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { redirectUrl: this.redirectUrl || "defaultPage" } })),
            this.store.dispatch(CoreActions.RemoveRedirectUrl()),
            this.store.dispatch(CoreActions.StartCountNotifications())
          ];
        }),
        catchError((err, caught) => {
          let error = (err && err.message) || err || '';
          if (error && error.length) {
            const messageObj: ApplicationModalMessage = {
              modalId: "023",
              subtitle: this.translate.instant('login.modals.TAKE_NEW_ATTEMPT'),
              text: this.translate.instant("errors." + error),
              title: this.translate.instant("login.modals.WARNING"),
              confirmLabel: this.translate.instant('login.modals.TRY_AGAIN')
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      ), { dispatch: false }
  )

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  acknowledgedGet$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.RetrieveUserAcknowledges)
        , switchMap(() => {
          return from(this.authService.getAllUserAcknowledges());
        })
        , map(
          (data: SenecaResponse<UserAcknowledges>) => {
            if (data) {
              if (data.error) {
                throw (new Error(data.error));
              } else {
                const ackAction: any = AuthActions.SetUserAcknowledges({ payload: data.response });
                return ackAction;
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "024",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          AuthActions.SetUserAcknowledges({ payload: null });
          return caught;
        })
      )
  )

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  acknowledgedUpdate$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.UpdateUserAcknowledges)
        , switchMap((action: any) => {
          return from(this.authService.updateUserAcknowledges(action.payload));
        })
        , map(
          (data: any) => {
            if (data) {
              if (data.error) {
                // Catturo l'errore
                throw (new Error(data.error));
              } else {
                const setAckAction: any = AuthActions.RetrieveUserAcknowledges();
                return setAckAction;
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "025",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          AuthActions.SetUserAcknowledges({ payload: null });
          return caught;
        })
      )
  );

  authLogout$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.Logout)
        , switchMap(() => {
          return from(this.authService.logout());
        })
        , mergeMap(
          (data: SenecaResponse<any>) => {
            if (data.response === null && data.error === null) {
              let actionsContainer = [
                AuthActions.SessionLogout(),
                AuthActions.SetUserAcknowledges({ payload: null }),
                CoreActions.RemoveApplicationLang(),
                ProfileActions.CancelLoggedUser()
              ];
              this.router.navigate(['/logoutSsoCompleted']);
              // this.router.navigate(["/login"]);
              this.router.navigate(["/localLogin"]);
              return actionsContainer;
            }
            return [];
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "026",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      )
  );
}

