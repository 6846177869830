<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goToPeerFeedback(true)" [isBackButton]="true"
      [title]="isResponse?
      (('peerFeedback.createUpdate.SIDENAV_COLL_TITLE_RESPONSE' | translate) + senderUser?.surname + ' ' + senderUser?.name):
      isManagerResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_TITLE_MANAGER_RESPONSE' | translate) + recipientUser?.surname + ' ' + recipientUser?.name :'peerFeedback.createUpdate.SIDENAV_COLL_TITLE' | translate"
      [description]="isResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION_RESPONSE' | translate):
      isManagerResponse? ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION_MANAGER_RESPONSE' | translate : {'surnameManager': senderUser?.surname, 'forenameManager': senderUser?.name,'surnameTowards': recipientUser?.surname, 'forenameTowards': recipientUser?.name}) :
       ('peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION' | translate)" [textLight]="isManagerResponse">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingFeedback">
        <div class="form-container">
          <!-- Se sta facendo una richiesta di feedback isResponse-->
          <ng-container *ngIf="!isResponse">
            <!-- Creazione -->
            <ng-container *ngIf="!feedbackId">
              <!-- user card -->
              <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                [isValued]="selectedUserList && selectedUserList.length"
                [title]="'peerFeedback.createUpdate.WHO' | translate">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                  (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="getUsers()"
                  placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

                <div class="user-card-collection-container">
                  <!-- Card utente -->
                  <div *ngIf="!isFetchingUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                    fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                    fxLayoutGap.lt-md="16px">
                    <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'feedbackUser',
                                  itemsPerPage: userCardData.numRecords,
                                  currentPage: userCardData.page,
                                  totalItems: userCardData.counter }" [user]="user"
                      [selectedUserList]="selectedUserList" [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                    </user-card>
                  </div>

                  <div *ngIf="isFetchingUsers" class="full-width min-height500" fxLayout="row wrap"
                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                    <div class="loader spinner-medium"></div>
                  </div>
                  <div class="full-width"
                    *ngIf="!isFetchingUsers && userCardData && userCardData.list && userCardData.list.length"
                    fxLayout="row" fxLayoutAlign="center center">
                    <table-pagination id="feedbackUser" (pageChanged)="pageChanged($event)">
                    </table-pagination>
                  </div>
                </div>

              </input-container>
              <ng-container>
                <!-- Obiettivo su cui è richiesto il feedback -->
                <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                  [tooltipModalMessage]="objectiveTitleModal" [isValued]="true"
                  title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                  <alloy-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                    [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                    [selectedItem]="selectedObjective" [items]="goalOptions" [loadingData]="isLoadingGoalsToAssociate"
                    [searchable]="true" bindLabel="title"
                    placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                  </alloy-select>
                </input-container>
              </ng-container>
              <!-- Messaggio di richiesta -->
              <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                [isValued]="requestMessage.length" [isObbligatory]="true"
                title="{{'peerFeedback.createUpdate.INSERT_MESSAGE' | translate}}">
                <alloy-text-area [bindValue]="requestMessage" (onModelChanged)="requestMessageChanged($event)"
                  placeholder="{{'peerFeedback.createUpdate.INSERT_MESSAGE_PLACEHOLDER' | translate}}">
                </alloy-text-area>
              </input-container>
              <!-- Pulsante richiedi peerfeedback -->
              <div class="button-container">
                <alloy-button (onButtonClicked)="goToPeerFeedback()"
                  [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default" size="large"
                  [label]="'peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST' | translate">
                </alloy-button>
              </div>
            </ng-container>
          </ng-container>



          <!-- Se sta rispondendo a richiesta di feedback -->
          <ng-container *ngIf="isResponse || isManagerResponse">
            <!-- Obiettivo su cui è richiesto il feedback -->
            <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true"
              title="{{'peerFeedback.createUpdate.REQUEST_FROM' | translate}}" [isWithoutInfoIcon]="true">
              <div class="custom-container">
                <div class="more-info-container">

                  <div class="user-container">
                    <avatar-img [initials]="senderUser.initials" [src]="senderUser.avatar" size="small"></avatar-img>
                    <p *ngIf="senderUser.name && senderUser.surname">{{ senderUser.surname + ' ' + senderUser.name |
                      truncate: [22, '...']}}
                    </p>
                  </div>
                </div>
              </div>
            </input-container>
            <!-- Obiettivo su cui è richiesto il feedback -->
            <ng-container *ngIf="isManagerResponse">
              <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback" [isValued]="true"
                [readonly]="true" title="{{'peerFeedback.createUpdate.TOWARDS' | translate}}"
                [isWithoutInfoIcon]="true">
                <div class="custom-container">
                  <div class="more-info-container">

                    <div class="user-container">
                      <avatar-img [initials]="recipientUser.initials" [src]="recipientUser.avatar"
                        size="small"></avatar-img>
                      <p *ngIf="recipientUser.name && recipientUser.surname">{{ recipientUser.surname + ' ' +
                        recipientUser.name |
                        truncate: [22, '...']}}
                      </p>
                    </div>
                  </div>
                </div>
              </input-container>
            </ng-container>
            <!-- Messaggio di richiesta -->
            <input-container [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true" [isObbligatory]="false"
              title="{{'peerFeedback.createUpdate.REFERRED_TO' | translate}}" [isWithoutInfoIcon]="true">
              <alloy-input
                [bindValue]="feedbackObject.targetGoal?.text  ? feedbackObject.targetGoal?.text: 'peerFeedback.modals.NO_GOALS' | translate"
                [readonly]="true"></alloy-input>
            </input-container>
            <!-- Motivazione della richiesta -->
            <input-container [isLoading]="isLoadingFeedback" [isValued]="true" [readonly]="true" [isObbligatory]="false"
              title="{{'peerFeedback.createUpdate.MOTIVATION_FOR_REQUEST' | translate}}" [isWithoutInfoIcon]="true">
              <alloy-input [bindValue]="feedbackObject.motivation" [readonly]="true"></alloy-input>
            </input-container>
            <!-- Quali sono le tue osservazioni? -->
            <input-container [isLoading]="isLoadingFeedback" [readonly]="false" [isObbligatory]="true"
              [isValued]="feedbackObject.comment && feedbackObject.comment.trim().length" [tooltipModalMessage]="isManagerResponse? requestMessageResponseManagerModal: requestMessageResponseModal"
              title="{{'peerFeedback.createUpdate.OBSERVATIONS' | translate}}">
              <alloy-text-area [bindValue]="feedbackObject.comment" (onModelChanged)="observationsChanged($event)"
                placeholder="{{'peerFeedback.createUpdate.WRITE_FEEDBACK_PLACEHOLDER' | translate}}">
              </alloy-text-area>
            </input-container>
            <!-- Pulsante richiedi peerfeedback -->
            <div class="button-container">
              <alloy-button [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default"
                size="large" (onButtonClicked)="goToPeerFeedback()"
                [label]="'peerFeedback.createUpdate.SEND_FEEDBACK' | translate">
              </alloy-button>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isLoadingFeedback">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/risposta di un peerfeedback -->
<modal id="createFeedback" (onCancel)="closeModalCreatePeerFeedback()" (onClose)="closeModalCreatePeerFeedback()"
  (onConfirm)="confirmModalCreatePeerFeeback()" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="modalBtnConfirm">
  <modal-text-content [title]="modalTitle">
    <!-- divisore -->
    <div class="divisor-line"></div>

    <!-- Subtitle -->
    <p class="subtitle-text" translate="peerFeedback.modals.REQUEST_FEEDBACK_SUBTITLE"></p>

    <ng-container *ngFor="let feedback of feedbackRecap">
      <div class="custom-container">
        <!-- Titolo del recap -->
        <p class="title-modal-custom">{{feedback.title}}</p>

        <!-- Avatar degli utenti -->
        <ng-container *ngIf="feedback.avatar">
          <div class="more-info-container">
            <ng-container *ngIf="!isResponse">
              <ng-container *ngFor="let user of this.selectedUserList">
                <div class="user-container">
                  <avatar-img [initials]="user.initials" [src]="user.avatar" size="small"></avatar-img>
                  <p *ngIf="user.name && user.surname">{{ user.surname + ' ' + user.name | truncate: [22, '...']}}
                  </p>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="(isResponse || isManagerResponse) && feedback.towards">
              <div class="user-container">
                <avatar-img [initials]="senderUser.initials" [src]="senderUser.avatar" size="small"></avatar-img>
                <p *ngIf="senderUser.name && senderUser.surname">{{ senderUser.surname + ' ' + senderUser.name |
                  truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="!feedback.towards">
              <div class="user-container">
                <avatar-img [initials]="recipientUser.initials" [src]="recipientUser.avatar" size="small"></avatar-img>
                <p *ngIf="recipientUser.name && recipientUser.surname">{{ recipientUser.surname + ' ' +
                  recipientUser.name |
                  truncate: [22, '...']}}
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Testo del recap -->
        <p *ngIf="!feedback.avatar" class="text-modal-custom">{{feedback.text}}</p>
      </div>
    </ng-container>

  </modal-text-content>
</modal>