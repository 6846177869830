<div class="page-content-wrapper">
  <banner-home *ngIf="runningPhaseDate && runningPhaseDate.length" [phaseName]="phaseName" [backgroundSrc]="bannerImage"
    [endDate]="runningPhaseDate" [isGoalsUpdatePhase]="isGoalsUpdatePhase"
    [runningYear]="runningYear.year"></banner-home>

  <!--Vista Desktop -->
  <div class="box-collection-container">
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap" fxLayoutGap.sm="16px grid"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center" id="boxTag">
      <!-- Lista feedback per peopleAppraisal senza profilo self assessment -->
      <ng-container *ngIf="!isSelfAssessment && peerFeedbackAllowed">
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToFeedbackRequests()"
          imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="{{'homeCards.selfAssessment.feedbackCollection.TITLE' | translate}}"
          description="homeCards.selfAssessment.feedbackCollection.DESCRIPTION_ALT" [isNew]="false"></box-home>
      </ng-container>
      <!--[disabledModalMessage]="notesDisabledModal"-->
      <!-- Appunti privati -->
      <ng-container>
        <box-home id="privateBoxTag" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToTakeNote()"
          iconSrc="../../../../assets/img/icons/homeIcons/notes-img.svg" [iconBox]="true"
          title="{{'homeCards.peopleAppraisal.notes.TITLE' | translate}}"
          description="homeCards.peopleAppraisal.notes.DESCRIPTION">
        </box-home>
      </ng-container>
      <!-- Smart Feedback -->
      <!-- <ng-container>
        <box-home id="feedbackBoxTag" fxFlex.gt-sm="33" fxFlex.sm="50" fxFlex.gt-sm="33" (onButtonClicked)="goToSmartFeedback()"
          iconSrc="../../../../assets/img/icons/homeIcons/feedback-img.svg" [iconBox]="true"
          title="{{'homeCards.peopleAppraisal.smartFeedback.TITLE' | translate}}"
          description="homeCards.peopleAppraisal.smartFeedback.DESCRIPTION" [isDisabled]="false"
          [disabledModalMessage]="applauseDisabledModal">
        </box-home>
      </ng-container> -->
      <!-- Dashboard [isDisabled]="true" [disabledModalMessage]="dashboardDisabledModal" -->
      <box-home id="dashboardBoxTag" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToDashboard()"
        iconSrc="../../../../assets/img/icons/homeIcons/man-op-dashboard-img.svg" [iconBox]="true"
        title="{{'homeCards.peopleAppraisal.dashboard.TITLE' | translate}}"
        description="homeCards.peopleAppraisal.dashboard.DESCRIPTION"></box-home>

      <!-- Peer FeedBack-->
      <box-home id="peerFeedbackBoxTag" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToCreatePeerFeedbackPeopleAppraisal()"
        iconSrc="../../../../assets/img/icons/merge.svg" [iconBox]="true"
        title="{{'homeCards.peopleAppraisal.peerFeedback.TITLE' | translate}}"
        [disabledModalMessage]="feedbackDisabledModal" description="homeCards.peopleAppraisal.peerFeedback.DESCRIPTION">
      </box-home>
    </div>
  </div>
</div>