import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { PerfAlloyAssessmentPhaseKeys, PerformanceAssessmentAlloyStatuses, WebrankingOpenAPI } from 'atfcore-commonclasses';

@Component({
  selector: 'app-admin-edit-phase-date',
  templateUrl: './editPhaseDate.component.html',
  styleUrls: ['./editPhaseDate.component.scss']
})
export class EditPhaseDateComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  processId: string = '';
  isLoadingPhaseData: boolean = true;

  processYear!: number;
  processYearCode: string = '';
  processStatus!: string;

  notes: string = '';
  endDate!: Date | undefined;
  startDate!: Date | undefined;
  deadlineDate!: Date | undefined;

  phase: any;

  minDate: Date | undefined;

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.processId = params.processId;
              this.processYear = params.processYear as number;
              this.processYearCode = params.processYearCode;
              if (!this.processId) {
                this.isLoadingPhaseData = false;
              } else {
                this.getPhaseData();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  noteChanged(text: string) {
    this.notes = text;
  }

  getPhaseData() {
    this.adminService.getProcess(this.processId).subscribe(
      (data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "071",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {

          this.phase = {
            perfYear: this.processYear,
            perfYearCode: this.processYearCode,
            startDate: this.startDate,
            endDate: this.endDate,
            deadlineDate: this.deadlineDate
          }

          if (this.router.url.includes('editDates/self-assessment')) {
            this.startDate = data.response.selfAssessmentStartDate ? new Date(data.response.selfAssessmentStartDate) : undefined;
            this.endDate = data.response.selfAssessmentEndDate ? new Date(data.response.selfAssessmentEndDate) : undefined;
            this.phase.phaseKey = WebrankingOpenAPI.PhaseKeysTypes.SELF_ASSESSMENT;
            this.deadlineDate = data.response.selfAssessmentDeadlineDate ? new Date(data.response.selfAssessmentDeadlineDate) : undefined;
            this.minDate = new Date();
          }

          if (this.router.url.includes('editDates/assessment')) {
            this.startDate = data.response.peopleAppraisalStartDate ? new Date(data.response.peopleAppraisalStartDate) : undefined;
            this.endDate = data.response.peopleAppraisalEndDate ? new Date(data.response.peopleAppraisalEndDate) : undefined;
            this.phase.phaseKey = WebrankingOpenAPI.PhaseKeysTypes.PEOPLE_APPRAISAL;
            this.deadlineDate = data.response.peopleAppraisalDeadlineDate ? new Date(data.response.peopleAppraisalDeadlineDate) : undefined;
            this.minDate = new Date();
          }

          if (this.router.url.includes('editDates/update-goal')) {
            this.startDate = data.response.goalUpdateStartDate ? new Date(data.response.goalUpdateStartDate) : undefined;
            this.endDate = data.response.goalUpdateEndDate ? new Date(data.response.goalUpdateEndDate) : undefined;
            this.phase.phaseKey = WebrankingOpenAPI.PhaseKeysTypes.GOAL_UPDATE;
            this.deadlineDate = data.response.goalUpdateDeadlineDate ? new Date(data.response.goalUpdateDeadlineDate) : undefined;
            const selfEndDeadlineDate = data.response.selfAssessmentDeadlineDate ? new Date(data.response.selfAssessmentDeadlineDate) : undefined;
            const peopleDeadlineDate = data.response.peopleAppraisalDeadlineDate ? new Date(data.response.peopleAppraisalDeadlineDate) : undefined;
            if (selfEndDeadlineDate === undefined || peopleDeadlineDate === undefined) {
              this.minDate = selfEndDeadlineDate;
            } else {
              if (selfEndDeadlineDate > peopleDeadlineDate) {
                this.minDate = selfEndDeadlineDate;
              } else {
                this.minDate = peopleDeadlineDate;
              }
            }
          }
        }

        this.processStatus = data.response.processStatus;
        this.isLoadingPhaseData = false;
      }
    )
  }


  startDateChanged(newDate: Date) {
    this.startDate = newDate;
  }

  endDateChanged(newDate: Date) {
    this.endDate = newDate;
  }

  deadlineDateChanged(newDate: Date) {
    this.deadlineDate = newDate;
  }

  onConfirm() {
    this.modalService.open('confirm-edit');
  }

  closeConfirm(confirm?: boolean) {
    this.modalService.close('confirm-edit');
    if (confirm) {
      this.phase.startDate = this.startDate;
      this.phase.endDate = this.endDate;
      this.phase.deadlineDate = this.deadlineDate;
      this.adminService.setPerformanceAssessmentPhaseDates(this.phase).subscribe((data: any) => {
        if (!data.error) {
          this.router.navigate([`admin/processDetails/${this.processId}/${this.processYear}/${this.processYearCode}/phases`]);
        }
      });
    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }

  isProcessValid() {
    if (this.isLoadingPhaseData)
      return true;

    return this.processStatus == PerformanceAssessmentAlloyStatuses.HISTORY ? true : false;
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}