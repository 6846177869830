<div class="main-container">
  <!-- Avatar -->
  <div class="avatar-container">
    <ng-container *ngIf="!(object.smartFeedbackType == 'INVIATO')">
      <avatar-img [user]="loggedUser" size="medium"></avatar-img>
    </ng-container>
  </div>

  <div class="rectangle-container">
    <!-- Triangolo che punta a destra o sinistra -->
    <svg-icon *ngIf="!(object.smartFeedbackType == 'INVIATO')" class="rectangle-left"
      [src]="'assets/img/icons/rectangle-left.svg'"></svg-icon>
    <svg-icon *ngIf="object.smartFeedbackType == 'INVIATO'" class="rectangle-right"
      [src]="'assets/img/icons/rectangle-right.svg'"></svg-icon>

    <!-- Card -->
    <div class="card-container">
      <div class="header-type-container">
        <div class="type-container">
          <!-- Icona card-->
          <div class="type-container-text-icon">
            <ng-container *ngIf="item">
              <svg-icon [src]="item.icon" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
              <p *ngIf="item.title">{{item.title}}</p>
            </ng-container>
          </div>
          <!-- Icona tempo -->
          <div class="period-time">
            <p *ngIf="object">{{object.creationDateAgo}}</p>
            <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
          </div>
        </div>
      </div>
      <div class="divisor-line"></div>

      <!-- Card profile -->
      <div class="information-container">
        <div class="information-text-container">
          <!-- Text per lo smart-feedback -->
          <p class="information-text">
            <ng-container *ngIf="object.isSmartFeedback">
              <p class="bold-text" translate="feedbackSection.MOTIVATION"></p>
              <p style="white-space: pre-wrap" [innerHTML]="object.comment"></p>
            </ng-container>
            <!-- Text per gli appunti privati -->
            <ng-container *ngIf="!object.isSmartFeedback">
              <span class="bold-text italic-text" translate="feedbackSection.WHY"></span><span
                style="white-space: pre-wrap" [innerHTML]="object.comment"></span><br>
              <span class="bold-text italic-text" translate="feedbackSection.WHAT"></span><span
                style="white-space: pre-wrap" [innerHTML]="object.motivation"></span>
            </ng-container> 
          </p>
          <!-- Pulsante -->
          <div *ngIf="!object.isSmartFeedback" class="action-footer">
            <div class="actions-link-container">
              <a translate="generic.EDIT" (click)="editObject(object.feedbackId)">
              </a>
              <!-- Elimina commentato per gli applausi su richiesta -->
              <a translate="generic.DELETE" (click)="deleteObject(object.feedbackId)">
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="avatar-container" [ngClass]="{'right': object.smartFeedbackType == 'INVIATO'}">
    <ng-container *ngIf="object.smartFeedbackType == 'INVIATO'">
      <avatar-img [user]="object.recipientUser" size="medium"></avatar-img>
    </ng-container>
  </div>
</div>