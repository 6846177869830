import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { OrganizeInterviewComponent } from '../organizeInterview/organizeInterview.component';

@Injectable()
export class CanDeactivateUpdateInterviewPeopleAppraisalGuardService implements CanDeactivate<OrganizeInterviewComponent> {
  constructor() { }

  canDeactivate(component: OrganizeInterviewComponent): boolean | Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!component.goToGoalObjectiveList) {
        component.formatDataOpenUpdateInterviewModal();
        let clickedButton = document.getElementById("confirmBtn");
        if (clickedButton) {
          clickedButton.onclick = ((e: any) => {
            resolve(false);
          });
        } else {
          resolve(true);
        }
      } else {
        component.goToGoalObjectiveList = false;
        resolve(true);
      }
    });
  }
}