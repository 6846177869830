<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="showSidenavInformation()" (onBackFunction)="goToUpdatePrivateNote(true)"
      [isBackButton]="true"
      [title]="(userId && userId.length) ? (('privateNotes.createUpdate.SIDENAV_TITLE' | translate) + personDetails.forename + ' ' + personDetails.surname) : ('privateNotes.createUpdate.SIDENAV_TITLE_GENERIC' | translate) "
      [description]="userId && userId.length ? ('privateNotes.createUpdate.SIDENAV_DESCRIPTION' | translate) : ('privateNotes.createUpdate.SIDENAV_DESCRIPTION_GENERIC' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingPrivateNotes">
        <div class="form-container">

          <!-- Contenitore card urenti appunti multipli -->
          <ng-container *ngIf="!userId">
            <input-container [tooltipModalMessage]="whoNoteModal" [isObbligatory]="true"
              [isValued]="selectedUserList && selectedUserList.length"
              [title]="'privateNotes.createUpdate.WHO_NOTE' | translate">
              <!-- ricerca -->
              <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                placeholder="{{'privateNotes.createUpdate.SEARCH_PLACEHOLDER' | translate}}">
              </alloy-input>
              <p>
                <!-- <span class="text-label-form primary-color" translate="privateNotes.createUpdate.WHO_NOTE_SUB"></span> -->
                <!-- <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length == 1"
                  class="text-label-form primary-text-color error-color">{{ ' ' +
                  selectedUserList.length + ' ' +
                  ('generic.SELECTED' |
                  translate)}}</span> -->
                <!-- <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length > 1"
                  class="text-label-form primary-text-color error-color">{{ ' ' +
                  selectedUserList.length + ' ' +
                  ('generic.SELECTEDS' |
                  translate)}}</span> -->
              </p>
              <div class="user-card-collection-container">
                <!-- Card utente -->
                <div *ngIf="!isFetchingUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                  fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                  fxLayoutGap.lt-md="16px">
                  <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'noteUsers',
                      itemsPerPage: userCardData.numRecords,
                      currentPage: userCardData.currentPage,
                      totalItems: userCardData.counter }" [user]="user" [selectedUserList]="selectedUserList"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)"></user-card>
                </div>
                <div *ngIf="isFetchingUsers" class="full-width min-height500" fxLayout="row wrap"
                  fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                  fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                  <div class="loader spinner-medium"></div>
                </div>
                <div *ngIf="!isFetchingUsers && (!userCardData.list || !userCardData.list.length)"
                  class="full-width min-height500" fxLayout="row wrap" fxLayoutGap="16px grid"
                  fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                  fxLayoutGap.lt-md="16px">
                  <p translate="privateNotes.createUpdate.NO_RESULTS"></p>
                </div>
                <div class="full-width" *ngIf="!isFetchingUsers && userCardData.list && userCardData.list.length"
                  fxLayout="row" fxLayoutAlign="center center">
                  <table-pagination id="noteUsers" (pageChanged)="usersPageChanged($event)">
                  </table-pagination>
                </div>
              </div>

            </input-container>
          </ng-container>

          <!-- Cosa vuoi appuntare -->
          <input-container [isObbligatory]="true" [isLoading]="isFetchingPrivateNotes"
            [tooltipModalMessage]="whatToNoteModal" [isValued]="note.comment && note.comment.trim().length"
            [isObbligatory]="true" title="{{'privateNotes.createUpdate.WHAT_TO_NOTE' | translate}}">
            <alloy-text-area [bindValue]="note.comment" (onModelChanged)="whatChanged($event)"
              placeholder="{{'privateNotes.createUpdate.WHAT_TO_NOTE_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>
          <!-- Perchè stai prendendo questo appunto -->
          <input-container [isLoading]="isFetchingPrivateNotes" [tooltipModalMessage]="whyNoteModal"
            [isValued]="note.motivation && note.motivation.trim().length" [isObbligatory]="false"
            title="{{'privateNotes.createUpdate.WHY_NOTE' | translate}}">
            <alloy-text-area [bindValue]="note.motivation" (onModelChanged)="whyChanged($event)"
              placeholder="{{'privateNotes.createUpdate.WHY_NOTE_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>
          <div class="button-container">
            <alloy-button (onButtonClicked)="goToUpdatePrivateNote()"
              [disabled]="isFetchingPrivateNotes || !isObbligatoryDataInserted()" type="success default" size="large"
              [label]="(noteId && noteId.length) ? ('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE' | translate) : ('privateNotes.createUpdate.INSERT_PRIVATE_NOTE' | translate)">
            </alloy-button>
          </div>
        </div>
      </ng-container>
      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isFetchingPrivateNotes">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un appunto -->
<modal id="modalCreateUpdatePrivateNote" (onCancel)="goBackBrowser()" (onClose)="closeModalCreateUpdatePrivateNote()"
  (onConfirm)="(noteId && noteId.length) ? updatePrivateNote() : createPrivateNote()"
  cancelLabel="{{modalCreateUpdatePrivateNoteTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdatePrivateNoteTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdatePrivateNoteId" [title]="modalCreateUpdatePrivateNoteTitle"
    [subtitle]="modalCreateUpdatePrivateNoteSubtitle" [text]="modalCreateUpdatePrivateNoteText">
  </modal-text-content>
</modal>