/*
 * Modulo per la gestione dell'autenticazione
 */

import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LocalLoginComponent } from "./login/local-login.component";
import { LoginSamlErrorComponent } from "./login/login-saml-error.component";
import { LogoutSsoCompletedComponent } from "./login/logout-sso-completed.component";
import { SharedModule } from '../shared/shared.module';
import { LoginSsoComponent } from "./login/login-sso.component";
import { SignUpComponent } from './sign-up/sign-up.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';

@NgModule({
  declarations: [
    LoginComponent,
    LocalLoginComponent,
    LoginSamlErrorComponent,
    LogoutSsoCompletedComponent,
    LoginSsoComponent,
    SignUpComponent,
    PasswordRecoveryComponent
  ],
  imports: [FlexLayoutModule, CommonModule, FormsModule, TranslateModule,
    SharedModule]
})
export class AuthModule { }
