<page-container>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="'process.phases.SIDENAV_TITLE' | translate"
            [description]="'process.phases.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingPhaseData">
                <div class="form-container">

                    
                    <!-- data inizio -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="startDate" [isObbligatory]="true"
                        title="{{'process.phases.START_DATE' | translate}}">
                        <alloy-datepicker [minDate]="minDate" [value]="startDate" (dateChanged)="startDateChanged($event)"
                            placeholder="{{'process.phases.INSERT_START_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- data fine -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="endDate" [isObbligatory]="true"
                        title="{{'process.phases.END_DATE' | translate}}" >
                        <alloy-datepicker [minDate]="minDate" [value]="endDate" (dateChanged)="endDateChanged($event)"
                            placeholder="{{'process.phases.INSERT_END_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>

                    <!-- data chiusura -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="deadlineDate" [isObbligatory]="true"
                        title="{{'process.phases.DEADLINE_DATE' | translate}}" >
                        <alloy-datepicker [minDate]="minDate" [value]="deadlineDate" (dateChanged)="deadlineDateChanged($event)"
                            placeholder="{{'process.phases.INSERT_DEADLINE_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>

                    <!-- Note -->
                    <!--input-container [isObbligatory]="true" [isValued]="notes && notes.trim().length"
                        title="{{'process.create.NOTE' | translate}}">
                        <alloy-text-area [bindValue]="notes" (onModelChanged)="noteChanged($event)"
                            placeholder="{{'process.create.NOTE_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container-->


                    <div class="action-container">
                        <alloy-button class="m-auto" (onButtonClicked)="onConfirm()" [disabled]="isProcessValid()"
                            type="success default" size="large" [label]="'process.phases.actions.EDIT' | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingPhaseData">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="confirm-edit" (onClose)="closeConfirm()" [confirmLabel]="'generic.CONFIRM' | translate"
    [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeConfirm(true)" (onCancel)="closeConfirm()">
    <modal-text-content [modalId]="'ed001'" [title]="'process.phases.modals.CONFIRM_TITLE' | translate"
        [text]="'process.phases.modals.CONFIRM_DESCR' | translate">
    </modal-text-content>
</modal>