import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareTag } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { PerfAlloySmartFeedback } from 'atfcore-commonclasses';

@Component({
  selector: 'app-selfAssessment-sendClap',
  templateUrl: './sendClap.component.html',
  styleUrls: ['./sendClap.component.scss']
})
export class SendClapSelfAssessmentComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  userId: string = '';
  personDetails: any;

  modalUpdateClapTitle: string = '';
  modalUpdateClapText: string = '';
  modalUpdateClapTitleConfirmTextButton: string = '';
  modalUpdateClapTextCloseTextButton: string = '';

  isFetchingCreateSmartFeedback: boolean = false;
  isFetchingSmartFeedbackUsers: boolean = false;
  smartFeedbackFromRecord: number = 0;
  smartFeedbackNumRecords: number = 0;
  smartFeedbackCurrentPage: number = 0;
  smartFeedbackCounter: number = 0;
  smartFeedbackComment: string = "";

  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  userCardData: UserCard[] = [];
  clapId: string = '';

  selectedUserList: any = [];

  // Ricerca utente
  searchUserText: string = '';
  // modalUpdateId: string = '';
  // modalUpdateTitle: any;
  // modalUpdateText: any;
  // modalUpdateTitleConfirmTextButton: any;
  // modalUpdateTextCloseTextButton: any;
  loggedUser: any;
  forceBack: boolean = false;

  chargeUsersVariable: boolean = true;
  isImpersonate: boolean = false;

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public router: Router
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;

              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.loggedUser = loggedUser.user;

                    if (!this.userId && this.chargeUsersVariable) {
                      this.chargeUsersVariable = false;
                      this.resetSmartFeedbackUsersData();
                      this.getMyCollegueUsers()
                    }
                  })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.getMyCollegueUsers()
    this.translate.get(
      [
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP',
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR',
        'feedbackSection.sendClapPage.modals.WHICH_CARE',
        'feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR',
      ])
      .subscribe(translations => {
        this.descriptionTooltipModal = {
          modalId: "401",
          title: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO'],
          subtitle: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB'],
          text: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR'],
        };
        this.whoTooltipModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        }

        // this.modalUpdateId = this.getModalSaveId();
        // this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
      })
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetSmartFeedbackUsersData(notDeleteSelected?: boolean) {
    this.smartFeedbackFromRecord = 0;
    this.smartFeedbackNumRecords = 10;
    this.smartFeedbackCurrentPage = 1;
    this.smartFeedbackCounter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  // Esegue una nuova ricerca
  searchUsers() {
    this.getMyCollegueUsers();
  }

  // Salva il testo che si cerca
  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Recupera una lista di utenti per gli inviare gli smart feedback
  getMyCollegueUsers() {
    this.isFetchingSmartFeedbackUsers = true;
    this.selfAssessmentService.countMyCollegueUsersForManager()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {

          this.smartFeedbackCounter = data.response;

          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.smartFeedbackCurrentPage && this.smartFeedbackNumRecords) {
            fromRecord = (this.smartFeedbackCurrentPage - 1) * this.smartFeedbackNumRecords;
          } else {
            fromRecord = 0;
          }

          if (this.smartFeedbackCounter) {
            this.selfAssessmentService.listMyColleagueUsers(fromRecord, this.smartFeedbackNumRecords, this.searchUserText).subscribe
              ((data: SenecaResponse<any>) => {
                if (data && data.response) {
                  this.userCardData = UserDataUtils.getUserCardData(data.response) || [];
                }
                this.isFetchingSmartFeedbackUsers = false
              })
          }
        }
        else {
          this.isFetchingSmartFeedbackUsers = false;
        }
      }, (err?: any) => {
        this.isFetchingSmartFeedbackUsers = false;
      });
  }


  // Cambia la paginazione alla lista degli utenti per lo smart feedback
  smartFeedbackUsersPageChanged(newPage: any) {
    this.smartFeedbackCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getMyCollegueUsers();
  }



  // Descrizionde dello smart feedback 
  smartFeedbackDescriptionChanged(text: string) {
    this.smartFeedbackComment = text;
  }

  // Invia lo smart feedback ad uno o più utenti
  sendSmartFeedback(closeModal?: boolean) {
    if (closeModal) {
      this.modalService.close('smartFeedback');
    }

    let usersIds: string[] = [];
    let smartFeedback: PerfAlloySmartFeedback[] = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      usersIds.push(this.selectedUserList[i].id);
      smartFeedback.push(
        {
          senderUserId: this.loggedUser.userId,
          recipientUserId: this.selectedUserList[i].id,
          smartComment: this.smartFeedbackComment
        })
    }

    this.selfAssessmentService.createSmartFeedbackForSubordinate(this.runningYear.year, this.runningYear.yearCode, smartFeedback, usersIds)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.router.navigate(['selfAssessment/home']);
        }
      })
  }

  openSmartFeedbackModal() {
    this.modalService.open('smartFeedback');
  }

  closeSmartFeedbackModal() {
    this.modalService.close('smartFeedback');
  }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateModal() {
    // this.modalUpdateId = this.getModalSaveId();
    // this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
    this.openModalForUpdate();
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdate() {
    this.modalService.open('modalUpdateForUser');
  }

  checkObbligatoryData() {
    if (this.smartFeedbackComment) {
      if (!this.smartFeedbackComment || !this.smartFeedbackComment.trim().length) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }


  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio dell'applauso
  closeModalUpdate(backAnyway?: boolean) {
    if (backAnyway) {
      this.forceBack = true;
      this.redirectService.goToPersonDetailsFeedbackSelfAssessment(this.loggedUser.userId, this.runningYear);
    }
    this.modalService.close('modalUpdateForUser');
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}