<div class="main-container">
    <!-- Peerfeedback -->
    <ng-container *ngIf="object">
        <div class="rectangle-container">
            <!-- Card-->
            <div class="card-container">
                <!-- Header -->
                <card-feedback-header [icon]="'assets/img/icons/send.svg'" [creationDate]="creationDateText"
                    [title]="title"
                    [user]=" object.senderUser" [hasAvatar]="true" [avatarRight]="isPosted">
                </card-feedback-header>
                <div class="divisor-line"></div>

                <!-- Card profile -->
                <ng-container>
                    <div class="information-container">
                        <div class="information-text-container" *ngIf="object.motivation">
                            <!-- Text -->
                            <p class="information-title" translate="feedbackSection.feedbackToManager.REGARDING"></p>
                            <p class="information-desc">{{object.recipientUser?.surname + ' ' +
                                object.recipientUser?.forename}}</p>
                            <!-- Text -->
                            <p class="information-title" translate="feedbackSection.feedbackToManager.MOTIVATION"></p>
                            <p class="information-desc">{{object.motivation}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>