import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PerformanceAssessmentAlloyStatuses } from "atfcore-commonclasses";

@Injectable()
export class UtilsService {

    constructor(
        private translate: TranslateService
    ) { }

    getItemStatusBadge(data: any) {
        // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
        for (let i = 0; i < data.length; i++) {
            if (data[i].status == PerformanceAssessmentAlloyStatuses.STANDBY) {
                data[i].statusType = 'inProgress';
                data[i].statusMessage = this.translate.instant('process.status.STANDBY');
            } else if (data[i].status == PerformanceAssessmentAlloyStatuses.RUNNING) {
                data[i].statusType = 'completed';
                data[i].statusMessage = this.translate.instant('process.status.ACTIVE');
            } else {
                data[i].statusType = 'disabled';
                data[i].statusMessage = this.translate.instant('process.status.CLOSED');
            }
        }
        return data;
    }
}