<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.gt-xs="16px">

  <banner-home *ngIf="runningPhaseDate && runningPhaseDate.length" [phaseName]="phaseName" [backgroundSrc]="bannerImage"
    [endDate]="runningPhaseDate" [isGoalsUpdatePhase]="isGoalsUpdatePhase" [runningYear]="runningYear.year"
    [selfAssessment]="true"></banner-home>

  <ng-container *ngIf="!isLoadingCurrentYear">
    <!-- Homepage self assessment-->
    <div class="box-collection-container">
      <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
        fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
        <!-- Scheda utente -->
        <box-home *ngIf="!notAvailibleNotes" fxFlex="33" fxFlex.sm="50" fxFlex.xs="100"
          (onButtonClicked)="goToPersonDetails()" iconSrc="../../../../assets/img/icons/contact-white.svg" [iconBox]="true"
          title="{{('homeCards.selfAssessment.objectives.TITLE' | translate) + ' ' + phaseName + ' ' + (runningYear && runningYear.year)}}"
          description="homeCards.selfAssessment.objectives.DESCRIPTION">
        </box-home>
        <!-- Appunti privati -->
        <box-home *ngIf="!notAvailibleNotes" id="privateBoxTag" fxFlex="33" fxFlex.sm="50" fxFlex.xs="100"
          (onButtonClicked)="goToTakeNote()" iconSrc="../../../../assets/img/icons/homeIcons/notes-img.svg"
          [iconBox]="true" title="{{'homeCards.peopleAppraisal.notes.TITLE' | translate}}"
          description="homeCards.peopleAppraisal.notes.DESCRIPTION">
        </box-home>
        <!-- I tuoi Feedback -->
        <box-home fxFlex="33" fxFlex.sm="50" fxFlex.xs="100" (onButtonClicked)="goToFeedbackSelfAssessment()"
          iconSrc="../../../../assets/img/icons/messages-square.svg" [iconBox]="true"
          title="{{('homeCards.notSelfAssessment.objectives.TITLE' | translate)}}"
          description="homeCards.notSelfAssessment.objectives.DESCRIPTION">
        </box-home>
        <!-- Smart FeedBack-->
        <ng-container>
          <box-home id="smartFeedbackBoxTag" fxFlex="33" fxFlex.sm="50" fxFlex.xs="100"
            (onButtonClicked)="goToSmartFeedback()" iconSrc="../../../../assets/img/icons/clap-hands-white.svg"
            [iconBox]="true" title="{{'homeCards.peopleAppraisal.smartFeedback.TITLE' | translate}}"
            [disabledModalMessage]="feedbackDisabledModal"
            description="homeCards.peopleAppraisal.smartFeedback.DESCRIPTION">
          </box-home>
        </ng-container>
        <!-- Peer FeedBack-->
        <ng-container>
          <box-home id="peerFeedbackBoxTag" fxFlex="33" fxFlex.sm="50" fxFlex.xs="100"
            (onButtonClicked)="goToRequestFeedback()" iconSrc="../../../../assets/img/icons/message-circle.svg"
            [iconBox]="true" title="{{'homeCards.selfAssessment.peerFeedback.TITLE' | translate}}"
            [disabledModalMessage]="feedbackDisabledModal"
            description="homeCards.selfAssessment.peerFeedback.DESCRIPTION">
          </box-home>
        </ng-container>
        <!-- Feedback al responsabile-->
        <ng-container>
          <box-home id="feedbackToManagerBoxTag" fxFlex="33" fxFlex.sm="50" fxFlex.xs="100"
            (onButtonClicked)="goToFeedbackToManager()" iconSrc="../../../../assets/img/icons/send.svg"
            [iconBox]="true" title="{{'homeCards.selfAssessment.feedbackToManager.TITLE' | translate}}"
            [disabledModalMessage]="feedbackDisabledModal"
            description="homeCards.selfAssessment.feedbackToManager.DESCRIPTION">
          </box-home>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>