import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreActions from "../../core/ngrx/core.actions";
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { catchError, switchMap, take } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utilsService';

@Component({
  selector: 'admin-setup-competences',
  templateUrl: './setupCompetences.component.html',
  styleUrls: ['./setupCompetences.component.scss']
})
export class SetupCompetencesComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;

  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: {
    id: number,
    code: string
  } = {
      id: 0,
      code: ''
    }
  isLoadingProcessDetails: boolean = false;
  getProcessDetails$: Subscription = new Subscription;
  processDetail: any[] = [];
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;
  tabList: { id: string; name: any; }[] = [];
  processYearSelected: boolean = false;
  translations: any;
  currentContentTab: any
  isLoadingAllCompetencesData: boolean = false;
  isLoadingYear: boolean = false;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    private utilsService: UtilsService
  ) {
    this.isLoadingYear = true;
    this.processYearSelected = false;

    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'setupCompetences.ALL_COMPETENCES',
      'setupCompetences.ROLE_COMPETENCES',
      'setupCompetences.DICTIONARY',
      'setupCompetences.TECH_SOFT',
      'setupCompetences.TECH_SKILL',
    ]).subscribe(translations => {
      this.translations = translations;
      this.tabList = [
        {
          id: 'all',
          name: translations['setupCompetences.ALL_COMPETENCES']
        },
        {
          id: 'role-competence',
          name: translations['setupCompetences.ROLE_COMPETENCES']
        },
        {
          id: 'dictionary',
          name: translations['setupCompetences.DICTIONARY']
        }
      ]

      this.route.params.subscribe((params: any) => {
        if (params.processYear) {
          this.processYear = {
            id: params.processYear,
            code: params.processYearCode
          }
        }

        if (this.processYear && this.processYear.id) {
          this.processYearSelected = true;
          if (window.location.href.includes('/rolesAndCompetences')) {
            this.currentContentTab = this.tabList[1];
          } else if (window.location.href.includes('/dictionaries')) {
            this.currentContentTab = this.tabList[2];
          } else {
            this.currentContentTab = this.tabList[0];
          }
          this.isLoadingYear = false;
        } else {
          this.processYearSelected = false;
          this.isLoadingYear = false;
          this.getProcessesData();
        }
      })

    });
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            data.response = this.utilsService.getItemStatusBadge(data.response);
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }


  goToProcessDetails(process: any) {
    this.router.navigate(['admin/setupCompetences/' + process.processYear + '/' + process.processYearCode + '/all/softSkills']);
  }

  orderBy(fieldName: string) {
  }

  onContentTabChanged(selected: any) {
    this.currentContentTab = this.tabList.find((tab: any) => tab.id == selected);
    let url = 'admin/setupCompetences/' + this.processYear.id + '/' + this.processYear.code;
    if (this.currentContentTab.id == 'role-competence') {
      url += '/rolesAndCompetences/softSkills';
    } else if (this.currentContentTab.id == 'dictionary') {
      url += '/dictionaries';
    } else if (this.currentContentTab.id == 'all') {
      url += '/all/softSkills';
    }
    this.router.navigate([url], { replaceUrl: true })
  }


  downloadReport() {
    this.isDownloadingReport = true;
    this.adminService.downloadCompetencesReport(this.processYear.id, this.processYear.code)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }


  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    if (this.getProcessDetails$) {
      this.getProcessDetails$.unsubscribe();
    }
  }
}


