<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content">
    <div class="page-content">
      <svg-icon class="logo-container" [applyClass]="true" src="./assets/img/icons/webranking-logo.svg"></svg-icon>
      <p>
        <span class="small-font-weight" translate="403.TITLE"></span><br>
        <span translate="403.SUBTITLE" class="bold-text"></span>
      </p>
      <p translate="403.CONTENT"></p>
      <div class="back-button-container">
        <alloy-button type="success default" size="large" label="{{'generic.GO_IN_HOME' | translate}}"
          (onButtonClicked)="redirectService.goToHome()"></alloy-button>
      </div>
    </div>
  </div>
</page-container>