import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { CountUsersForImpersonateResponse, GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { switchMap, catchError, take } from 'rxjs/operators';

@Component({
  selector: 'admin-people-anagraphics',
  templateUrl: './peopleAnag.component.html',
  styleUrls: ['./peopleAnag.component.scss']
})
export class PeopleAnagComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  isLoadingUserData: boolean = false;
  getUserData$: Subscription = new Subscription;
  userData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }
  sidebarData: any
  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  userId: string = '';
  menuOptions: any[] = [];
  searchedText: string = '';
  stiDateTooltip: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  today = moment().format();

  userDetails: any;
  isLoadingUserDetails: boolean = false;


  selectedApprover: any;
  approversList: any[] = [];
  approverSelectOpen: boolean = false;
  importFile: any;
  resetIncludeUpload: boolean = false;
  selectedFilters: any = {};
  selectedFiltersNumber: number = 0;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    public router: Router,
    private sidebarService: SidebarService,
    private authService: AuthService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params
              .subscribe(
                (params: Params) => {
                  this.userId = params.userId;
                  this.getUsersData();
                })
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'process.details.PERSON_DETAILS',
      'process.details.MANAGE_STI',
      'process.details.EXCLUDE_FROM_PROCESS',
      'process.sti.ENABLE_TITLE',
      'process.sti.ENABLE_INFO',
      'process.sti.GIVE_VALUE',
      'process.sti.GIVE_VALUE_INFO',
      'process.sti.SIGNED_DATE_TITLE',
      'process.sti.SIGNED_DATE_INFO',
      'process.sti.CONFIRM_ENABLE',
      'process.sti.CONFIRM_ENABLE_SUB',
      'process.sti.CONFIRM_ENABLE_TEXT',
      'process.manage.INCLUDE_TITLE',
      'process.manage.INCLUDE_INFO',
      'process.manage.EXCLUDE_TITLE',
      'process.manage.EXCLUDE_INFO',
      'headerDossier.processSetup.PARTICIPANT',
      'headerDossier.processSetup.OBJECTIVE_DEFINITION',
      'headerDossier.processSetup.PROCESS_PHASES'
    ]).subscribe((translations) => {
      this.stiDateTooltip = {
        modalId: 'ps012',
        title: translations['process.sti.SIGNED_DATE_TITLE'],
        text: translations['process.sti.SIGNED_DATE_INFO']
      }

      this.menuOptions = [
        {
          id: 'details',
          title: translations['process.details.PERSON_DETAILS'],
          icon: '/assets/img/icons/clipboard.svg',
        },
        {
          id: 'manageSTI',
          title: translations['process.details.MANAGE_STI'],
          icon: '/assets/img/icons/pie-chart.svg',
        },
        {
          id: 'exclude',
          title: translations['process.details.EXCLUDE_FROM_PROCESS'],
          icon: '/assets/img/icons/trash-2.svg',
        }
      ]
    })
  }

  // ricerca
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.userData.list = [];
    this.userData.fromRecord = 0;
    this.userData.page = 1;
    this.getUsersData();
  }



  userPageChanged(page: number) {
    this.userData.page = page;
    this.userData.list = [];
    this.getUsersData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.userData.numRecords = item.id;
    this.userData.list = [];
    this.userData.fromRecord = 0;
    this.getUsersData();
  }

  // menu utente
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    user.isMenuOpen = !user.isMenuOpen;
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any) {
    user.isMenuOpen = false;
    if (options.id == 'details') {

    } else if (options.id == 'manageSTI') {
      this.modalService.open('manageSTI');
    } else {
      // this.modalService.open('deleteProcess');
    }
  }

  // Funzioni modale filtri
  openFiltersModal() {
    this.modalService.open('filtersModal')
  }

  applyFilters() {
    this.closeFiltersModal();
    this.getUsersData();
  }

  clearFilters() {
    this.closeFiltersModal();
    this.getUsersData();

  }

  getUsersDataFilter() {
    this.userData.page = 1;
    this.getUsersData();
  }

  closeFiltersModal() {
    this.modalService.close("filtersModal")
  }

  downloadList() {
  }

  showApproverSelect() {
    this.approverSelectOpen = !this.approverSelectOpen;
  }

  // seleziona l'utente approvatore nella pagina di dettaglio
  selectApprover(newApprover: any) {
    this.selectedApprover = newApprover;
  }

  openSidenavDetail(user: any) {
    this.adminService.DetailPeopleAnag(user.userId).subscribe(
      (res) => {
        this.sidebarData = res.response;
      }
    );
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }

  orderBy(fieldName: string) {
  }

  emitOnDownloadList() {
    this.adminService.exportUsersAnag().subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "a004",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        let filename = data.response;
        this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
          if (data && data.response) {
            let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
            setTimeout(() => {
              window.open(downloadUrl, '_blank');
            }, 500)
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "a007",
              text: this.translate.instant("errors." + data && data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a008",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
      }
    }, (err: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "a005",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    })
  }

  emitOnImportList() {
    this.modalService.open('import-modal-include');
  }

  assignFile(file: any) {
    this.importFile = file;
  }

  closeModalImportInclude(confirm?: boolean) {
    this.modalService.close('import-modal-include');
    if (confirm) {
      this.isLoadingUserData = true;
      this.adminService.importUsers(this.importFile).subscribe(
        (data: any) => {
          console.log(data);
          if (data && data.body) {
            if (data.body.response.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "a004",
                text: this.translate.instant("errors." + data.body.response.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }else{
              this.getUsersData();
            }
          }
        }
      );
    }
  }

  downloadTrack() {
    this.adminService.downloadImportUsers().subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "a004",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        let filename = data.response;
        this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
          if (data && data.response) {
            let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
            setTimeout(() => {
              window.open(downloadUrl, '_blank');
            }, 500)
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "a007",
              text: this.translate.instant("errors." + data && data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a008",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
      }
    }, (err: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "a005",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    })
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  // ricerca
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  getUsersData(fromSearch?: any) {
    this.isLoadingUserData = true;

    sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');

    this.adminService.countPeopleAnag(this.searchedText, this.selectedFilters)
      .pipe(
        switchMap(
          (counter) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userData.counter = counter.response;

              // Calcolo la paginazione
              this.userData.fromRecord = (this.userData.page - 1) * this.userData.numRecords;

              if (this.userData.counter) {
                return this.adminService.listPeopleAnag(this.userData.fromRecord, this.userData.numRecords, this.searchedText, this.selectedFilters);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a009",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a010",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.userData.list = data.response || [];
          }
          this.isLoadingUserData = false;
        }
        , (err: any) => {
          this.isLoadingUserData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a011",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  ngOnDestroy() {
    if (this.getUserData$) {
      this.getUserData$.unsubscribe();
    }
  }

}


