import * as CommonClasses from "../../../commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { UserOptions, UserAcknowledges, GetNotificationListResponse, SenecaResponse } from "../../../commonclasses";
import { Observable } from "rxjs";

@Injectable()
export class AuthService {
  applicationContext: string;
  constructor(private urlService: UrlService,
    private http: HttpClient) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  getSSOUrl = () => {
    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/oauth-start', {
    });
  }

  loginFromOAuthCallback = (code: any, deviceType: any, userAgent: any) => {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('code', code);
    httpParams = httpParams.append('deviceType', deviceType);
    httpParams = httpParams.append('userAgent', userAgent);
    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/login-from-oauth-callback', {
      params: httpParams
    });
  }

  // Servizio che tenta il login
  login(email: string, password: string, device?: string, userAgent?: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/login-local-password', { email: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
  }

  // Servizio che tenta il login prod
  loginProd(email: string, password: string, device?: string, userAgent?: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/login', { email: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
  }

  // Servizio che aggiorna una ack
  updateUserAcknowledges(userAcknowledges: UserAcknowledges) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/update-userAcknowledges', {
      userAcknowledges: userAcknowledges
    });
  }

  // Servizio che recupera il JWT token dato un tiny token
  getAllUserAcknowledges() {
    return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-all-userAcknowledges');
  }

  updateUserOptions(userOptions: UserOptions) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/update-userOptions', {
      userOptions: userOptions
    });
  }

  // Metodo per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]) {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      let currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }
    return isAuthorized;
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(token: string): any {
    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-full-jwt-token', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Servizio che recupera un token nuovo
  renewToken(sessionStorageToken: string, forceRefreshUser?: boolean) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/renew-token', { forceRefreshUser: forceRefreshUser }, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorageToken) });
  }

  // Servizio che setta una nuova password
  setNewPassword(oldPassword: string, newPassword: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/set-new-password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  // Logout
  logout() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/logout', {
    });
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }

  // Crea in base al token l'ssorqtp
  crateRetrieveTokenAfterLogin() {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/create-retrieve-token-after-login', null);
  }

  // Ritorna l'anno del processo in corso
  getPerformanceAssessmentRunningYear() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-running-performance-assessment-process', {});
  }

  // Ritorna l'anno del processo in corso
  getPerformanceAssessmentRunningPhase(perfYear: number, perfYearCode: string): Observable<CommonClasses.SenecaResponse<CommonClasses.AlloyPerformanceItemServiceResponses.GetPerformanceAssessmentEndDate>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<CommonClasses.SenecaResponse<CommonClasses.AlloyPerformanceItemServiceResponses.GetPerformanceAssessmentEndDate>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-end-date', {
      params: httpParams
    });
  }

  // Recupera una lista di notifiche
  getNotificationList(fromRecord?: number, numRecords?: number, allData?: boolean, getOnlyUnread?: boolean, getOnlyRead?: boolean): Observable<SenecaResponse<GetNotificationListResponse>> {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord.toString());
    }

    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords.toString());
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    }

    if (getOnlyUnread) {
      httpParams = httpParams.append('getOnlyUnread', getOnlyUnread.toString());
    }
    if (getOnlyRead) {
      httpParams = httpParams.append('getOnlyRead', getOnlyRead.toString());
    }

    return this.http.get<SenecaResponse<GetNotificationListResponse>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-notification-list', {
      params: httpParams
    });
  }

  // Marca la notifica come letta
  markNotificationAsRead(notificationQueueId: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/mark-notification-as-read', { notificationQueueId: notificationQueueId });
  }

  // Marca tutte le notifiche come lette
  markNotificationAllAsRead() {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/mark-all-notification-as-read', {});
  }

  // Cancella le notifiche passate come parametro
  deleteNotificationByIds(notificationQueueIds: string | string[]) {
    let httpParams = new HttpParams();
    if (notificationQueueIds && notificationQueueIds.length && Array.isArray(notificationQueueIds)) {
      notificationQueueIds && notificationQueueIds.forEach(type => {
        httpParams = httpParams.append('notificationQueueId', type);
      });
    } else if (notificationQueueIds && notificationQueueIds.length && !Array.isArray(notificationQueueIds)) {
      httpParams = httpParams.append('notificationQueueId', notificationQueueIds);
    }
    return this.http.get<CommonClasses.SenecaResponse<boolean>>(this.applicationContext + 'rest-api/alloyperformance-mediator/delete-notification-by-ids', {
      params: httpParams
    });
  }

  // Cancella tutte le notifiche lette
  deleteAllNotifications() {
    return this.http.get<CommonClasses.SenecaResponse<boolean>>(this.applicationContext + 'rest-api/alloyperformance-mediator/delete-all-my-read-notifications/');
  }

  // Conta le notifiche
  countNotifications(getOnlyUnread?: boolean) {
    let httpParams = new HttpParams();
    if (getOnlyUnread) {
      httpParams = httpParams.append('getOnlyUnread', getOnlyUnread.toString());
    }
    return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-notification-count', {
      params: httpParams
    });

  }

  getDownloadTempFileUrl(filename: string, ssorqtp?: string): string {
    return this.applicationContext + 'rest-api/alloyperformance-mediator/download-temp-file?filename=' + filename + '&ssortkqp=' + ssorqtp;
  }

  getMaskedUrlDownload(maskedUrl: string, fileName: string): string {
    return this.applicationContext + 'rest-api/alloyperformance-mediator/proxy-url/' +
      encodeURIComponent(fileName) +
      "?requestUrl=" +
      encodeURIComponent(maskedUrl)
  }

  initUserPasswordRecovery(email: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/init-user-password-recovery', { email: email });
  }

  uptdateEntiledPassword(validationToken: string, userId: string, newPassword: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/update-entitled-password', { validationToken: validationToken, userId: userId, newPassword: newPassword });
  }

  registerUser(email: string, password: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/register-user', { email: email, password: password });
  }

  confirmUserRegistration(userId: string, confirmToken: string) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/alloyperformance-mediator/confirm-user-registration', { userId: userId, confirmToken: confirmToken });
  }

}