<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="dictionaryId ? title : ('setupCompetences.dictionary.NEW' | translate)"
            [description]="sidenavDescr">
        </sidenav-informations>
    </div>


    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <div class="form-container">
                <!-- Placeholder caricamento -->
                <ng-container *ngIf="isLoadingData">
                    <div class="loader-container">
                        <div class="loader spinner-big"></div>
                    </div>
                </ng-container>

                <!-- dati caricati -->
                <ng-container *ngIf="!isLoadingData">

                    <!-- Titolo -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="title && title.trim().length" [isObbligatory]="true"
                        [title]="'setupCompetences.dictionary.TITLE' | translate">
                        <alloy-input [bindValue]="title" (onModelChanged)="titleChanged($event)"
                            [placeholder]="'setupCompetences.dictionary.INSERT_TITLE_PLACEHOLDER' | translate">
                        </alloy-input>
                    </input-container>

                    <!-- Descrizione -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="description && description.trim().length" [isObbligatory]="false"
                        [title]="'setupCompetences.dictionary.DESCRIPTION' | translate">
                        <alloy-text-area [bindValue]="description" [maxlength]="300"
                            (onModelChanged)="descriptionChanged($event)"
                            placeholder="{{'setupCompetences.dictionary.DESCRIPTION_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>


                    <!-- Aspettative non soddisfatte -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="pointDescription[1] && pointDescription[1].trim().length" [isObbligatory]="true"
                        [title]="'starRating.1' | translate">
                        <alloy-text-area [bindValue]="pointDescription[1]" [maxlength]="300"
                            (onModelChanged)="pointDescriptionChanged($event,1)"
                            placeholder="{{'setupCompetences.dictionary.TEXT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <!-- Aspettative parzialmente soddisfatte -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="pointDescription[2] && pointDescription[2].trim().length" [isObbligatory]="true"
                        [title]="'starRating.2' | translate">
                        <alloy-text-area [bindValue]="pointDescription[2]" [maxlength]="300"
                            (onModelChanged)="pointDescriptionChanged($event, 2)"
                            placeholder="{{'setupCompetences.dictionary.TEXT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <!-- Aspettative pienamente soddisfatte -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="pointDescription[3] && pointDescription[3].trim().length" [isObbligatory]="true"
                        [title]="'starRating.3' | translate">
                        <alloy-text-area [bindValue]="pointDescription[3]" [maxlength]="300"
                            (onModelChanged)="pointDescriptionChanged($event, 3)"
                            placeholder="{{'setupCompetences.dictionary.TEXT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <!-- Aspettative superate -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="pointDescription[4] && pointDescription[4].trim().length" [isObbligatory]="true"
                        [title]="'starRating.4' | translate">
                        <alloy-text-area [bindValue]="pointDescription[4]"
                            (onModelChanged)="pointDescriptionChanged($event, 4)"
                            placeholder="{{'setupCompetences.dictionary.TEXT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <!-- Prestazioni eccellenti -->
                    <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="pointDescription[5] && pointDescription[5].trim().length" [isObbligatory]="true"
                        [title]="'starRating.5' | translate">
                        <alloy-text-area [bindValue]="pointDescription[5]"
                            (onModelChanged)="pointDescriptionChanged($event, 5)"
                            placeholder="{{'setupCompetences.dictionary.TEXT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <!-- Salva modifiche / cancella-->
                    <div class="action-container">
                        <alloy-button [label]="'generic.CANCEL' | translate" type="success bordless"
                            (onButtonClicked)="onCancel()"></alloy-button>
                        <alloy-button [disabled]="false" [label]="'generic.SAVE' | translate" type="success"
                            (onButtonClicked)="onConfirm()" [disabled]="isSaveDisabled()">
                        </alloy-button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</page-container>

<!-- Modale crea/aggiorna dizionario -->
<modal id="confirm-create-update" (onClose)="closeConfirmModal()" (onConfirm)="closeConfirmModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeConfirmModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content modalId="cred001"
        [title]="(dictionaryId ? 'setupCompetences.modals.UPDATE_DICTIONARY_TITLE' : 'setupCompetences.modals.CREATE_DICTIONARY_TITLE') | translate"
        [text]="(dictionaryId ? 'setupCompetences.modals.UPDATE_DICTIONARY_DESCR' : 'setupCompetences.modals.CREATE_DICTIONARY_DESCR') | translate">
    </modal-text-content>
</modal>