import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  loggedUser: any;
  // dashboard
  dashboardType: string = 'MY_TEAM';
  dashboardTypeStructure: boolean = false;
  // Filtri
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};

  // selfAssessment
  selfAssessmentPercentage: number = 0;
  selfAssessmentOpen: boolean = false;
  selfAssessmentProgress: any;
  getSelfAssessmentData$: Subscription = new Subscription;
  isLoadingSelfAssessmentData: boolean = false;
  selfAssessmentNoData: boolean = false;

  // People appraisal
  peopleAppraisalPercentage: number = 0;
  peopleAppraisalOpen: boolean = false;
  peopleAppraisalData: any;
  getPeopleAppraisalData$: Subscription = new Subscription;
  isLoadingPeopleAppraisalData: boolean = false;
  peopleAppraisalNoData: boolean = false;

  // Calibration
  calibrationPercentage: number = 0;
  calibrationOpen: boolean = false;
  calibrationData: any;
  getCalibrationData$: Subscription = new Subscription;
  isLoadingCalibrationData: boolean = false;
  calibrationNoData: boolean = false;

  // Pas
  pasPercentage: number = 0;
  pasOpen: boolean = false;
  pasData: any;
  getPasData$: Subscription = new Subscription;
  isLoadingPasData: boolean = false;

  // Comportamenti e competenze
  behaviorCompetenceOpen: boolean = false;
  isLoadingBehaviorCompetenceData: boolean = false;
  behaviorCompetenceData: any;
  getBehaviorCompetenceData$: Subscription = new Subscription;

  //  Overview
  isLoadingOverviewData: boolean = false;
  getOverviewData$: Subscription = new Subscription;
  isDownloadingReport: boolean = false;

  careColors: string[] = ["#C6EBB3", "#58BC5D", "#378C0C"];

  actionColor: string[] = ["#E4002B", "#F5B336", "#25BB48"];
  midTermColor: string[] = ["#E4002B", "#25BB48", "#DBDBDB"];

  /** Overview finale */
  productionData: any[] = [];
  finalPalette: string[] = ['#58BC5D']
  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];

  // lista processi
  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  processYear!: number;
  selectedRows: any;

  isProcessClosed$: Subscription = new Subscription;
  isProcessClosed: boolean = false;
  downloadReport$: Subscription = new Subscription;
  processYearCode: any;
  selectedFiltersNumber: any;
  translations: any;
  showOverviewGraph: boolean = true;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.redrawGraphs();
  }

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private adminService: AdminService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private sharedService: SharedService,
    public redirectService: RedirectService) {

    this.selectedRows = this.rowPerPageOptions[0];

    this.route.params
      .subscribe((params: Params) => {
        if (params.processYear) {
          this.processYear = params.processYear;
        }
        if (params.processYearCode) {
          this.processYearCode = params.processYearCode;
        }

        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Dashboard - Admin");


      });
  }

  ngOnInit(): void {
    this.translate.get([
      'dashboard.feedbackTypes.SENT_SMART_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK',
      'dashboard.feedbackTypes.SENT_PEER_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK',
      'dashboard.feedbackTypes.EVIDENCES',
      'dashboard.feedbackTypes.INTERVIEWS',
      'dashboard.FILTERED',
      'dashboard.TO_START',
      'dashboard.STARTED',
      'dashboard.SENT_HR',
      'dashboard.accordion.SELF_ASSESSMENT',
      'dashboard.accordion.EVALUATION',
      'dashboard.POST',
      'dashboard.pas.ELIGIBLE',
      'dashboard.pas.SELF_SENT',
      'dashboard.pas.ASSESSMENTS_SENT',
      'dashboard.pas.POST_SENT',
      'dashboard.pas.RETURNED',
      'dashboard.ENTIRE_COMPANY',
      'dashboard.PAS_EVALUATED',
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.store.select(fromApp.getLoggedUser)
        .subscribe((loggedUser: any) => {
          this.loggedUser = loggedUser && loggedUser.user;

          if (this.processYear) {
            this.getGraphData()
          } else {
            this.isLoadingSelfAssessmentData = true;
            this.isLoadingPeopleAppraisalData = true;
            this.getProcessesData()
          }
        }
        );
    })
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  goToProcessDetails(process: any) {
    this.router.navigate(['/admin/dashboard/', process.processYear, process.processYearCode]);
  }

  openDashboardTooltip() {
    this.modalService.open('dashboard-tooltip');
  }

  closeDashboardTooltip() {
    this.modalService.close('dashboard-tooltip');
  }

  // Personalizza le label delle tabelle
  customizePercentageText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeSeries(valueFromNameField: string) {
    return valueFromNameField === 'Azienda intera'
      ? { type: 'spline', label: { visible: true }, color: '#378C0C' } : {};
  }


  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  redrawGraphs(graphSection?: any) {
    let graphToRedraw: string[] = [];
    switch (graphSection) {
      case 'self-assessment': {
        graphToRedraw = ["selfAssessmentGauge", "selfAssessmentChart"];
        break;
      }
      case 'people-appraisal': {
        graphToRedraw = ["peopleAppraisalGauge", "peopleAppraisalChart"];
        break;
      }
      case 'calibration': {
        graphToRedraw = ["calibrationGauge", "calibrationChart"];
        break;
      }
      default:
        graphToRedraw = Object.keys(this.chartInstances);
        break;
    }
    if (graphToRedraw && graphToRedraw.length) {
      setTimeout(() => {
        //let keys = Object.keys(this.chartInstances);
        for (let i = 0; i < graphToRedraw.length; i++) {
          if (this.chartInstances[graphToRedraw[i]]) {
            this.chartInstances[graphToRedraw[i]].render();
          }
        }
      }, 100);
    }
  }


  // inizializza i dati dei grafici
  getGraphData(event?: any) {
    this.selfAssessmentNoData = false;
    this.peopleAppraisalNoData = false;
    this.calibrationNoData = false;
    if (this.selectedFiltersNumber > 0) {
      sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
      sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    }
    this.getOverviewData();
    this.getSelfAssessmentData();
    this.getPeopleAppraisalData();
    this.getCalibrationData();
    this.getPasData();
    this.getBehaviorCompetenceData();
  }


  // controlla se sto caricando qualcosa
  isLoading() {
    return this.isLoadingSelfAssessmentData || this.isLoadingPeopleAppraisalData;
  }

  getSelfAssessmentData() {
    this.isLoadingSelfAssessmentData = true;
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }

    this.getSelfAssessmentData$ = this.adminService.getDashboardSelfAssessment(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        } else {
          this.selfAssessmentProgress = {};
          this.selfAssessmentPercentage = Math.round(data.response.progress * 100) || 0;
          this.selfAssessmentProgress.completedPercentage = this.selfAssessmentPercentage;
          if (data.response.noUsersFound) {
            this.selfAssessmentNoData = true;
          }
          this.selfAssessmentProgress.total = data.response.total;
          this.selfAssessmentProgress.sent = data.response.totalSent;
          this.selfAssessmentProgress.chartData = [
            {
              attribute: this.translations['dashboard.SENT_HR'],
              number: data.response.totalSent,
              color: '#C6EBB3',
            },
            {
              attribute: this.translations['dashboard.STARTED'],
              number: data.response.totalStarted,
              color: '#58BC5D',
            },
            {
              attribute: this.translations['dashboard.TO_START'],
              number: data.response.totalToStart,
              color: '#378C0C',
            }
          ]

          this.isLoadingSelfAssessmentData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        });
  }


  getPeopleAppraisalData() {
    this.isLoadingPeopleAppraisalData = true;
    if (this.getPeopleAppraisalData$) {
      this.getPeopleAppraisalData$.unsubscribe()
    }

    this.getPeopleAppraisalData$ = this.adminService.getDashboardPeopleAppraisal(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeopleAppraisalData = false;
        } else {
          this.peopleAppraisalData = {};
          this.peopleAppraisalPercentage = Math.round(data.response.progress * 100) || 0;
          this.peopleAppraisalData.completedPercentage = this.peopleAppraisalPercentage;
          if (data.response.noUsersFound) {
            this.peopleAppraisalNoData = true;
          }
          this.peopleAppraisalData.total = data.response.total;
          this.peopleAppraisalData.sent = data.response.totalSent;
          this.peopleAppraisalData.chartData = [
            {
              attribute: this.translations['dashboard.SENT_HR'],
              number: data.response.totalSent,
              color: '#C6EBB3',
            },
            {
              attribute: this.translations['dashboard.STARTED'],
              number: data.response.totalStarted,
              color: '#58BC5D',
            },
            {
              attribute: this.translations['dashboard.TO_START'],
              number: data.response.totalToStart,
              color: '#378C0C',
            }
          ]

          this.isLoadingPeopleAppraisalData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da004",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeopleAppraisalData = false;
        });
  }

  getCalibrationData() {
    this.isLoadingCalibrationData = true;
    if (this.getCalibrationData$) {
      this.getCalibrationData$.unsubscribe()
    }

    this.getCalibrationData$ = this.adminService.getDashboardCalibration(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCalibrationData = false;
        } else {
          this.calibrationData = {};
          this.calibrationPercentage = Math.round(data.response.progress * 100) || 0;
          this.calibrationData.completedPercentage = this.peopleAppraisalPercentage;
          if (data.response.noUsersFound) {
            this.calibrationNoData = true;
          }
          this.calibrationData.total = data.response.total;
          this.calibrationData.sent = data.response.postCalibration;
          this.calibrationData.chartData = [
            {
              attribute: this.translations['dashboard.POST'],
              number: data.response.postCalibration,
              color: '#C6EBB3',
            },
            {
              attribute: this.translations['dashboard.accordion.EVALUATION'],
              number: data.response.peopleAppraisal,
              color: '#58BC5D',
            },
            {
              attribute: this.translations['dashboard.accordion.SELF_ASSESSMENT'],
              number: data.response.selfAssessment,
              color: '#378C0C',
            }
          ]
          this.isLoadingCalibrationData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCalibrationData = false;
        });
  }

  getPasData() {
    this.isLoadingPasData = true;
    if (this.getPasData$) {
      this.getPasData$.unsubscribe()
    }

    this.getPasData$ = this.adminService.getDashboardPas(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasData = false;
        } else {
          this.pasData = {};
          this.pasPercentage = Math.round(data.response.progress * 100) || 0;
          this.pasData = [
            {
              title: this.translations['dashboard.pas.ELIGIBLE'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.totalEligible / data.response.totalUsers) * 100)) + ' orange',
              value: data.response.totalEligible
            },
            {
              title: this.translations['dashboard.pas.SELF_SENT'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.selfAssessmentSent / data.response.totalUsers) * 100)) + ' orange',
              value: data.response.selfAssessmentSent
            },
            {
              title: this.translations['dashboard.pas.ASSESSMENTS_SENT'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.appraisalSent / data.response.totalUsers) * 100)) + ' orange',
              value: data.response.appraisalSent
            },
            {
              title: this.translations['dashboard.pas.POST_SENT'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.postCalibrationSent / data.response.totalUsers) * 100)) + ' orange',
              value: data.response.postCalibrationSent
            },
            {
              title: this.translations['dashboard.pas.RETURNED'],
              class: "percentage" + Math.trunc(Math.ceil((data.response.returnedToSubordinate / data.response.totalUsers) * 100)) + ' dark-orange',
              value: data.response.returnedToSubordinate
            },
          ]
          this.isLoadingPasData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasData = false;
        });
  }

  getBehaviorCompetenceData() {
    this.isLoadingBehaviorCompetenceData = true;
    if (this.getBehaviorCompetenceData$) {
      this.getBehaviorCompetenceData$.unsubscribe()
    }

    this.getBehaviorCompetenceData$ = this.adminService.getDashboardBehaviorCompetence(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingBehaviorCompetenceData = false;
        } else {
          this.behaviorCompetenceData = {};
          this.behaviorCompetenceData.behaviors = data.response.behaviours;
          this.behaviorCompetenceData.competences = data.response.competences;

          this.isLoadingBehaviorCompetenceData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingBehaviorCompetenceData = false;
        });
  }

  getOverviewData() {
    this.isLoadingOverviewData = true;
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }

    this.getOverviewData$ = this.adminService.getDashboardOverview(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        } else {
          this.productionData = [];
          this.showOverviewGraph = !data.response.hideChart;
          let evaluations = [
            "LOW_PERFORMER",
            "DEVELOPING_PERFORMER",
            "SOLID_PERFORMER",
            "ADVANCE_PERFORMER",
            "EXCEPTIONAL_PERFORMER",
          ]

          let companyTotal = data.response.totalInCompany;
          let filteredTotal = data.response.totalFiltered;

          for (let i = 0; i < evaluations.length; i++) {
            let response = data.response[evaluations[i]];
            if (response) {
              if (this.showOverviewGraph) {
                this.productionData.push({
                  status: evaluations[i] == 'ADVANCE_PERFORMER' ? 'Advanced' : (evaluations[i].charAt(0).toUpperCase() + evaluations[i].slice(1).toLowerCase()).split('_')[0],
                  structure: this.translations['dashboard.ENTIRE_COMPANY'],
                  value: Math.round((response.allUsers / companyTotal) * 100),
                  tooltip: response.allUsers,
                  tooltipAllUser: companyTotal
                })
              }
              // // TODO DA TOGLIERE
              // this.productionData.push({
              //   status: evaluations[i] == 'ADVANCE_PERFORMER' ? 'Advanced' : (evaluations[i].charAt(0).toUpperCase() + evaluations[i].slice(1).toLowerCase()).split('_')[0],
              //   structure: this.translations['dashboard.ENTIRE_COMPANY'],
              //   value: Math.round(Math.random() * 100),
              //   tooltip: "PROVA " + Math.round(Math.random() * 100),
              //   tooltipAllUser: "TOTAL " + Math.round(Math.random() * 100),
              //   isSplince: true
              // })
              // // TODO DA TOGLIERE
              this.productionData.push({
                status: evaluations[i] == 'ADVANCE_PERFORMER' ? 'Advanced' : (evaluations[i].charAt(0).toUpperCase() + evaluations[i].slice(1).toLowerCase()).split('_')[0],
                structure: this.translations['dashboard.PAS_EVALUATED'] + data.response.totalFilteredEvaluated + '/' + filteredTotal,
                value: Math.round((response.filtered / filteredTotal) * 100),
                tooltip: response.filtered,
                tooltipAllUser: filteredTotal
              })
            }
          }
          this.isLoadingOverviewData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadDashboardReport(this.processYear, this.processYearCode, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  customizeTooltip = (info: any) => (
    {
      html: `<div><div class='tooltip-header'>${this.translate.instant('dashboard.NUM_PAS')}</div>`
        + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
        + `<span class=\'top-series-name\'>${(info.points[1]?.point?.data?.value >= 0 ? info.points[1].point.data.value : (info.points[0].point.data.value || 0)) + '% - ' + (info.points[1]?.point?.data?.tooltip >= 0 ? info.points[1]?.point?.data?.tooltip : (info.points[0]?.point?.data?.tooltip || 0)) + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + (info.points[1]?.point?.data?.tooltipAllUser >= 0 ? info.points[1]?.point?.data?.tooltipAllUser : (info.points[0]?.point?.data?.tooltipAllUser || 0)) + ' ' + this.translate.instant('dashboard.TOTAL')}</span></div></div></div>`
    }
  );

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }


  ngOnDestroy(): void {
    // if (this.combinedSelected$) {
    //   this.combinedSelected$.unsubscribe();
    // }
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }
    if (this.getPeopleAppraisalData$) {
      this.getPeopleAppraisalData$.unsubscribe()
    }
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    if (this.getCalibrationData$) {
      this.getCalibrationData$.unsubscribe()
    }
    if (this.getPasData$) {
      this.getPasData$.unsubscribe()
    }
    if (this.getBehaviorCompetenceData$) {
      this.getBehaviorCompetenceData$.unsubscribe()
    }
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }
  }

}
