import { OnDestroy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from "../ngrx/app.reducers";
import * as fromPeopleAppraisal from "./ngrx/peopleAppraisal.reducers";
import { JwtPayload, PerfAlloyYearCodes } from 'src/commonclasses';
import * as PeopleAppraisalActions from "./ngrx/peopleAppraisal.actions";
import { Router } from '@angular/router';
import { RedirectService } from '../shared/services/redirect.service';
import moment = require('moment-timezone');
import { ModalService } from '../shared/components/modal/modal.service';

@Component({
  selector: 'app-peopleAppraisal',
  templateUrl: './peopleAppraisal.component.html',
  styleUrls: ['./peopleAppraisal.component.scss']
})
export class PeopleAppraisalComponent implements OnDestroy {
  applicationLang$: Subscription;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription;
  getIsLoadingSubordinates$: Subscription = new Subscription;
  isLoadingSubordinates: boolean = true;
  applicationLang: string = '';
  runningYear: any = {};
  subordinatesWithPhaseStatuses: any[] = [];
  activeUserId: string | null = null;
  combinedSelected$: Subscription;
  combinedSelectedYears$: Subscription;
  isClickedTeamButton$: Subscription;
  loggedUser: any;
  activeUserId$: Subscription;
  runningPhase: any | null = null;
  isLoadingCurrentPhase: boolean;
  isTeamButtonClicked: boolean = false;
  showImpersonateBanner: boolean = false;
  isOpenedSecondLevel: boolean = false;

  structureSubordinatesWithPhaseStatuses$: Subscription = new Subscription;
  structureSubordinatesWithPhaseStatuses: any[] = [];

  constructor(
    private appStore: Store<fromApp.AppState>,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
    private translate: TranslateService,
    public router: Router,
    private redirectService: RedirectService,
    private modalService: ModalService,
  ) {
    this.isLoadingCurrentPhase = true;
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
        moment.locale(applicationLang);
      }
    });

    this.appStore.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.showImpersonateBanner = showImpersonateBanner;
      })

    const runningYear$ = this.appStore.select(fromApp.getRunningYear);
    const runningPhase$ = this.appStore.select(fromApp.getRunningPhase);

    this.combinedSelectedYears$ = combineLatest([runningYear$, runningPhase$])
      .subscribe(([runningYear, runningPhase]) => {
        if (runningYear?.yearCode && runningPhase) {
          this.runningPhase = runningPhase;
          this.isLoadingCurrentPhase = false;
          this.runningYear = runningYear;

          if (moment().isBetween(moment(runningPhase.goalUpdateStartDate), moment(runningPhase.goalUpdateEndDate))) {
            // In caso ci sia la fase di goal update aperta, devo avanzare nel processo successivo (inesistente) per far credere all'utente di essere nel nuovo processo
            if (this.runningYear.yearCode == PerfAlloyYearCodes.H1) {
              this.runningYear = {
                year: this.runningYear.year,
                yearCode: PerfAlloyYearCodes.H2
              }
            } else {
              this.runningYear = {
                year: this.runningYear.year + 1,
                yearCode: PerfAlloyYearCodes.H1
              }
            }
          }

          // Recupero dai servizi la lista di sottoposti
          this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses());
        }
      })

    this.isClickedTeamButton$ = this.appStore.select(fromApp.getIsButtonTeamClicked).subscribe((isButtonClicked) => {
      this.isTeamButtonClicked = isButtonClicked;
    })

    // Recupero la lista di subordinati con lo stato delle fasi
    this.subordinatesWithPhaseStatuses$ = this.appStore.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
      let subordinatesPhase = subordinatesWithPhaseStatuses?.filter((x: any) => {
        return !x.structureUser;
      })

      let structureSubordinatesPhase = subordinatesWithPhaseStatuses?.filter((x: any) => {
        return x.structureUser;
      })

      this.subordinatesWithPhaseStatuses = subordinatesPhase || [];
      this.structureSubordinatesWithPhaseStatuses = structureSubordinatesPhase || [];
    });

    // Loader dei subordinati
    this.getIsLoadingSubordinates$ = this.appStore.select(fromApp.getIsLoadingSubordinates).subscribe((isLoadingSubordinates) => {
      this.isLoadingSubordinates = isLoadingSubordinates;
    });

    // Recupero l'id dell'utente attualmente selezionato
    this.activeUserId$ = this.appStore.select(fromApp.getActiveUserId).subscribe((activeUserId: string) => {
      this.activeUserId = activeUserId;
    })

    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$])
      .subscribe(
        ([loggedUser, userAck]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  // Se mostrare o no la sidebar degli utenti a sinistra
  showSidebar() {
    // && !this.isInGoalDetail() 
    return !this.redirectService.isThisCurrentPage('createUpdateGoal') && !this.redirectService.isThisCurrentPage('organizeInterview')
      && !this.redirectService.isThisCurrentPage('notifications') && !this.redirectService.isThisCurrentPage('goalDetails')
      && !this.redirectService.isThisCurrentPage('sendApplause')
      && !this.redirectService.isThisCurrentPage('createUpdateDevelopmentAction')
      && !this.redirectService.isThisCurrentPage('profile')
      && !this.redirectService.isThisCurrentPage('myTeam')
      && !this.redirectService.isThisCurrentPage('createUpdateEvidence')
      && !this.redirectService.isThisCurrentPage('createUpdatePrivateNote')
      && !this.redirectService.isThisCurrentPage('createUpdatePeerFeedback')
      && !this.redirectService.isThisCurrentPage('feedbackRequest')
      && !this.redirectService.isThisCurrentPage('midTermList')
      && !this.redirectService.isThisCurrentPage('addMidTermReview')
      && !this.redirectService.isThisCurrentPage('finalEvaluation')
      && !this.redirectService.isThisCurrentPage('definePerformanceLevel')
      && !this.redirectService.isThisCurrentPage('postCalibrationEvaluation')
      && !this.redirectService.isThisCurrentPage('support');
  }

  isInGoalDetail() {
    if (window.location.href.indexOf('personDetails') > -1 && window.location.href.indexOf('feedback') < 0 && window.location.href.indexOf('developmentPlan') < 0) {
      let currentUrl = window.location.href.split('personDetails')[1];
      if (currentUrl.split('/').length > 2) {
        return true;
      }
      return false;
    }
    return false;
  }

  // Porta al dettaglio di una persona dato il suo id
  onUserClicked(user: any) {
    if (user.structureUser && !(user.status.statusType == 'COMPLETED')) {
      this.openDisabledStructureUserModal();
    } else {
      this.isTeamButtonClicked = !this.isTeamButtonClicked;
      this.appStore.dispatch(PeopleAppraisalActions.SetHeaderTeamClickedButton({ payload: this.isTeamButtonClicked }));
      if (user && user.userId) {
        this.appStore.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: user.userId }));
        this.router.navigate(['peopleAppraisal/personDetails/', user.userId, this.runningYear.year, this.runningYear.yearCode]);
      }
    }
  }


  openDisabledStructureUserModal() {
    this.modalService.open("disabled-structure-user-modal");
  }

  closeDisabledStructureUserModal() {
    this.modalService.close("disabled-structure-user-modal");
  }

  secondLevelOpen(openLevelValue: boolean) {
    this.isOpenedSecondLevel = openLevelValue;
  }

  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
    if (this.isClickedTeamButton$) {
      this.isClickedTeamButton$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.getIsLoadingSubordinates$) {
      this.getIsLoadingSubordinates$.unsubscribe();
    }
    if (this.activeUserId$) {
      this.activeUserId$.unsubscribe();
    }
  }
}
