<textarea *ngIf="!readonly" [(ngModel)]="bindValue" [placeholder]="placeholder" attr.aria-label="{{ attrAriaLabel }}"
  (ngModelChange)="emitOnModelChanged($event)" #textareaEl="ngModel"></textarea>

<p *ngIf="readonly && !isInnerHTML">
  {{bindValue}}
</p>

<p [innerHTML]="bindValue" *ngIf="readonly && isInnerHTML">
</p>

<div *ngIf="canCancelEdit" class="full-width">
  <p class="cancel-edit-container">
    <span class="cancel-edit" translate="defineObjectives.CANCEL_EDIT" (click)="cancelEdit.emit()"></span>
    <span class="or-share" translate="defineObjectives.OR_SHARE"></span>
  </p>
</div>