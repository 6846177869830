<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [disabled]="true" [adminPageTitle]="('impersonate.TITLE' | translate)"
    [adminPageSubtitle]="('impersonate.SUBTITLE' | translate)" [adminBack]="true" [hasTabs]="false">
  </header-dossier>
  <div class="main-content-wrapper admin">
    <!-- contenitore tabella -->

    <div class="admin-table-container">
      <div class="structure-recap">
        <p class="structure-title" translate="impersonate.LIST"></p>

        <div class="search-container">
          <div class="search-wrapper">
            <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
              (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
              [placeholder]="'structureAnag.details.SEARCH_BY' | translate" [isSearchBar]="true">
            </alloy-input>
            <!-- Commentato in attesa che ci dicano che filtri possono essere utili -->
            <!--p class="filter-recap" translate="structureAnag.details.FILTER_BY"></p-->
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isLoadingUsersData">
        <ng-container *ngIf="usersData.counter > 0">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                  <!-- th translate="{{ ('adminTable.CID' | translate)}}"></th-->
                  <th class="small-col" translate="{{ ('adminTable.PROCESS_INVOLVED' | translate)}}">
                  </th>
                  <th translate="{{ ('adminTable.SUBCOMPANY' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of usersData.list | 
                            slice:0:usersData.numRecords | 
                            paginate: { id: 'usersList',
                                    itemsPerPage: usersData.numRecords,
                                    currentPage: usersData.page,
                                    totalItems: usersData.counter }">
                  <td>{{ user.surname + ' ' + user.forename }}</td>
                  <!--td>
                    <div class="count">
                      {{ user.cid }}
                    </div>
                  </td-->
                  <td class="small-col link" (click)="openUserProcessModal(user)">{{
                    user.numerOfProcess ||
                    0 }}</td>
                  <td>
                    {{ user.socDistacco || '-'}}
                  </td>
                  <td class="detail" (click)="impersonate(user.userId)">{{
                    ('adminTable.IMPERSONATE' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="usersList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!usersData.counter">
          <div class="no-data-wrapper">
            <p translate="adminTable.NO_DATA_USER"></p>
          </div>
        </ng-container>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsersData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>

  </div>
</div>



<modal id="userProcessInfo" (onClose)="closeUserProcessModal()" (onConfirm)="closeUserProcessModal()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <ng-container *ngIf="userProcessModalData">
    <p class="modal-title">
      {{ ('impersonate.modals.PROCESS' | translate) + ' ' + userProcessModalData.surname + ' '+
      userProcessModalData.forename}}</p>
    <div class="assessment-scrollable-wrapper">
      <div class="assessment-container" *ngFor="let data of userProcessModalData.userAssessments">
        <p class="year">{{ data.year + ' ' + data.yearCode }}</p>
        <!-- Titolo -->
        <div class="entry divider">
          <p class="title" translate="impersonate.modals.PROCESS_NAME"></p>
          <p class="value">{{ data.title }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</modal>