import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  stars: { id: number, isClicked: boolean }[] = [];
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  @Input() showFullNumber: boolean = false;
  @Input() fullNumber: any;
  @Input() rating: any;
  @Input() disabled: boolean = false;
  @Input() grayColor: boolean = false;
  @Input() hideLabel: boolean = false;
  @Input() customContainerClass: string = "";
  @Input() isWithoutInfoIcon?: boolean;
  @Input() forceRating: number = 0;
  @Input() forceLabel?: string;
  @Input() scale: any = 5; // 3 
  @Input() direction: string = 'column';
  @Input() forceDisabled!: boolean;
  @Output() onStarClicked: EventEmitter<number> = new EventEmitter();
  markLabel: string = '';
  originalMarkLabel: string = '';
  translations: any;


  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.translate.get([
      'starRating.0',
      'starRating.1',
      'starRating.2',
      'starRating.3',
      'starRating.4',
      'starRating.5'
    ]).subscribe((translations) => {
      this.translations = translations;
      this.stars = JSON.parse(JSON.stringify(this.starsModel[this.scale]));
      this.markLabel = this.translations['starRating.0'];
      if (this.forceRating > 0) {
        this.disabled = true;
        for (let i = 0; i < this.forceRating; i++) {
          this.stars[i].isClicked = true;
        }
        this.rating = JSON.parse(JSON.stringify(this.stars));
      }
      if (this.rating) {
        let mark;
        for (let i = 0; i < this.rating.length; i++) {
          if (this.rating[i].isClicked) {
            mark = i;
          }
        }
        if (mark || mark == 0) {
          this.markLabel = this.translations['starRating.' + (mark + 1)];
          this.originalMarkLabel = this.markLabel;
        } else {
          this.markLabel = this.translations['starRating.0'];
          this.originalMarkLabel = this.markLabel;
        }
        // problemi se non selezionato da stella
        if (this.forceRating == 1) {
          this.markLabel = this.translations['starRating.1'];
        }
      } else {
        this.rating = this.stars;
      }
      if (this.forceDisabled !== undefined)
        this.disabled = this.forceDisabled;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.forceRating && !changes.forceRating.firstChange && changes.forceRating.currentValue != changes.forceRating.previousValue) {
      this.ngOnInit();
    }
  }

  onStarMouseOver(id: number) {
    if (!this.disabled) {
      for (let i = 0; i <= id; i++) {
        this.rating[i].isHovered = true;
        this.markLabel = this.translations['starRating.' + (id + 1)];
      }
    }
  }

  onStarMouseOut() {
    if (!this.disabled) {
      for (let i = 0; i < this.rating.length; i++) {
        this.rating[i].isHovered = false;
      }
    }
    if (this.originalMarkLabel) {
      this.markLabel = this.originalMarkLabel;
    }
  }

  buttonClicked(id: number) {
    if (!this.disabled) {
      for (let i = 0; i < this.rating.length; i++) {
        this.rating[i].isClicked = false;
        this.rating[i].isHovered = false;
      }
      for (let i = 0; i <= id; i++) {
        this.rating[i].isClicked = true;
        this.originalMarkLabel = this.markLabel;
      }
    }
    this.onStarClicked.emit(id);
  }

  isRated(currentObject: any) {
    if (currentObject.stars && currentObject.stars[0] && currentObject.stars[0].isClicked) {
      return true;
    }
    return false;
  }

  getWrapperClass() {
    let classList = '';
    if (this.customContainerClass) {
      classList = classList + this.customContainerClass;
    }
    if (this.scale == 3) {
      classList += " min-width200 ";
    }

    if (this.direction == 'row') {
      classList += " row";
    }

    return classList;
  }
}
