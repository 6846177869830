import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import * as fromApp from "../../ngrx/app.reducers";
import { Store } from '@ngrx/store';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse, User } from 'src/commonclasses';
import { Subscription } from 'rxjs';
import { UserCard } from 'src/app/utils/classes.utils';
import * as CoreActions from "../../core/ngrx/core.actions";
import { UserDataUtils } from 'src/app/utils/user-data.utils';

@Component({
  selector: 'app-feedback-to-manager',
  templateUrl: './feedback-to-manager.component.html',
  styleUrls: ['./feedback-to-manager.component.scss']
})
export class FeedbackToManagerComponent implements OnInit {

  runningYear$: Subscription = new Subscription();
  runningYear: any;

  // 
  getManagersBySubordinateId$: Subscription = new Subscription();
  isLoadingManagersBySubordinateId: boolean = false;

  createFeedbackToManagerForUser$: Subscription = new Subscription();
  isLoadingCreateFeedbackToManagerForUser: boolean = false;

  // Manager
  getMangersList$: Subscription = new Subscription();
  userCardData: {
    counter: number,
    fromRecord: number,
    numRecords: number,
    list: UserCard[],
    page: number
  } = {
      counter: 0,
      fromRecord: 0,
      numRecords: 10,
      list: [],
      page: 1
    };
  isFetchingManager: boolean = false;

  managerDetails!: UserCard;


  isLoadingFeedback: boolean = false;
  isLoadingGoalsToAssociate: boolean = false;
  isUserSelected: boolean = false;

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, isUserSelected?: boolean }> = [];

  feedbackId: string = '';

  searchUserText: string = '';
  selectedUserList: any[] = [];
  selectedUser: UserCard | undefined;

  selectedObjective: any;
  goalOptions: Array<{ id: string, title: string }> = [];

  searchTeamUserText: string = '';


  listTeamUsers$: Subscription = new Subscription;

  // Modali 
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  requestMessageModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  requestMessage: string = '';


  objectiveOptions: Array<{ id: string, title: string }> = [];

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              // this.userId = params.userId;
              this.feedbackId = params.feedbackId;
              this.getUsersList();
              // this.getGoalsToAssociate();
            }
          );
      }
    });
  }



  ngOnInit(): void {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'peerFeedback.createUpdate.OBJECTIVE_TITLE',
        'peerFeedback.modals.OBJECTIVE_TITLE_INFO',
        'peerFeedback.createUpdate.REQUEST_MESSAGE',
        'peerFeedback.modals.REQUEST_MESSAGE_INFO',
        'peerFeedback.createUpdate.WHO',
        'peerFeedback.modals.WHO_INFO',
        'peerFeedback.modals.TO_WHOM',
        'peerFeedback.modals.NO_GOALS',
        'peerFeedback.modals.REFERS_TO',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_TITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_SUBTITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_DESC',
        'feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_TITLE',
        'feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_SUBTITLE',
        'feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_DESC'

      ])
      .subscribe(translations => {
        this.objectiveTitleModal = {
          modalId: "pf001",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.OBJECTIVE_TITLE'],
          text: translations['peerFeedback.modals.OBJECTIVE_TITLE_INFO']
        };
        this.requestMessageModal = {
          modalId: "pf002",
          title: translations['feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_TITLE'],
          subtitle: translations['feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_SUBTITLE'],
          text: translations['feedbackToMAnager.modals.INFO_FEEDBACK_TO_MANAGER_6_DESC']
        };
        this.whoTooltipModal = {
          modalId: "pf002",
          title: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_5_DESC']
        };
      })
  }

  // Gestisce quando crei/aggiorni un'evidenza0
  sendFeedbackRequest() {
    // createFeedbackToManagerForUser$

    // Avvio il loader
    this.isLoadingCreateFeedbackToManagerForUser = true;

    let recipientUserId = '';
    if (this.selectedUser) {
      recipientUserId = this.selectedUser.id
    }
    // Inizio a fare la chiamata per il counte degli utenti
    this.createFeedbackToManagerForUser$ = this.selfAssessmentService.createFeedbackToManagerForUser(this.runningYear.year.toString(), this.runningYear.yearCode.toString(), this.requestMessage, recipientUserId)
      .subscribe((data: SenecaResponse<User>) => {
        if (data && data.response) {
          this.redirectService.goToHome();
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "gu01",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingCreateFeedbackToManagerForUser = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "gu02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateFeedbackToManagerForUser = false;
      });
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  changeSearchedTeamTextValue(name: string) {
    this.searchTeamUserText = name;
  }


  searchUsers() {
    this.getUsersList();
  }

  // Recupero la lista degli utenti
  getUsersList() {
    // Avvio il loader
    this.isFetchingManager = true;

    // Inizio a fare la chiamata per il counte degli utenti
    this.getMangersList$ = this.selfAssessmentService.countUserForPeerFeedback(this.runningYear.year.toString(), this.searchUserText)
      .subscribe((counter: SenecaResponse<number>) => {
        if (counter && counter.response) {
          this.userCardData.counter = counter.response;
          // Calcolo la paginazione
          this.userCardData.fromRecord = (this.userCardData.page - 1) * this.userCardData.numRecords;
          //Inizio la chimata per recuperare gli utenti 
          this.selfAssessmentService.listUserForPeerFeedback(this.userCardData.fromRecord, this.userCardData.numRecords, this.searchUserText)
            .subscribe((data: SenecaResponse<Array<User>>) => {
              if (data && data.response) {
                this.userCardData.list = UserDataUtils.getUserCardData(data.response)
                this.isFetchingManager = false;

              } else {
                // Vedo se c'è la traduzione dell'errore
                const messageObj: ApplicationModalMessage = {
                  modalId: "gu11",
                  text: this.translate.instant("errors." + counter.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isFetchingManager = false;
              }
            }, (err?: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "gu12",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isFetchingManager = false;
            });
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "gu01",
            text: this.translate.instant("errors." + counter.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingManager = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "gu02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingManager = false;
      });
  }

  getManagersBySubordinateId() {
    // Avvio il loader
    this.isLoadingManagersBySubordinateId = true;

    let subordinateId = '';
    if (this.selectedUser) {
      subordinateId = this.selectedUser.id
    }
    // Inizio a fare la chiamata per il counte degli utenti
    this.getManagersBySubordinateId$ = this.selfAssessmentService.getManagersBySubordinateId(this.runningYear.year.toString(), this.runningYear.yearCode.toString(), subordinateId)
      .subscribe((data: SenecaResponse<User>) => {
        if (data && data.response) {
          this.managerDetails = UserDataUtils.formatUserCardData(data.response);
          this.isLoadingManagersBySubordinateId = false;
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "gu01",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingManagersBySubordinateId = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "gu02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagersBySubordinateId = false;
      });
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  usersPageChanged(newPage: any) {
    this.userCardData.page = newPage;
    // Avvio una nuova ricerca
    this.getUsersList();
  }

  changeAssociatedObjective(objective: any) {
    this.selectedObjective = objective;
  }

  requestMessageChanged(text: string) {
    this.requestMessage = text;
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    // if (!this.isResponse) {
    //   if (this.selectedUserList && this.selectedUserList.length && this.requestMessage && this.requestMessage.trim().length) {
    //     return true;
    //   }
    // } else {
    //   if (this.feedbackObject.comment) {
    //     return true;
    //   }
    // }
    // return false;
    return false;
  }

  // selectUser(user: any) {
  //   let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
  //   if (isPresent && isPresent.length) {
  //     this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
  //   } else {
  //     this.selectedUserList.push(user);
  //   }
  // }

  // Aggiorno le card con l'utente selezionato
  selectUser(user: any) {
    for (let i = 0; i < this.userCardData.list.length; i++) {
      if (this.userCardData.list[i].id !== user.id) {
        this.userCardData.list[i].isChecked = false;
      }
    }
    this.userCardData.list = JSON.parse(JSON.stringify(this.userCardData.list))
    // Imposto utente selezionato 
    this.selectedUser = this.userCardData.list.find(user => user.isChecked == true);
  }

  usersTeamPageChanged(newPage: any) {
    this.userCardData.page = newPage;
    this.userCardData.list = [];
    this.getUsersList()
  }

  clickedRequestFeedback(isFirstStep?: boolean) {
    if (isFirstStep) {
      this.isUserSelected = true;
      this.getManagersBySubordinateId()
      // this.getListMyColleagueUsers();
      // this.getGoalsToAssociateForManager();
    }
  }

  isDisabled() {
    if (this.selectedUser?.id) {
      return true;
    }
    return false;
  }

  isDisabledSecondStep() {
    if (this.selectedUser && this.requestMessage) {
      return true;
    }
    return false;
  }

  openConfirmDeleteUser() {
    this.modalService.open('confirm-remove-user')
  }

  // Vai indietro back browser
  goBackBrowser() {
    if (this.isUserSelected) {
      this.isUserSelected = false;
    } else {
      this.redirectService.goBackBrowser();
    }
  }

  searchTeamUsers() {
    this.getUsersList();
  }

  closeConfirmDeleteUser(confirm?: boolean) {
    if (confirm) {
      this.isUserSelected = false;
      this.selectedUser = undefined;
      this.searchUserText = '';
      this.userCardData.list = []
      this.getUsersList();
    }
    this.modalService.close('confirm-remove-user')
  }

  openModalCreateUpdatePeerFeedback() {
    this.feedbackRecap = [
      {
        title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
        avatar: true
      },
      {
        title: this.translate.instant('feedbackToMAnager.modals.REGARDING'),
        avatar: true,
        isUserSelected: true
      },
      {
        title: this.translate.instant('feedbackToMAnager.modals.OSSERVATION'),
        text: this.requestMessage
      }];
    this.modalService.open('modalCreateUpdatePeerFeedback')
  }
  closeModalCreateUpdatePeerFeedback(confirm?: boolean) {
    this.modalService.close('modalCreateUpdatePeerFeedback')
    if (confirm) {
      this.sendFeedbackRequest();
    }
  }
}