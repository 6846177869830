<div class="page-content-wrapper">

  <header-dossier [user]="loggedUser" [disabled]="true" [isAdminHeader]="true" [adminBack]="true"
    [adminPageTitle]="'dashboard.TITLE' | translate" [hasTabs]="false"
    [adminPageSubtitle]="processYear ? (('monitoring.PERFORMANCE' | translate) + processYear) : ('dashboard.WHAT_PROCESS' | translate)"
    [adminActionLabel]="processYear ? ('dashboard.DOWNLOAD_REPORT' | translate) : ''" (onAdminAction)="downloadReport()"
    adminActionLabelType="success" [showLoader]="isDownloadingReport" [adminActionDisabled]="isDownloadingReport">
  </header-dossier>

  <!-- Home processi -->
  <ng-container *ngIf="!processYear">
    <div class="main-content-wrapper admin">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.SEMESTER' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                              slice:0:processData.numRecords | 
                              paginate: { id: 'processList',
                                          itemsPerPage: processData.numRecords,
                                          currentPage: processData.page,
                                          totalItems: processData.counter }">
                  <td>{{ process.processYearCode + ' ' + process.processYear }}</td>
                  <td>{{ process.name }}</td>
                  <td>
                    {{ process.totalParticipants || 0 }}
                  </td>
                  <td>
                    <item-list-team-status type="inProgress"></item-list-team-status>
                  </td>
                  <td class="detail" (click)="goToProcessDetails(process)">
                    {{ 'adminTable.ACCESS' | translate }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <ng-container *ngIf="processYear">
    <div class="main-content-wrapper">

      <!-- Filtri -->
      <div class="filters-recap">
        <!-- Contenuto filtri -->
        <div class="filter-content">
          <div class="title-container">
            <div class="info-title">
              <p class="title">
                {{ ('dashboard.TITLE') | translate }}
              </p>
              <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                (click)="openDashboardTooltip()">
              </svg-icon>
            </div>
          </div>


          <!-- Filtri -->
          <anag-filters [searchEnabled]="false" (updateSelectedFilters)="updateSelectedFilters($event)"
            [keepFilters]="true" (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)"
            (startSearch)="getGraphData($event)" [showSelfAssessmentStatus]="false" [showAppraisalStatus]="false"
            [showCalibrationStatus]="false"
            [showManager]="true">
          </anag-filters>

        </div>
      </div>

      <div class="accordion-list">
        <!-- Osservazioni finali -->
        <div class="final-overview-container">
          <ng-container *ngIf="!isLoadingOverviewData">
            <dx-chart id="finalChart" [dataSource]="productionData" [palette]="finalPalette"
              (onInitialized)="saveInstance($event, 'finalChart')">

              <dxo-title [text]="'dashboard.accordion.FINAL_OVERVIEW' | translate">
                <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                </dxo-font>
                <dxo-subtitle [text]="'dashboard.FINAL_OVERVIEW_DESCR' | translate">
                  <dxo-font color="#767676" weight="300" size="12px">
                  </dxo-font>
                </dxo-subtitle>
              </dxo-title>

              <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
              <dxo-common-series-settings argumentField="status" type="bar" valueField="value">
              </dxo-common-series-settings>
              <dxo-series-template nameField="structure" [customizeSeries]="customizeSeries">
              </dxo-series-template>
              <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
              </dxo-legend>
              <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20"
                [valueMarginsEnabled]="false">
                <dxo-label [customizeText]="customizePercentageText"></dxo-label>
              </dxi-value-axis>
              <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
            </dx-chart>
          </ng-container>
          <ng-container *ngIf="isLoadingOverviewData">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </div>

        <!-- Self assessment -->
        <percentage-accordion [title]="'dashboard.accordion.SELF_ASSESSMENT' | translate"
          [percentage]="selfAssessmentPercentage" [isAccordionOpened]="selfAssessmentOpen"
          [isLoading]="isLoadingSelfAssessmentData" (onAccordionOpen)="redrawGraphs('self-assessment')">
          <div class="accordion-content">

            <ng-container *ngIf="!isLoadingSelfAssessmentData">

              <div class="dashboard" *ngIf="selfAssessmentProgress">
                <!-- Se ho dati -->
                <ng-container *ngIf="!selfAssessmentNoData; else selfPlaceholder">
                  <!-- Indicatore self -->
                  <div class="section">
                    <dx-circular-gauge id="selfAssessmentGauge" [value]="selfAssessmentProgress.completedPercentage"
                      (onInitialized)="saveInstance($event, 'selfAssessmentGauge')">
                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.SELF_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#58BC5D"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>


                    <div class="more-graph-info">
                      <p class="number">{{ selfAssessmentProgress.sent + '/' + selfAssessmentProgress.total}}</p>
                      <p>{{('dashboard.SENT_SCHEDULE' | translate)}}</p>
                    </div>
                  </div>

                  <!-- Chart self -->
                  <div class="section right">
                    <dx-chart id="selfAssessmentChart" [dataSource]="selfAssessmentProgress.chartData"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'selfAssessmentChart')"
                      [rotated]="true">

                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.SELF_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="true" position="outside" horizontalAlignment="center"
                        verticalAlignment="bottom">
                      </dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info"></div>
                  </div>
                </ng-container>

                <!-- Placeholder se non ho dati -->
                <ng-template #selfPlaceholder>
                  <div class="section">
                    <dx-circular-gauge id="selfAssessmentGauge" [value]="selfAssessmentProgress.completedPercentage"
                      (onInitialized)="saveInstance($event, 'selfAssessmentGauge')">
                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.SELF_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#fff">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#dbdbdb"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#dbdbdb"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>


                    <div class="more-graph-info">
                      <p class="number">0/0</p>
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>
                  <div class="section right">

                    <dx-chart id="selfAssessmentChart" [dataSource]="selfAssessmentProgress.chartData"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'selfAssessmentChart')"
                      [rotated]="true">

                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.SELF_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="false">
                      </dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                        <dxo-grid [visible]="true"></dxo-grid>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info">
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>
                </ng-template>

              </div>

            </ng-container>

            <ng-container *ngIf="isLoadingSelfAssessmentData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>

        <!-- People appraisal -->
        <!-- [isDisabled]="!isMidTermActive" -->
        <percentage-accordion [title]="'dashboard.accordion.EVALUATION' | translate"
          [percentage]="peopleAppraisalPercentage" [isAccordionOpened]="peopleAppraisalOpen"
          [isLoading]="isLoadingPeopleAppraisalData" (onAccordionOpen)="redrawGraphs('people-appraisal')">
          <div class="accordion-content">

            <ng-container *ngIf="!isLoadingPeopleAppraisalData && peopleAppraisalData">
              <div class="dashboard" *ngIf="peopleAppraisalData">

                <!-- se ho dati -->
                <ng-container *ngIf="!peopleAppraisalNoData; else peoplePlaceholder">
                  <!-- Indicatore people appraisal -->
                  <div class="section">
                    <dx-circular-gauge id="peopleAppraisalGauge" [value]="peopleAppraisalData.completedPercentage"
                      (onInitialized)="saveInstance($event, 'peopleAppraisalGauge')">

                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.PEOPLE_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#58BC5D"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>


                    <div class="more-graph-info">
                      <p class="number">{{ peopleAppraisalData.sent + '/' + peopleAppraisalData.total}}</p>
                      <p>{{('dashboard.SENT_SCHEDULE' | translate)}}</p>
                    </div>
                  </div>

                  <!-- Chart people appraisal -->
                  <div class="section right">
                    <dx-chart id="peopleAppraisalChart" [dataSource]="peopleAppraisalData.chartData" [rotated]="true"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'peopleAppraisalChart')">
                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.PEOPLE_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>
                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="true" position="outside" horizontalAlignment="center"
                        verticalAlignment="bottom"></dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info"></div>
                  </div>
                </ng-container>


                <!-- se non ho dati -->
                <ng-template #peoplePlaceholder>
                  <div class="section">
                    <dx-circular-gauge id="peopleAppraisalGauge" [value]="selfAssessmentProgress.completedPercentage"
                      (onInitialized)="saveInstance($event, 'peopleAppraisalGauge')">

                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.PEOPLE_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#ffffff">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#dbdbdb"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#dbdbdb"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>


                    <div class="more-graph-info">
                      <p class="number">0/0</p>
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>

                  <div class="section right">
                    <dx-chart id="peopleAppraisalChart" [dataSource]="peopleAppraisalData.chartData" [rotated]="true"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'peopleAppraisalChart')">
                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.PEOPLE_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>
                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="false"></dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-grid [visible]="true"></dxo-grid>
                        <dxo-label [visible]="false"></dxo-label>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info">
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>
                </ng-template>
              </div>

            </ng-container>

            <ng-container *ngIf="isLoadingPeopleAppraisalData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>


        <!-- Calibration -->
        <percentage-accordion [title]="'dashboard.accordion.CALIBRATION' | translate"
          [percentage]="calibrationPercentage" [isAccordionOpened]="calibrationOpen"
          [isLoading]="isLoadingCalibrationData" (onAccordionOpen)="redrawGraphs('calibration')">
          <div class="accordion-content">

            <ng-container *ngIf="!isLoadingCalibrationData && calibrationData">
              <div class="dashboard" *ngIf="calibrationData">
                <!-- se ho dati -->
                <ng-container *ngIf="!calibrationNoData; else calibrationPlaceholder">
                  <!-- Indicatore calibration -->
                  <div class="section">
                    <dx-circular-gauge id="calibrationGauge" [value]="calibrationData.completedPercentage"
                      (onInitialized)="saveInstance($event, 'calibrationGauge')">

                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.HR_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#58BC5D"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>


                    <div class="more-graph-info">
                      <p class="number">{{ calibrationData.sent + '/' + calibrationData.total}}</p>
                      <p>{{('dashboard.SENT_SCHEDULE' | translate)}}</p>
                    </div>
                  </div>

                  <!-- Chart calibration -->
                  <div class="section right">
                    <dx-chart id="calibrationChart" [dataSource]="calibrationData.chartData" [rotated]="true"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'calibrationChart')">

                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.HR_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="true" position="outside" horizontalAlignment="center"
                        verticalAlignment="bottom">
                      </dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-label [visible]="false"></dxo-label>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info"></div>
                  </div>
                </ng-container>

                <ng-template #calibrationPlaceholder>
                  <!-- Indicatore calibration -->
                  <div class="section">
                    <dx-circular-gauge id="calibrationGauge" [value]="calibrationData.completedPercentage"
                      (onInitialized)="saveInstance($event, 'calibrationGauge')">

                      <dxo-title [text]="'dashboard.PROGRESS' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.HR_PROG_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-value-indicator type="triangleNeedle" color="#fff">
                      </dxo-value-indicator>
                      <dxo-range-container palette="pastel">
                        <dxi-range [startValue]="0" [endValue]="50" color="#dbdbdb"></dxi-range>
                        <dxi-range [startValue]="50" [endValue]="100" color="#dbdbdb"></dxi-range>
                      </dxo-range-container>
                      <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                      <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                        <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                      </dxo-scale>
                    </dx-circular-gauge>

                    <div class="more-graph-info">
                      <p class="number">0/0</p>
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>

                  <!-- Chart calibration -->
                  <div class="section right">
                    <dx-chart id="calibrationChart" [dataSource]="calibrationData.chartData" [rotated]="true"
                      [palette]="careColors" (onInitialized)="saveInstance($event, 'calibrationChart')">

                      <dxo-title [text]="'dashboard.NUMERIC' | translate">
                        <dxo-font color="#333333" family="Open Sans" weight="600" size="20px">
                        </dxo-font>
                        <dxo-subtitle [text]="'dashboard.HR_NUM_DESCR' | translate">
                          <dxo-font color="#767676" weight="300" size="12px">
                          </dxo-font>
                        </dxo-subtitle>
                      </dxo-title>

                      <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                      <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                        [ignoreEmptyPoints]="true" [barPadding]="0.5">
                      </dxo-common-series-settings>
                      <dxo-series-template nameField="attribute"></dxo-series-template>
                      <dxo-legend [visible]="false"></dxo-legend>
                      <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                      <dxo-argument-axis>
                        <dxo-grid [visible]="true"></dxo-grid>
                        <dxo-label [visible]="false"></dxo-label>
                      </dxo-argument-axis>
                    </dx-chart>

                    <div class="more-graph-info">
                      <p>{{('generic.NO_RESULTS' | translate)}}</p>
                    </div>
                  </div>



                </ng-template>
              </div>

            </ng-container>

            <ng-container *ngIf="isLoadingCalibrationData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>

        <!-- Accordion pas -->
        <percentage-accordion [title]="'dashboard.accordion.RETURN_OF_BALLOTS' | translate" [percentage]="pasPercentage"
          [isAccordionOpened]="pasOpen" [isLoading]="isLoadingPasData" (onAccordionOpen)="redrawGraphs('pas')">
          <div class="accordion-content overflow">

            <ng-container *ngIf="!isLoadingPasData && pasData">
              <div class="clusters-wrapper">
                <div class="cluster-wrapper" *ngFor="let gauge of pasData; let i = index">
                  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                    <div class="circularProgress" fxLayoutAlign="center center" [ngClass]="gauge.class">
                      <div tabindex="0" class="circularProgress-overlay">{{gauge.value || 0}}</div>
                    </div>
                    <div fxLayout="column" class="full-width" fxLayoutAlign="center start">
                      <p tabindex="0" class="cluster-indicator-label full-width">
                        {{gauge.title}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoadingPasData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>

        </percentage-accordion>

        <!-- Comportamenti organizzativi -->
        <percentage-accordion [hidePercentage]="true" [title]="'dashboard.accordion.BEHAVIOR_COMPETENCE' | translate"
          [isAccordionOpened]="behaviorCompetenceOpen" [isLoading]="isLoadingBehaviorCompetenceData"
          (onAccordionOpen)="redrawGraphs('behavior-competence')">
          <div class="accordion-content">

            <ng-container *ngIf="!isLoadingBehaviorCompetenceData && behaviorCompetenceData">
              <div class="list-container">
                <p class="title-row" translate="dashboard.BEHAVIORS"></p>
                <ng-container
                  *ngIf="behaviorCompetenceData.behaviors && behaviorCompetenceData.behaviors.length; else behaviorPlaceholder">
                  <ng-container *ngFor="let behavior of behaviorCompetenceData.behaviors; let i = index;">
                    <div class="list-row">
                      <p class="index">{{ i + 1 }}.</p>
                      <p class="title">{{ behavior.title }}</p>
                      <star-rating customContainerClass="small-star-size min-width-auto" [forceRating]="behavior.rating"
                        [disabled]="true" [hideLabel]="true">
                      </star-rating>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #behaviorPlaceholder>
                  <div class="list-row">
                    <p class="title" translate="generic.NO_RESULTS"></p>
                  </div>
                </ng-template>
              </div>

              <div class="list-container competences">
                <p class="title-row" translate="dashboard.COMPETENCES"></p>
                <ng-container
                  *ngIf="behaviorCompetenceData.competences && behaviorCompetenceData.competences.length; else competencePlaceholder">
                  <ng-container *ngFor="let competence of behaviorCompetenceData.competences; let i = index;">
                    <div class="list-row">
                      <p class="index">{{ i + 1 }}.</p>
                      <p class="title">{{ competence.title }}</p>
                      <star-rating customContainerClass="small-star-size min-width-auto"
                        [forceRating]="competence.rating" [disabled]="true" [hideLabel]="true">
                      </star-rating>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #competencePlaceholder>
                  <div class="list-row">
                    <p class="title" translate="generic.NO_RESULTS"></p>
                  </div>
                </ng-template>
              </div>
            </ng-container>


            <ng-container *ngIf="isLoadingBehaviorCompetenceData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>
      </div>
    </div>
  </ng-container>
</div>

<modal id="dashboard-tooltip" (onClose)="closeDashboardTooltip()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.DASH_TOOL_TITLE' | translate"
    [text]="'dashboard.modals.DASH_TOOL_TEXT' | translate"></modal-text-content>
</modal>