import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as CoreActions from "../../core/ngrx/core.actions";
import { UrlService } from 'src/app/shared/services/url.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'admin-monitoring-user-list',
  templateUrl: './monitoring-user-list.component.html',
  styleUrls: ['./monitoring-user-list.component.scss']
})
export class MonitoringUserListComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];

  processYear: number = 0;
  processYearCode: string = '';

  usersFromRecord: number = 0;
  usersNumRecords: number = 5;
  usersCurrentPage: number = 0;
  usersCounter: number = 0;
  isLoadingUsers: boolean = false;
  selectedUserList: any[] = []
  searchedText: string = '';

  getCountersData$: Subscription = new Subscription();
  isLoadingCounters: boolean = false;
  counterData: any;

  usersList: any[] = [];
  translations: any;
  massiveModalText: any;
  massiveModalData: {
    user: any;
    observation: string;
    peopleAppraisalObservation: string;
    level: string;
    payout: number | null;
  } = {
      user: null,
      observation: '',
      peopleAppraisalObservation: '',
      level: '',
      payout: null
    };
  allChecked: boolean = false;
  selectedFilters: any = {};

  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  isLoadingTranslations: boolean = false;
  runningPhase: any;
  selectedFiltersNumber: number = 0;
  impersonateUserForAdmin$: any;
  loggedUser: any;
  reopenModal: any = {};

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private modalService: ModalService,
    private urlService: UrlService,
    private redirectService: RedirectService,
    private analyticsService: AnalyticsService,
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Lista degli utenti");

    this.selectedRows = this.rowPerPageOptions[2];
    this.usersNumRecords = this.selectedRows.id;

    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    const runningYear$: Observable<any> = this.store.select(fromApp.getRunningYear);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([getRunningPhase$, runningYear$, loggedUser$])
      .subscribe(
        ([runningPhase, runningYear, loggedUser]) => {
          this.runningPhase = runningPhase;
          this.runningYear = runningYear;
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
          this.route.params
            .subscribe(
              (params: Params) => {
                this.processYear = params.processYear;
                this.processYearCode = params.processYearCode;
                this.getCountersData();
              })
        });
  }

  ngOnInit() {
    sessionStorage.setItem('peopleAppraisal', '');
    sessionStorage.setItem('userIdList', '');
    this.isLoadingTranslations = true;

    this.translate.get([
      'monitoring.SHOW_USER_SCHEDULE',
      'monitoring.COMPARE_TEAM',
      'monitoring.VERIFY_SEND',
      'monitoring.REOPEN_SELF_ASSESSMENT',
      'monitoring.REOPEN_PEOPLE_APPRAISAL',
      'verifySend.modals.SEND_DESCR_MANY1',
      'verifySend.modals.SEND_DESCR_MANY2',
      'monitoring.modals.SELF_TITLE',
      'monitoring.modals.SELF_SUB',
      'monitoring.modals.SELF_DESCR_1',
      'monitoring.modals.SELF_DESCR_2',
      'monitoring.modals.PEOPLE_TITLE',
      'monitoring.modals.PEOPLE_SUB',
      'monitoring.modals.PEOPLE_DESCR_1',
      'monitoring.modals.PEOPLE_DESCR_2',
    ]).subscribe((translations) => {
      this.translations = translations;

      this.menuOptions = [
        {
          id: 'verify',
          title: translations['monitoring.VERIFY_SEND'],
          icon: '/assets/img/icons/send.svg',
        },
        {
          id: 'compare',
          title: translations['monitoring.COMPARE_TEAM'],
          icon: '/assets/img/icons/users.svg',
        },
        {
          id: 'view',
          title: translations['monitoring.SHOW_USER_SCHEDULE'],
          icon: '/assets/img/icons/clipboard-list.svg',
        },
        {
          id: 'self',
          title: translations['monitoring.REOPEN_SELF_ASSESSMENT'],
          icon: '/assets/img/icons/wand.svg',
        },
        {
          id: 'people',
          title: translations['monitoring.REOPEN_PEOPLE_APPRAISAL'],
          icon: '/assets/img/icons/wand.svg',
        }
      ]

      this.isLoadingTranslations = false;
    })
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  // menu utente
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], user)
    }
    user.isMenuOpen = !user.isMenuOpen
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any, isConfirm?: boolean) {
    user.isMenuOpen = false;
    if (!options.disabled) {
      if (options.id == 'view') {
        this.impersonate(user.userId)
      } else if (options.id == 'compare') {
        this.goToMatrix(true, user.userId);
      } else if (options.id == 'verify') {
        this.router.navigate(['admin/verifySendSchedule/', this.processYear, this.processYearCode, user.userId]);
      } else if (options.id == 'self') {
        this.reopenModal.title = this.translations['monitoring.modals.SELF_TITLE'];
        this.reopenModal.subtitle = this.translations['monitoring.modals.SELF_SUB'];
        this.reopenModal.text = this.translations['monitoring.modals.SELF_DESCR_1'] + user.surname + ' ' + user.forename + this.translations['monitoring.modals.SELF_DESCR_2'];
        this.reopenModal.userId = user.userId;
        this.reopenModal.isSelf = true;
        setTimeout(() => { this.modalService.open('reopen-schedule'); }, 200)
      } else if (options.id == 'people') {
        this.reopenModal.title = this.translations['monitoring.modals.PEOPLE_TITLE'];
        this.reopenModal.subtitle = this.translations['monitoring.modals.PEOPLE_SUB'];
        this.reopenModal.text = this.translations['monitoring.modals.PEOPLE_DESCR_1'] + user.surname + ' ' + user.forename + this.translations['monitoring.modals.PEOPLE_DESCR_2'];
        this.reopenModal.isSelf = false;
        this.reopenModal.userId = user.userId;
        setTimeout(() => { this.modalService.open('reopen-schedule'); }, 200)
      }
    }
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    if (this.selectedUserList && this.selectedUserList.length) {
      this.selectedUserList.length = 0;
    }
    if (this.usersList && this.usersList.length > 0) {
      for (let i = 0; i < this.usersList.length; i++) {
        this.usersList[i].isChecked = this.allChecked;
        if (this.allChecked) {
          this.addRemoveUser(this.usersList[i]);
        }
      }
    }
  }

  // ricerca
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.resetUserData();
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.usersList = [];
  }

  // Recupera una lista di utenti
  getUsersList(fromSearch: any) {
    if (fromSearch) {
      this.resetUserData();
    }
    sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    // Avvio il loader
    this.isLoadingUsers = true;
    this.adminService.countProcessUsers(this.processYear, this.processYearCode, this.searchedText, this.selectedFilters)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.usersCounter) {
                return this.adminService.listProcessUsers(this.processYear, this.processYearCode, this.searchedText, fromRecord, this.usersNumRecords, undefined, this.selectedFilters);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let user = data.response[i];
              let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);
              if (selected) {
                user.isChecked = true;
              }
            }
            this.usersList = data.response;
          }
          this.isLoadingUsers = false;
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista 
  usersPageChanged(newPage: any) {
    this.usersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsersList(false);
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
    //this.selectedUserList = [];
    //for (let i = 0; i < this.usersList.length; i++) {
    //  if (this.usersList[i].isChecked) {
    //    this.selectedUserList.push(this.usersList[i]);
    //  }
    //}
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }

  }


  // Conversione stato valutazione finale utente
  getFinalEvaluationStatus(status: string) {
    switch (status) {
      case 'TO_COMPLETE':
        return {
          label: this.translations['calibration.statuses.TO_COMPLETE'],
          status: 'toStart'
        };
      case 'COMPLETED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  // Conversione stato calibration utente
  getCalibrationStatus(status: string) {
    switch (status) {
      case 'CALIBRATION_TODO':
        return {
          label: this.translations['calibration.statuses.TO_START'],
          status: 'toStart'
        };
      case 'CALIBRATION_TO_CONFIRM':
        return {
          label: this.translations['calibration.statuses.TO_CONFIRM'],
          status: 'inProgress'
        };
      case 'CALIBRATION_CONFIRMED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  // ritorna i dati dei contatori relativi agli stati degli utenti nel processo
  getCountersData() {
    this.isLoadingCounters = true;

    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }

    this.getCountersData$ = this.adminService.processUserCounter(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<number>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.counterData = data.response;
        }
        this.isLoadingCounters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCounters = false;
      });
  }

  // Aggiorna il testo dell'osservazione quando confermo il PL di un singolo utente
  massiveModalObservationChanged(text: string) {
    this.massiveModalData.observation = text;
  }

  // redirect alla matrice, se da peopleAppraisal poi recupero i dati nella matrice
  // altrimenti metot nello store la lista degli utenti selezionati
  goToMatrix(fromPeopleAppraisal?: boolean, peopleAppraisalUserId?: string) {
    if (!fromPeopleAppraisal) {
      let userIds = this.selectedUserList.map((user: any) => user.userId);
      sessionStorage.setItem('userIdList', JSON.stringify(userIds));
    } else {
      let userIds = this.selectedUserList.map((user: any) => user.userId);
      userIds.push(peopleAppraisalUserId);
      sessionStorage.setItem('peopleAppraisal', JSON.stringify(userIds));
    }
    this.router.navigate(['admin/monitoringMatrix/', this.processYear, this.processYearCode]);
  }


  // modale inclusione massiva, se ho un solo utente si adatta
  openMassiveSend(user?: any) {
    if (user && !user.isChecked) {
      this.selectUser(user);
    }
    if (this.selectedUserList && this.selectedUserList.length > 1) {
      this.massiveModalText = this.translations['verifySend.modals.SEND_DESCR_MANY1'] + this.selectedUserList.length + this.translations['verifySend.modals.SEND_DESCR_MANY2'];
    }
    this.modalService.open('massive-send');
  }

  // chiude la modale di conferma e chiama i servizi
  closeMassiveSend(confirm?: boolean) {
    this.modalService.close('massive-send');
    if (confirm) {
      this.sendSchedules();
    }
  }

  // Conferma la calibration di un insieme di utenti
  sendSchedules() {
    this.isLoadingUsers = true;
    this.isLoadingCounters = true;
    let userIds = this.selectedUserList.map((user: any) => user.userId);
    if (!userIds || !userIds.length) {
      this.redirectService.goBackBrowser();
    } else {
      this.adminService.savePostCalibrationFormForAdmin(userIds, null, true)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "save001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.selectedUserList = [];
            this.getCountersData();
            this.getUsersList(false);
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "save002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  isMenuOptionValid(option: any, user: any) {
    if (this.loggedUser.userId == user.userId) {
      return false;
    } else if (option.id == 'view') {
      // // if (user.postCalibrationStatus == PerfAlloyAdminPostCalibrationFormStatuses.WAITING || user.postCalibrationStatus == PerfAlloyAdminPostCalibrationFormStatuses.SENT) {
      // if (user.postCalibrationStatus == "WAITING" || user.postCalibrationStatus == "SENT") {
      //   return false
      // }
      return true;
    } else if (option.id == 'compare') {
      if (user.postCalibrationStatus == "WAITING") {
        return false
      }
      return true;
    } else if (option.id == 'verify') {
      if (user.postCalibrationStatus == "WAITING" || user.postCalibrationStatus == "SENT") {
        return false
      }
      for (let i = 0; i < this.selectedUserList.length; i++) {
        if (this.selectedUserList[i].postCalibrationStatus == "WAITING" || this.selectedUserList[i].postCalibrationStatus == "SENT") {
          return false
        }
      }
      return true;
    } else if (option.id == 'self') {
      if (user.selfAssessmentReceived && user.postCalibrationStatus != 'SENT') {
        return true;
      }
    } else if (option.id == 'people') {
      if (user.appraisalReceived && user.postCalibrationStatus != 'SENT') {
        return true;
      }
    }
    return false;
  }

  canGoToMatrix() {
    for (let i = 0; i < this.selectedUserList.length; i++) {
      if (this.selectedUserList[i].postCalibrationStatus == "WAITING") {
        return false
      }
    }
    return true;
  }

  canConfrimUserList() {
    for (let i = 0; i < this.selectedUserList.length; i++) {
      if (this.selectedUserList[i].postCalibrationStatus == "WAITING" || this.selectedUserList[i].postCalibrationStatus == "SENT") {
        return false
      }
    }
    return true;
  }


  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadProcessParticipantsReportForAdmin(this.processYear, this.processYearCode, this.selectedFiltersNumber == 0, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  openScheduleProgressModal() {
    this.modalService.open('schedule-progress-status');
  }

  closeScheduleProgressModal() {
    this.modalService.close('schedule-progress-status');
  }

  impersonate(userId: string) {
    this.isLoadingUsers = true;
    this.impersonateUserForAdmin$ = this.adminService.impersonateUserForAdmin(userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          if (data.error == 'USER_NOT_FOUND') {
            const messageObj: ApplicationModalMessage = {
              modalId: "a012",
              text: this.translate.instant("errors.NOT_AUTHORIZED_IMPERSONATE"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUsers = false;
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "a012",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        } else {
          let url = this.urlService.getApplicationUrl();
          // const redirectUrl = 
          let redirectUrl = '';
          if (window.location.href.indexOf('localhost') >= 0) {
            redirectUrl = 'http://localhost:4200/#/impersonateRedirect?token=' + data.response;
          } else {
            redirectUrl = url.url + '/#/impersonateRedirect?token=' + data.response;
          }

          window.open(redirectUrl, '_blank');
        }
        this.isLoadingUsers = false;
      },
        (err) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a013",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        })
  }

  closeReopenScheduleModal(confirm?: boolean) {
    this.modalService.close('reopen-schedule');
    if (confirm) {
      let serviceToCall;
      if (this.reopenModal.isSelf) {
        serviceToCall = this.adminService.reopenSelfAssessmentSchedule(this.reopenModal.userId)
      } else {
        serviceToCall = this.adminService.reopenPeopleAppraisalSchedule(this.reopenModal.userId)
      }

      this.isLoadingUsers = true;
      this.isLoadingCounters = true;

      serviceToCall.subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "reopen001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUsers = false;
          this.isLoadingCounters = false;
        } else {
          this.reopenModal = {};
          this.getCountersData();
          this.getUsersList(false);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "reopen002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsers = false;
        this.isLoadingCounters = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }

    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.impersonateUserForAdmin$) {
      this.impersonateUserForAdmin$.unsubscribe();
    }
  }
}


