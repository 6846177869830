<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <!-- Side nav -->
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="userId ? (('feedbackSection.sendClapPage.SIDENAV_TITLE_WITH_USER' | translate) + (personDetails && personDetails.forename) + ' ' + (personDetails && personDetails.surname)) : ('feedbackSection.sendClapPage.SIDENAV_TITLE_WITHOUT_USER' | translate)"
      [description]="userId ? ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITH_USER' | translate) : ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITHOUT_USER' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="true">
        <div class="form-container">
          <ng-container *ngIf="!userId">
            <!-- user card -->
            <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
              [isValued]="selectedUserList && selectedUserList.length"
              [title]="'feedbackSection.sendClapPage.WHO_TO_CLAP' | translate">
              <!-- ricerca -->
              <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></alloy-input>

              <!-- Card degli utenti -->
              <div class="user-card-collection-container">
                <div *ngIf="!isFetchingSmartFeedbackUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                  fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                  fxLayoutGap.lt-md="16px">
                  <user-card *ngFor="let user of userCardData | slice:0:smartFeedbackNumRecords | paginate: { id: 'smartFeedback',
                  itemsPerPage: smartFeedbackNumRecords,
                  currentPage: smartFeedbackCurrentPage,
                  totalItems: smartFeedbackCounter }" [user]="user" [selectedUserList]="selectedUserList"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)"></user-card>
                </div>
                <!-- Loader -->
                <div *ngIf="isFetchingSmartFeedbackUsers" class="full-width min-height500" fxLayout="row wrap"
                  fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                  fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                  <div class="loader spinner-medium"></div>
                </div>
                <!-- impaginazione -->
                <div class="full-width" *ngIf="!isFetchingSmartFeedbackUsers && userCardData && userCardData.length"
                  fxLayout="row" fxLayoutAlign="center center">
                  <table-pagination id="smartFeedback" (pageChanged)="smartFeedbackUsersPageChanged($event)">
                  </table-pagination>
                </div>
              </div>
            </input-container>
          </ng-container>
          <!-- Area in cui si inserisce il commento per lo smart feedback -->
          <input-container [isLoading]="isFetchingCreateSmartFeedback" [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="smartFeedbackComment && smartFeedbackComment.trim().length" [isObbligatory]="true"
            title="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP' | translate}}">
            <alloy-text-area [bindValue]="smartFeedbackComment" (onModelChanged)="smartFeedbackDescriptionChanged($event)"
              placeholder="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>

          <!-- Pulsante per l'invio dello smart feedback -->
          <div class="button-container">
            <alloy-button (onButtonClicked)="openSmartFeedbackModal()" [disabled]="!checkObbligatoryData()"
              type="primary default" size="large" [label]="'feedbackSection.sendClapPage.SEND_CLAP' | translate">
            </alloy-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale smart feedback -->
<modal id="smartFeedback" [cancelLabel]="'feedbackSection.sendClapPage.modals.CANCEL_LABEL' | translate | uppercase"
  [confirmLabel]="'feedbackSection.sendClapPage.modals.CONFIRM_LABEL' | translate | uppercase"
  (onClose)="closeSmartFeedbackModal()" (onCancel)="closeSmartFeedbackModal()" (onConfirm)="sendSmartFeedback(true)">
  <modal-text-content [title]="'feedbackSection.sendClapPage.modals.SEND_SMART_FEEDBACK' | translate"
    [subtitle]="'feedbackSection.sendClapPage.modals.WHY_SEND_CLAP' | translate" [text]="smartFeedbackComment">
    <ng-container>
      <div *ngFor="let user of selectedUserList" class="user-list">
        <p>{{user.surname + " " + user.name}}</p>
      </div>
    </ng-container>
  </modal-text-content>
</modal>