<page-container>
    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="sidenavTitle" [description]="sidenavDescr">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <div class="form-container">
                <ng-container *ngIf="isLoadingMacro || isLoadingCompetenceData">
                    <div class="loader spinner-big"></div>
                </ng-container>

                <ng-container *ngIf="!isLoadingMacro && !isLoadingCompetenceData">
                    <!-- Titolo della competenza  -->
                    <input-container [isObbligatory]="true"
                        [isValued]="competenceData.title && competenceData.title.trim().length"
                        title="{{'setupCompetences.createUpdate.BEHAVIOR_TITLE' | translate}}"
                        [isWithoutInfoIcon]="true">
                        <alloy-input [bindValue]="competenceData.title" (onModelChanged)="titleChanged($event)"
                            placeholder="{{'setupCompetences.createUpdate.BEHAVIOR_TITLE_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>

                    <!-- Reference value  -->
                    <input-container [isObbligatory]="true"
                        [isValued]="competenceData.referenceValue && competenceData.referenceValue.id"
                        title="{{'setupCompetences.createUpdate.REFERENCE_VALUE' | translate}}"
                        [isWithoutInfoIcon]="true">
                        <alloy-select [inMinWidthForIe]="true"
                            [placeholder]="'setupCompetences.createUpdate.REFERENCE_VALUE_PLACEHOLDER' | translate"
                            (onSelectedItem)="selectReferenceValue($event)"
                            [selectedItem]="competenceData.referenceValue" [items]="referenceValueList"
                            [searchable]="true" bindLabel="id"
                            [readonly]="competenceData.readonly">
                        </alloy-select>
                    </input-container>

                    <!-- Descrizione della competenza -->
                    <input-container [isValued]="competenceData.description && competenceData.description.trim().length"
                        title="{{'setupCompetences.createUpdate.DESCRIPTION' | translate}}" [isWithoutInfoIcon]="true">
                        <alloy-text-area [bindValue]="competenceData.description" [maxlength]="600"
                            (onModelChanged)="descriptionChanged($event)"
                            placeholder="{{'setupCompetences.createUpdate.DESCRIPTION_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>
                    
                    <!-- Aggiungere contenitore azioni -->
                    <div class="action-container center">
                        <alloy-button (onButtonClicked)="openConfirmModal()" [disabled]="!checkComplete()"
                            type="success default" size="large" [label]="saveLabel">
                        </alloy-button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</page-container>

<modal id="recap-competence-modal" (onClose)="closeConfirmModal()" (onConfirm)="closeConfirmModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeConfirmModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <ng-container *ngIf="confirmModalTitle">
        <p class="modal-title">{{ confirmModalTitle }}</p>
        <div class="info-container">
            <!-- Avviso
            <div class="section">
                <p class="title" translate="setupCompetences.modals.WARNING_NOTICE"></p>
                <p class="text">{{ confirmModalNotice }}</p>
            </div> -->
            <!-- Titolo -->
            <div class="section">
                <p class="title" translate="setupCompetences.modals.TITLE"></p>
                <p class="text">{{ competenceData.title }}</p>
            </div>
            <!-- Valore -->
            <ng-container *ngIf="competenceData.referenceValue && competenceData.referenceValue.title">
                <div class="section">
                    <p class="title" translate="setupCompetences.modals.MACRO_COMPETENCE"></p>
                    <p class="text">{{ competenceData.referenceValue.title }}</p>
                    <br>
                </div>
            </ng-container>
            <ng-container *ngIf="roleList && roleList.length > 0">
                <div class="section">
                    <p class="title" translate="setupCompetences.modals.ROLSE_WITH_COMPETENCE"></p>
                    <!-- TODO aggiungere lista -->
                    <ul>
                        <li *ngFor="let role of roleList">{{ role.roleTitle }}</li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </ng-container>
</modal>