<div class="user-card-wrapper" *ngIf="user" (click)="!noActions && emitOnCheck()"
  [ngClass]="{'active': user.isChecked && !noActions, 'noActions': noActions}">
  <avatar-img [initials]="user.initials" [src]="user.avatar" size="medium"></avatar-img>
  <div class="name-wrapper" [ngClass]="{'row': isFeedbackCard}">
    <p class="text-default">{{ user.surname }}</p>
    <p class="text-default">{{ user.name }}</p>
  </div>
  <div class="check-wrapper" *ngIf="!noActions">
    <svg-icon *ngIf="user.isChecked" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
    </svg-icon>
  </div>

  <svg-icon *ngIf="noActions" class="trash" (click)="emitOnDelete()" src="assets/img/icons/trash-2.svg" [applyClass]="true">
  </svg-icon>
</div>