<div class="team-sidebar-container"
  [ngClass]="{'impersonate-banner-padding': hasImpersonateBanner, 'action-margin': isInPersonDetails()}">
  <div class="searchbar-container">
    <svg-icon src="/assets/img/icons/search.svg" class="regular-size blue-primary" [applyClass]="true"></svg-icon>
    <p class="text-default" *ngIf="!searchedText || !searchedText.length"></p>
    <input class="text-default" [(ngModel)]="searchedText" (keyup)="onUserSearch($event)"
      [placeholder]="'menu.SEARCH_BY' | translate" [disabled]="isLoadingUsers">
  </div>
  <ng-container *ngIf="!isLoadingUsers && !searchedText && users && users.length">
    <item-list-team class="full-width" *ngFor="let user of users; trackBy: userId; let index = index; let last = last;"
      [ngClass]="{'last-child': last}" id=" {{ 'pasUserTag' + index }}" [user]="user"
      (userClicked)="onUserClicked(user)" [activeUserId]="activeUserId" [currentPhaseId]="currentPhaseId">
    </item-list-team>
  </ng-container>
  <ng-container *ngIf="!isLoadingUsers && searchResults && searchResults.length">
    <item-list-team class="full-width"
      *ngFor="let user of searchResults; trackBy: userId; let index = index; let last = last;"
      [ngClass]="{'last-child': last}" id="{{ 'pasUserTag' + index }}" [user]="user" (userClicked)="onUserClicked(user)"
      [activeUserId]="activeUserId" [currentPhaseId]="currentPhaseId">
    </item-list-team>
  </ng-container>

  <ng-container
    *ngIf="!isLoadingUsers && ((!searchedText && structureUsers && structureUsers.length) || (searchedText && searchedText.length && searchStructureResults && searchStructureResults.length))">
    <div fxHide.xs fxHide.sm fxShow class="divisor-line-structure"></div>
    <p fxHide.xs fxHide.sm fxShow (click)="onSecondLevelOpen()" class="structure-title">{{('banner.SECOND_STRUCTURE' |
      translate) + (isOpenedSecondLevel ? '-' : '+' )}}</p>
    <p fxHide fxShow.xs fxShow.sm (click)="onSecondLevelOpen()" class="structure-title-mobile">{{isOpenedSecondLevel ?
      '-' : '+' }}</p>
    <ng-container *ngIf="!searchedText && isOpenedSecondLevel">
      <item-list-team ngClass.xs="width-auto" ngClass.sm="width-auto" class="full-width second-level"
        *ngFor="let user of structureUsers; trackBy: userId; let index = index; let last = last;"
        [ngClass]="{'last-child': last}" id="{{ 'pasStructureUserTag' + index }}" [user]="user"
        (userClicked)="onUserClicked(user)" [activeUserId]="activeUserId"
        [title]="('itemListTeamStatus.BOARD_PEOPLE_APPRAISAL' | translate)">
      </item-list-team>
    </ng-container>
    <ng-container
      *ngIf="!isLoadingUsers && searchStructureResults && searchStructureResults.length && isOpenedSecondLevel">
      <item-list-team class="full-width first-level"
        *ngFor="let user of searchStructureResults; trackBy: userId; let index = index;let last = last;"
        [ngClass]="{'last-child': last}" id="{{ 'pasStructureUserTag' + index }}" [user]="user"
        (userClicked)="onUserClicked(user)" [activeUserId]="activeUserId"
        [title]="('itemListTeamStatus.BOARD_PEOPLE_APPRAISAL' | translate)">
      </item-list-team>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isLoadingUsers && (!users || !users.length)">
    <div class="no-data-container">
      <p class="no-collab-title" translate="goalSetting.NO_COLLAB"></p>
      <p class="no-collab-description" translate="goalSetting.NO_COLLAB_DESCRIPTION"></p>
    </div>
  </ng-container>
  <!-- Loader -->
  <ng-container *ngIf="isLoadingUsers">
    <div class="loader spinner-medium"></div>
  </ng-container>
</div>