import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import * as CommonClasses from 'src/commonclasses'
import { DateUtil } from 'src/commonclasses';
@Component({
  selector: 'modal-text-content',
  templateUrl: 'modal-text-content.component.html',
  styleUrls: ['./modal-text-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTextContentComponent implements OnInit {
  @Input() modalId?: string = 'AAA'
  @Input() title?: string = 'Titolo input';
  @Input() subtitle?: string = '';
  @Input() text?: string = ''
  @Input() badge?: BadgeObject;
  @Input() image?: string;
  @Input() noBackground?: boolean;
  @Input() withScrolledBody?: boolean;
  @Input() modalWithKalturaScript?: string;
  @Input() kalturaCustomId?: string = '';
  @Input() isRiepilogueDevelopAction?: boolean;
  @Input() modalDataList!: CommonClasses.PerfAlloyGoalUpdatePhase.PerfAlloyUserGoalUpdate[];
  @Input() alignDefault: boolean = false;
  showModalCode: boolean = !environment.production;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
  }

}