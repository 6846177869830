import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PerfAlloyPeerFeedback, PerfAlloyPeerFeedbackParams, PerfAlloyUserGoal, SenecaResponse, User } from 'src/commonclasses';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';

@Component({
  selector: 'app-peopleAppraisal-createUpdatePeerFeedback',
  templateUrl: './createUpdatePeerFeedback.component.html',
  styleUrls: ['./createUpdatePeerFeedback.component.scss']
})
export class CreateUpdatePeerFeedbackPeopleAppraisalComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  modalCreateUpdatePeerFeedbackId: string = '';
  modalCreateUpdatePeerFeedbackTitle: string = '';
  modalCreateUpdatePeerFeedbackSubtitle: string = '';
  modalCreateUpdatePeerFeedbackText: string = '';
  modalCreateUpdatePeerFeedbackTitleConfirmTextButton: string = '';
  modalCreateUpdatePeerFeedbackTextCloseTextButton: string = '';
  observationsModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoUsersTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  feedbackId: string = '';
  isLoadingFeedback: boolean = false;
  feedbackObject: any = {};
  // Dati utenti
  isLoadingTeam: boolean = false;
  userCardData: {
    counter: number,
    fromRecord: number,
    numRecords: number,
    list: UserCard[],
    page: number
  } = {
      counter: 0,
      fromRecord: 0,
      numRecords: 10,
      list: [],
      page: 1
    };
  cardSelected: any;

  countTeamUsers$: Subscription = new Subscription;
  listTeamUsers$: Subscription = new Subscription;
  isLoadingTeamUsers: boolean = false;

  isLoadingGoal: boolean = false;
  goalList: Array<PerfAlloyUserGoal> = [];
  getGoalList$: Subscription = new Subscription;

  teamUsersList: {
    counter: number,
    fromRecord: number,
    numRecords: number,
    list: UserCard[],
    page: number
  } = {
      counter: 0,
      fromRecord: 0,
      numRecords: 10,
      list: [],
      page: 1
    };

  isUserSelected: boolean = false;
  chargeUsersVariable: boolean = true;
  isFetchingUsers: boolean = false;
  searchUserText: string = '';
  searchTeamUserText: string = '';
  selectedUserList: any[] = [];
  selectedUser: UserCard | undefined;
  // Obiettivi da associare
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToFeedback$: any;
  objectiveOptions: Array<{ id: string, title: string }> = [];
  selectedObjective: any;


  provaCard = {
    avatar: "https://uploadarchive.blob.core.windows.net/avatar/8e34f779-15c9-4c6a-b19c-1f79627628fcEleven-Villain-5057459.jpg?st=2022-11-21T09%3A38%3A07Z&se=4760-10-17T09%3A38%3A07Z&sp=r&sv=2018-03-28&sr=b&sig=hVsKQ00n42UxOveuvFnPIj7I1xOF3GIjtiU12nMqVmE%3D",
    id: "wbranking-user-4",
    initials: "BS",
    isChecked: false,
    name: "Sofia",
    surname: "Bianchi"
  }

  requestMessage: string = '';

  isResponse: boolean = false;
  createPeerFeedback$: Subscription = new Subscription;
  getFeedback$: Subscription = new Subscription;
  getFeedbackById$: Subscription = new Subscription;
  feedbackObjectCopy: any;
  isUpdate: boolean = false;
  answerPeerFeedback$: Subscription = new Subscription;
  isChief: boolean = false;
  isChief$: Subscription = new Subscription;

  feedbackRecap: Array<{ title: string, text?: string; avatar?: boolean, isUserSelected?: boolean }> = [];

  constructor(
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {
    this.isChief$ = this.store.select(fromApp.getIsChief).subscribe((isChief: boolean) => {
      this.isChief = isChief;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              // this.userId = params.userId;
              this.feedbackId = params.feedbackId;
              if (window.location.href.indexOf('response') > -1) {
                this.isResponse = true;
              }
              // this.getFeedbackById();
              // this.getFeedbackRequest();
              // this.getFeedback();
              //   this.resetUsers();
              // this.getUsers();
              this.getTeamUsers();
            }
          );
      }
    });
  }


  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'peerFeedback.createUpdate.OBJECTIVE_TITLE',
        'peerFeedback.modals.OBJECTIVE_TITLE_INFO',
        'peerFeedback.createUpdate.REQUEST_MESSAGE',
        'peerFeedback.modals.REQUEST_MESSAGE_INFO',
        'peerFeedback.createUpdate.WHO',
        'peerFeedback.createUpdate.WHO_TITLE',
        'peerFeedback.modals.WHO_INFO',
        'peerFeedback.modals.TO_WHOM',
        'peerFeedback.modals.NO_GOALS',
        'peerFeedback.modals.REFERS_TO',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_TITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_SUBTITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_DESC',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_TITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_SUBTITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_DESC',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_TITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_SUBTITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_DESC',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_TITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_SUBTITLE',
        'peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_DESC'

      ])
      .subscribe(translations => {
        this.whoTooltipModal = {
          modalId: "pf010",
          title: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_1_DESC']
        };
        this.whoUsersTooltipModal = {
          modalId: "pf001",
          title: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_2_DESC']
        };
        this.objectiveTitleModal = {
          modalId: "pf001",
          title: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_4_DESC']
        };
        this.requestMessageModal = {
          modalId: "pf002",
          title: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_TITLE'],
          subtitle: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_SUBTITLE'],
          text: translations['peerFeedback.modals.INFO_FEEDBACK_TO_MANAGER_3_DESC']
        };
      })
  }




  observationsChanged(text: string) {
    this.feedbackObject.approverObservation = text;
  }

  searchUsers() {
    this.getListMyColleagueUsers();
  }
  searchTeamUsers() {
    this.getTeamUsers();
  }


  // Ripristina i dai della lista utenti per l'applauso
  resetUsers(notDeleteSelected?: boolean) {
    this.userCardData.fromRecord = 0;
    this.userCardData.numRecords = 16;
    this.userCardData.page = 1;
    this.userCardData.counter = 0;
    this.userCardData.list = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  changeSearchedTeamTextValue(name: string) {
    this.searchTeamUserText = name;
  }


  usersTeamPageChanged(newPage: any) {
    this.teamUsersList.page = newPage;
    this.teamUsersList.list = [];
    this.getTeamUsers()
  }


  usersPageChanged(newPage: any) {
    this.userCardData.page = newPage;
    this.userCardData.list = [];
    this.getListMyColleagueUsers();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  // Aggiorno le card con l'utente selezionato
  selectTeamUser(user: any) {
    for (let i = 0; i < this.teamUsersList.list.length; i++) {
      if (this.teamUsersList.list[i].id !== user.id) {
        this.teamUsersList.list[i].isChecked = false;
      }
    }
    this.teamUsersList.list = JSON.parse(JSON.stringify(this.teamUsersList.list))
    // Imposto utente selezionato 
    this.selectedUser = this.teamUsersList.list.find(user => user.isChecked == true);
  }

  clickedRequestFeedback(isFirstStep?: boolean) {
    if (isFirstStep) {
      this.isUserSelected = true;
      this.getListMyColleagueUsers();
      this.getGoalsToAssociateForManager();
    }
  }

  changeAssociatedObjective(objective: any) {
    this.selectedObjective = objective;
  }

  requestMessageChanged(text: string) {
    this.requestMessage = text;
    // if (this.feedbackId) {
    //   this.feedbackObject.request = text;
    // } else {
    //   this.requestMessage = text;
    // }
  }

  // Gestisce quando crei/aggiorni un'evidenza0
  goToUpdatePeerFeedback() {
    this.formatModalCreateUpdatePeerFeedback();
    this.openModalForCreateUpdatePeerFeedback();
  }

  goBack() {
    if (this.isUserSelected) {
      this.isUserSelected = false;
    } else {
      this.goBackBrowser()
    }
  }

  formatModalCreateUpdatePeerFeedback(isBack?: boolean) {
    this.modalCreateUpdatePeerFeedbackId = this.getModalId(isBack);
    this.modalCreateUpdatePeerFeedbackTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdatePeerFeedbackSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdatePeerFeedbackText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdatePeerFeedbackTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
    this.modalCreateUpdatePeerFeedbackTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
  }


  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'pf005';
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return 'pf006';
        } else {
          return 'pf007';
        }
      } else {
        if (this.feedbackId) {
          return 'pf008';
        } else {
          return 'pf009';
        }
      }
    }
  }

  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('generic.GO_BACK');
      } else {
        if (this.feedbackId) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK_REQUEST');
        } else {
          return this.translate.instant('peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.feedbackId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_INSERTING_A_FEEDBACK');
    } else if (this.feedbackId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_UPDATING_A_FEEDBACK');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.feedbackId && this.feedbackId.length) {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      } else {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      }
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK_BACK');
        }
      } else {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateNoteModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.INSERT_FEEDBACK_BACK');
        }
      } else {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.modals.SEND_REQUEST');
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (!this.isResponse) {
      if (this.feedbackId) {
        if (this.feedbackObject && this.feedbackObject.request && this.feedbackObject.request.trim().length) {
          return true;
        }
      } else {
        if (this.selectedUserList && this.selectedUserList.length && this.requestMessage && this.requestMessage.trim().length) {
          return true;
        }
      }
      return true;
    } else {
      if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.trim().length > 0) {
        return true;
      }
      return true;
    }
  }

  // Controllo se nello step uno il pulsante è disabilitato
  isDisabled() {
    if (this.selectedUser?.id) {
      return true;
    }
    return false;
  }

  isDisabledSecondStep() {
    if (this.selectedUser && this.selectedUserList.length && this.requestMessage) {
      return true;
    }
    return false;
  }

  checkIfDataIsChanged() {
    // return this.note && this.noteCopy && ((this.note.comment != this.noteCopy.comment) || (this.note.motivation != this.noteCopy.motivation));
    // if (this.isResponse) {
    //   return this.feedbackObject && this.feedbackObjectCopy && this.feedbackObject.approverObservation != this.feedbackObjectCopy.approverObservation;
    // } else {
    //   if (this.feedbackId) {
    //     if ((this.feedbackObject.goal && this.selectedObjective && this.feedbackObject.goal.goalId !== this.selectedObjective.goalId)) {
    //       return true;
    //     }
    //     if (this.feedbackObject.request !== this.feedbackObjectCopy.request) {
    //       return true;
    //     }
    //     return false;
    //   }
    //   // else {
    //   //     return this.requestMessage
    //   // }
    // }
    return this.requestMessage;
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdatePeerFeedback() {
    this.feedbackRecap = [
      {
        title: this.translate.instant('peerFeedback.modals.ON_WHOM'),
        avatar: true,
        isUserSelected: true
      },
      {
        title: this.translate.instant('peerFeedback.modals.TO_WHOM'),
        avatar: true
      },
      {
        title: this.translate.instant('peerFeedback.modals.REFERS_TO'),
        text: this.selectedObjective.id.length ? this.selectedObjective.title : this.translate.instant('peerFeedback.modals.NO_GOALS'),
      },
      {
        title: this.translate.instant('peerFeedback.modals.MOTIVATION_FOR_REQUEST'),
        text: this.requestMessage
      }];
    this.modalService.open('modalCreateUpdatePeerFeedback');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdatePeerFeedback() {
    this.modalService.close('modalCreateUpdatePeerFeedback');
  }

  openConfrimRequest() {
    this.closeModalCreateUpdatePeerFeedback();
    this.createPeerFeedback();
  }

  closeConfirmRequest() {
    this.modalService.close('modalConfrimRequest')
  }

  closeConfirmDeleteUser(confirm?: boolean) {
    if (confirm) {
      this.isUserSelected = false;
      this.selectedUser = undefined;
      this.searchTeamUserText = '';
      this.teamUsersList.list = []
      this.getTeamUsers();
    }
    this.modalService.close('confirm-remove-user')
  }

  openConfirmDeleteUser() {
    this.modalService.open('confirm-remove-user')
  }

  createPeerFeedback() {
    // Avvio il loader
    this.isLoadingFeedback = true;

    if (this.createPeerFeedback$) {
      this.createPeerFeedback$.unsubscribe();
    }

    let intermediateUserIds: Array<string> = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      intermediateUserIds.push(this.selectedUserList[i].id);
    }

    let selectedUserId = '';
    if (this.selectedUser) {
      selectedUserId = this.selectedUser.id;
    }

    this.createPeerFeedback$ = this.peopleAppraisalService.createPeerFeedback(this.runningYear.year, this.runningYear.yearCode, this.requestMessage, intermediateUserIds, selectedUserId, this.selectedObjective?.id)
      .subscribe((data: SenecaResponse<PerfAlloyPeerFeedback>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "crpeer01",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.goBackBrowser();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "crpeer02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedback = false;
      });
  }

  getFeedbackById() {
    // Avvio il loader
    this.isLoadingFeedback = true;
    // Controllo se esisteva già il subscribe
    if (this.getFeedbackById$) {
      this.getFeedbackById$.unsubscribe();
    }
    // Faccio la chiamata per recuperare un preciso feedback 
    this.getFeedbackById$ = this.peopleAppraisalService.getPeerFeedbackById(this.runningYear.year, this.runningYear.yearCode, this.feedbackId)
      .subscribe((data: SenecaResponse<PerfAlloyPeerFeedbackParams.GetPeerFeedbackForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackObject = data.response;
          // if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.length > 0) {
          //   this.isUpdate = true;
          // }
          // this.feedbackObjectCopy = JSON.parse(JSON.stringify(this.feedbackObject));
        }
        this.isLoadingFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        })
  }

  // Recupero la lista dei miei subordinati
  getTeamUsers() {
    // Avvio il loader
    this.isLoadingTeamUsers = true;

    if (this.countTeamUsers$) {
      this.countTeamUsers$.unsubscribe();
    }
    this.countTeamUsers$ = this.peopleAppraisalService.countTeamUsers(this.runningYear.year, this.runningYear.yearCode, this.searchTeamUserText)
      .subscribe((count: SenecaResponse<number>) => {
        if (count.response || count.response == 0) {
          // Controllo se esisteva già il subscribe
          if (this.listTeamUsers$) {
            this.listTeamUsers$.unsubscribe();
          }

          // Calcolo la paginazione
          this.teamUsersList.counter = count.response;

          if (this.teamUsersList.page && this.teamUsersList.numRecords) {
            this.teamUsersList.fromRecord = (this.teamUsersList.page - 1) * this.teamUsersList.numRecords;
          } else {
            this.teamUsersList.fromRecord = 0;
          }

          // Faccio la chiamata per recuperare gli utenti del mio team
          this.listTeamUsers$ = this.peopleAppraisalService.listTeamUsers(this.runningYear.year, this.runningYear.yearCode, this.teamUsersList.fromRecord, this.teamUsersList.numRecords, this.searchTeamUserText)
            .subscribe((data: SenecaResponse<User[]>) => {
              if (data && data.error) {
                const messageObj: ApplicationModalMessage = {
                  modalId: "teamUs1",
                  text: this.translate.instant("errors." + data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              } else {
                this.selectedUser = undefined;
                // Assegno dopo la lista di utenti dopo aver convertito nel type UserCard
                this.teamUsersList.list = UserDataUtils.getUserCardData(data.response);
              }
              this.isLoadingTeamUsers = false;
            },
              (err: string) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "teamUs2",
                  text: this.translate.instant("errors." + err),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingTeamUsers = false;
              })
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "teamUs1",
            text: this.translate.instant("errors." + count.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "teamUs2",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTeamUsers = false;
      });
  }


  // // Recupero la lista utenti 
  getListMyColleagueUsers() {
    // Avvio il loader
    this.isFetchingUsers = true;

    // Inizio a fare la chiamata per il counte degli utenti
    this.peopleAppraisalService.countMyCollegueUsersForPeerFeedback(this.runningYear.year.toString(), this.searchUserText)
      .subscribe((counter: SenecaResponse<number>) => {
        if (counter && counter.response) {
          // Salvo la response
          this.userCardData.counter = counter.response;
          // Calcolo la paginazione
          this.userCardData.fromRecord = (this.userCardData.page - 1) * this.userCardData.numRecords;
          //Inizio la chimata per recuperare gli utenti 

          let userIdToIgnore = '';
          if (this.selectedUser?.id) {
            userIdToIgnore = this.selectedUser.id;
          }

          this.peopleAppraisalService.listMyColleagueUsersForPeerFeedback(this.userCardData.fromRecord, this.userCardData.numRecords, this.searchUserText, [userIdToIgnore])
            .subscribe((data: SenecaResponse<Array<User>>) => {
              if (data && data.response) {
                this.userCardData.list = UserDataUtils.getUserCardData(data.response);
                this.isFetchingUsers = false;
              } else {
                // Vedo se c'è la traduzione dell'errore
                const messageObj: ApplicationModalMessage = {
                  modalId: "gu11",
                  text: this.translate.instant("errors." + counter.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isFetchingUsers = false;
              }
            }, (err?: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "gu12",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isFetchingUsers = false;
            });
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "gu01",
            text: this.translate.instant("errors." + counter.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingUsers = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "gu02",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingUsers = false;
      });
  }

  // getGoalsToAssociateForManager

  // Recupero la lista dei miei subordinati
  getGoalsToAssociateForManager() {
    // Avvio il loader
    this.isLoadingGoalsToAssociate = true;

    // Controllo se esisteva già il subscribe
    if (this.getGoalList$) {
      this.getGoalList$.unsubscribe();
    }

    if (this.selectedUser) {
      // Faccio la chiamata per recuperare gli utenti del mio team
      this.getGoalList$ = this.peopleAppraisalService.getGoalsToAssociateForManager(this.runningYear.year, this.runningYear.yearCode, this.selectedUser.id)
        .subscribe((data: SenecaResponse<Array<PerfAlloyUserGoal>>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "goal01",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoal = false;
          } else {
            this.objectiveOptions = [{ id: '', title: 'Nessuno' }];
            for (let i = 0; i < data.response.length; i++) {
              this.objectiveOptions.push({
                id: data.response[i].goalId,
                title: data.response[i].text
              });
            }

            this.selectedObjective = { id: '', title: 'Nessuno' };
          }
          this.isLoadingGoalsToAssociate = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "goal02",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalsToAssociate = false;
          })
    }
  }

  ngOnDestroy() {
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }
  }
}