<div class="avatar-notification-container" [ngClass]="background">
  <ng-container *ngIf="src && !isSvgImg">
    <div class="avatar-wrapper background" [ngClass]="size" [ngStyle]="{'background-image': 'url(' + src + ')'}">
    </div>
  </ng-container>
  <ng-container *ngIf="isSvgImg || !src || isHr">
    <div class="avatar-wrapper" [ngClass]="size + (isHr ? ' gray ' : ' ')">
      <svg-icon *ngIf="src && isSvgImg" [src]="src" class="avatar" [applyClass]="true"></svg-icon>
      <p *ngIf="!src && !isHr">{{ initials }}</p>
      <p *ngIf="isHr">HR</p>
    </div>
  </ng-container>
  <ng-container *ngIf="_user && _user.hasNotification">
    <div class="dot"></div>
    <div class="new" [ngClass]="size">
      <p translate="generic.AVATAR_NEW"></p>
    </div>
  </ng-container>
</div>