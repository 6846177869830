import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { catchError, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'admin-dictionaries',
  templateUrl: './dictionaries.component.html',
  styleUrls: ['./dictionaries.component.scss']
})
export class DictionariesComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    }
  ]
  selectedRows: { id: number, title: number };
  translations: any;
  menuOptions: { id: string; title: any; icon: string; disabled: boolean }[] = [];

  isLoadingData: boolean = false;
  dataObject: {
    fromRecord: number,
    numRecords: number,
    page: number,
    list: any[],
    counter: number
  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      list: [],
      counter: 0
    };
  modalDataToDelete: any;
  processYear: any;
  processYearCode: any;
  getData$: Subscription = new Subscription;
  searchedText: string = '';
  allChecked: boolean = false;
  cannotDeleteModalDescription: string = '';
  importFile: any;
  resetIncludeUpload: boolean = false;
  isLoadingImport: boolean = false;
  importNotice: string = '';

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {

    this.isLoadingData = true;

    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.processYear = params.processYear;
      this.processYearCode = params.processYearCode;
      this.translate.get([
        'setupCompetences.dictionary.MANAGE_SCALE',
        'generic.DELETE',
        'CANNOT_DELETE_DICTIONARY_TITLE',
        'CANNOT_DELETE_DICTIONARY_DECR',
        'setupCompetences.modals.FILE_CONTAINS',
        'setupCompetences.modals.DICTIONARIES',
        'setupCompetences.modals.CANNOT_DELETE_DICTIONARY_DECR',
        'setupCompetences.modals.DICTIONARY',
        'setupCompetences.modals.DICTIONARIES'
      ]).subscribe(translations => {
        this.translations = translations;

        this.menuOptions = [
          {
            id: 'manage',
            title: translations['setupCompetences.dictionary.MANAGE_SCALE'],
            icon: '/assets/img/icons/edit.svg',
            disabled: false
          },
          {
            id: 'delete',
            title: translations['generic.DELETE'],
            icon: '/assets/img/icons/trash-2.svg',
            disabled: false
          }
        ]
        this.getData()
      });
    })
  }

  // ricerca tutte le competenze
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.dataObject.list = [];
    this.dataObject.counter = 0;
    this.dataObject.fromRecord = 0;
    this.dataObject.page = 1;
    this.getData();
  }


  dictionaryPageChanged(page: number) {
    this.dataObject.page = page;
    this.dataObject.list = [];
    this.getData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.dataObject.numRecords = item.id;
    this.dataObject.list = [];
    this.dataObject.fromRecord = 0;
    this.getData();
  }


  openCloseMenu(data: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], data)
    }
    data.isMenuOpen = !data.isMenuOpen;
  }

  isMenuOptionValid(option: any, data: any) {
    if (option.id == 'delete' && data.isDefault) {
      return false;
    }
    return true;
  }

  // Associato alla direttiva clickOutside
  closeMenu(competence: any) {
    competence.isMenuOpen = false;
  }

  getData() {
    this.isLoadingData = true;

    if (this.getData$) {
      this.getData$.unsubscribe();
    }

    this.getData$ = this.adminService.countDictionaries(this.processYear, this.processYearCode, this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.dataObject.counter = counter.response;

              // Calcolo la paginazione
              this.dataObject.fromRecord = (this.dataObject.page - 1) * this.dataObject.numRecords;

              if (this.dataObject.counter) {
                return this.adminService.listDictionaries(this.processYear, this.processYearCode, this.dataObject.fromRecord, this.dataObject.numRecords, this.searchedText,);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.response && data.response.length) {
              this.dataObject.list = data.response;
            } else {
              this.dataObject.list = [];
            }
          }
          this.isLoadingData = false;
        }
        , (err: any) => {
          this.isLoadingData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );


  }

  onMenuOptionsClicked(option: any, data: any) {
    if (!option.disabled) {
      if (option.id == 'manage') {
        this.router.navigate(['admin/manageScale/', this.processYear, this.processYearCode, data.tagId])
      } else if (option.id == 'delete') {
        this.modalDataToDelete = data.tagId;
        this.modalService.open('confirm-cancel');
      }
    }
  }


  closeDeleteDataModal(confirm?: boolean) {
    this.modalService.close('confirm-cancel');
    if (confirm) {
      this.isLoadingData = true;
      this.getData();

      this.adminService.deleteDictionary(this.processYear, this.processYearCode, this.modalDataToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "dic005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          } else {
            //  se non puoi eliminarla ti ritorno questo: { roleTagId: string, roleTitle: string }[] 
            // cioè un array di ruoli coi loro titoli, che sono i ruoli in cui la scala è associata)
            if (data.response && Array.isArray(data.response)) {
              this.cannotDeleteModalDescription = this.translations['setupCompetences.modals.CANNOT_DELETE_DICTIONARY_DECR'];
              this.cannotDeleteModalDescription += '<br><br><ul>'
              for (let i = 0; i < data.response.length; i++) {
                this.cannotDeleteModalDescription += '<li>' + data.response[i].roleTitle + '</li>'
              }
              this.cannotDeleteModalDescription += '<ul>'
              this.modalService.open('cannot-delete');
            } else {
              let x: any = document.getElementById("snackbar-delete");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
          }
          this.dataObject.list = [];
          this.dataObject.fromRecord = 0;
          this.dataObject.page = 1;
          this.getData();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "dic006",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        });
    }
  }

  closeCannotDeleteModal() {
    this.modalService.close('cannot-delete');
  }

  downloadTrack() {
    this.adminService.downloadImportCompetenceScaleInProcessByYearTemplate(this.processYear, this.processYearCode).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "a004",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        let filename = data.response;
        this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
          if (data && data.response) {
            let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
            setTimeout(() => {
              window.open(downloadUrl, '_blank');
            }, 500)
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "a007",
              text: this.translate.instant("errors." + data && data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a008",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
      }
    }, (err: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "a005",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    });
  }


  openImportModal() {
    this.resetIncludeUpload = true;
    this.importFile = null;
    this.modalService.open('import-modal');
  }


  closeImportModal(confirm?: boolean) {
    this.modalService.close('import-modal');
    if (confirm) {
      this.isLoadingData = true;
      this.adminService.importBehaviorScaleInProcessByYear(this.processYear, this.processYearCode, this.importFile)
        .subscribe((data: any) => {
          if (data && data.body && data.body.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          } else {
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.importFile = null;
            this.getData();
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingData = false;
          });

    }
  }

  // Vai alla pagina di creazione dei dizionari
  goToNewDictionary() {
    this.router.navigate([`admin/manageScale/`, this.processYear, this.processYearCode])
  }

  assignFile(file: any) {
    this.importFile = file;
    this.isLoadingImport = true;
    this.adminService.importBehaviorScaleInProcessByYear(this.processYear, this.processYearCode, this.importFile, true)
      .subscribe((data: any) => {
        if (data && data.body && data.body.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        } else {
          this.importNotice = this.translations['setupCompetences.modals.FILE_CONTAINS'] + ' <b>' + data.body?.response?.scaleTagsTocreateCount || 0 + '</b> ';
          this.importNotice += data.body?.response?.scaleTagsTocreateCount  > 1 ? this.translations['setupCompetences.modals.DICTIONARIES'] : this.translations['setupCompetences.modals.DICTIONARY'];
          this.isLoadingImport = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        });
  }

  ngOnDestroy(): void {
    if (this.getData$) {
      this.getData$.unsubscribe();
    }
  }
}