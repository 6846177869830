import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminHomeComponent } from './home/home.component';
import { adminReducer } from './ngrx/admin.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './ngrx/admin.effects';
import { AdminService } from '../shared/services/admin.service';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { MonitoringUserListComponent } from './monitoring-user-list/monitoring-user-list.component';
import { MonitoringMatrixComponent } from './monitoring-matrix/monitoring-matrix.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SetupCompetencesComponent } from './setupCompetences/setupCompetences.component';
import { AllCompetencesComponent } from './setupCompetences/allCompetences/allCompetences.component';
import { CreateUpdateCompetenceComponent } from './setupCompetences/createUpdateCompetence/createUpdateCompetence.component';
import { VerifySendScheduleComponent } from './verifySendSchedule/verifySendSchedule.component';
import { RolesAndCompetencesComponent } from './setupCompetences/rolesAndCompetences/rolesAndCompetences.component';
import { ManageRolesComponent } from './setupCompetences/manageRoles/manageRoles.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DxChartModule, DxCircularGaugeModule, DxPieChartModule } from 'devextreme-angular';
import { DictionariesComponent } from './setupCompetences/dictionaries/dictionaries.component';
import { ManageScaleComponent } from './setupCompetences/manageScale/manageScale.component';
import { ProcessDetailsComponent } from './processDetails/processDetails.component';
import { RedirectService } from '../shared/services/redirect.service';
import { SidebarService } from '../shared/services/sidebar.service';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { EditPhaseDateComponent } from './editPhaseDate/editPhaseDate.component';
import { UtilsService } from '../shared/services/utilsService';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    ProcessSetupComponent,
    CreateUpdateProcessComponent,
    PeopleAnagComponent,
    StructureAnagComponent,
    ImpersonateComponent,
    MonitoringComponent,
    MonitoringUserListComponent,
    MonitoringMatrixComponent,
    SetupCompetencesComponent,
    AllCompetencesComponent,
    RolesAndCompetencesComponent,
    CreateUpdateCompetenceComponent,
    ManageRolesComponent,
    VerifySendScheduleComponent,
    AdminDashboardComponent,
    DictionariesComponent,
    ManageScaleComponent,
    ProcessDetailsComponent,
    EditPhaseDateComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    DragDropModule,
    DxCircularGaugeModule,
    DxChartModule,
    DxPieChartModule,
    StoreModule.forFeature('admin', adminReducer),
    EffectsModule.forFeature([AdminEffects])
  ],
  providers: [
    AdminService,
    RedirectService,
    SidebarService,
    UtilsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AdminModule { }
