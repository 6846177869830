<div class="banner-home" [ngClass]="{'narrow': isNarrow, 'final-evaluation': phaseName == 'FINAL_EVALUATION' }">
  <!--Banner maschera immagine-->
  <div class="img-banner-mask" [ngStyle]="{'background-image': 'url(' + backgroundSrc + ')'}"></div>
  <!--Banner testo banner-->
  <div id="bigBannerTag" class="text-banner-mask orange-bg">

    <ng-container *ngIf="!isAdmin">
      <!--Decorazione Banner-->
      <svg fxHide.xs class="decoration-img" preserveAspectRatio="none" width="33px" height="100%" viewBox="0 0 33 400"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 200C0 274.643 12.1388 343.713 32.7478 400V0C12.1388 56.2869 0 125.357 0 200Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 200C0 125.357 12.1388 56.2869 32.7478 0V400C12.1388 343.713 0 274.643 0 200Z" />
      </svg>
      <!-- Contenuto "default" del banner -->
      <div class="text-banner">
        <div class="banner-header">
          <!-- Data e colloqui -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
            <!--div class="meetings">
              <p *ngIf="!meetingNumber" class="text-small" translate="banner.NO_MEETINGS"></p>
              <p *ngIf="meetingNumber == 1" class="text-small">{{ meetingNumber + ('banner.MEETING' |
                translate)}}</p>
              <p *ngIf="meetingNumber > 1" class="text-small">{{ meetingNumber + ('banner.MEETINGS' |
                translate)}}</p>
            </div-->
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="alloy-logo"></svg-icon>
          </div>
        </div>
        <!-- Testo Banner -->
        <ng-container *ngIf="phaseName == 'H1' || phaseName == 'H2'">
          <h2 class="title-page full-width">{{ (('banner.' + phaseName + '.TITLE_1') | translate) + runningYear +
            (('banner.' + phaseName + '.TITLE_2') | translate)}}</h2>
        </ng-container>
        <ng-container *ngIf="phaseName == 'GOALS_UPDATE'">
          <h2 class="title-page full-width">{{ 'banner.GOALS_UPDATE.TITLE' | translate }}</h2>
        </ng-container>
        <!-- Se altra fase -->
        <ng-container *ngIf="phaseName != 'H1' && phaseName != 'H2' && phaseName != 'GOALS_UPDATE'">
          <h2 class="title-page full-width">{{ ('banner.' + phaseName + '.TITLE') | translate }}</h2>
        </ng-container>

        <!-- Contenuto banner -->
        <div class="banner-content">
          <h3 [ngClass]="{'goalPhase': isGoalsUpdatePhase}" class="text-big">{{ bannerSubtitle || '' }}</h3>
            <p *ngIf="!selfAssessment && !isGoalsUpdatePhase" class="list text-small"
              [innerHTML]="('banner.' + phaseName + '.LIST' | translate)"></p>
            <p *ngIf="selfAssessment && !isGoalsUpdatePhase" class="list text-small"
              [innerHTML]="('banner.' + phaseName + '.LIST_SELF' | translate)"></p>
        </div>
        <alloy-button *ngIf="hasButton" type="secondary medium" [label]="buttonLabel"
          (onButtonClicked)="emitOnButtonClicked($event)">
        </alloy-button>
      </div>
    </ng-container>

    <ng-container *ngIf="isAdmin">
      <!--Decorazione Banner-->
      <svg fxHide.xs class="decoration-img" preserveAspectRatio="none" width="33px" height="100%" viewBox="0 0 33 400"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 200C0 274.643 12.1388 343.713 32.7478 400V0C12.1388 56.2869 0 125.357 0 200Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 200C0 125.357 12.1388 56.2869 32.7478 0V400C12.1388 343.713 0 274.643 0 200Z" />
      </svg>
      <!-- Contenuto "default" del banner -->
      <div class="text-banner admin">
        <div class="banner-header">
          <!-- Data e colloqui -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
            <!--div class="meetings">
              <p *ngIf="!meetingNumber" class="text-small" translate="banner.NO_MEETINGS"></p>
              <p *ngIf="meetingNumber == 1" class="text-small">{{ meetingNumber + ('banner.MEETING' |
                translate)}}</p>
              <p *ngIf="meetingNumber > 1" class="text-small">{{ meetingNumber + ('banner.MEETINGS' |
                translate)}}</p>
            </div-->
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="alloy-logo"></svg-icon>
          </div>
        </div>
        <div class="banner-title-wrapper">
          <!-- Titolo banner -->
          <ng-container *ngIf="phaseName == 'H1' || phaseName == 'H2'">
            <h2 class="title-page full-width">{{ (('banner.' + phaseName + '.TITLE_1') | translate) + runningYear +
              (('banner.' + phaseName + '.TITLE_2') | translate)}}</h2>
          </ng-container>
          <ng-container *ngIf="phaseName != 'H1' && phaseName != 'H2'">
            <h2 class="title-page full-width">{{ ('banner.' + phaseName + '.TITLE') | translate }}</h2>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>