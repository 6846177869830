import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SendClapComponent } from '../sendClap/sendClap.component';

@Injectable()
export class CanDeactivateUpdateClapPeopleAppraisalGuardService implements CanDeactivate<SendClapComponent> {
  constructor() { }

  canDeactivate(component: SendClapComponent): boolean | Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!component.forceBack) {
        if (!component.noDataInserted() && !component.alreadySaved &&
          component.checkObbligatoryData() &&
          (false)
        ) {
          component.formatDataOpenUpdateModal();

          let clickedButton = document.getElementById("confirmBtn");
          if (clickedButton) {
            clickedButton.onclick = ((e: any) => {
              resolve(false);
            });
          }

        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }
}