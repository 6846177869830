<div class="page-content-wrapper">

  <div id="snackbarPersonDetails">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>
  <div id="objectivesReproposed">
    <p translate="stepper.OBJECTIVES_REPROPOSED"></p>
  </div>
  <div id="objectiveReproposed">
    <p translate="stepper.OBJECTIVE_REPROPOSED"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
      [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
      (onNewInterview)="createPrivateNote()" [hideAction]="isImpersonating" [menuButtons]="menuTabs">
    </header-dossier>

    <div class="main-content-wrapper">
      <!-- dati semestre H1 H2-->
      <ng-container *ngIf="!isNoteSection && !isGoalsUpdatePhase">
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab && currentStep && currentStep.id">
          <child-content>
            <stepper id="stepper" [statuses]="stepList" [dots]="5" [currentStatus]="currentStep"
              (stepClicked)="onStepClicked($event)"></stepper>

            <!-- 1 - Valutazione obiettivi di sviluppo -->
            <ng-container *ngIf="currentStep.id == 'objectiveEvaluation'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingToEvaluateGoals">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingToEvaluateGoals">
                <!-- Se non ho obiettivi mostro placeholder -->
                <ng-container *ngIf="!toEvaluateList || !toEvaluateList.length">
                  <no-data [title]="('generic.NO_OBJECTIVE_TO_EVALUATE' | translate)"
                    [description]="('generic.NO_OBJECTIVE_TO_EVALUATE_DESCR' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
                <!-- se ho obiettivi -->
                <ng-container *ngIf="toEvaluateList && toEvaluateList.length">
                  <div class="page-header">
                    <div id="firstStepPageHeader">
                      <div class="title-container">
                        <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                        <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                          (click)="openHelpModal(currentStep.id)">
                        </svg-icon>
                        <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                          [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                        </svg-icon>
                      </div>
                      <!-- Valutazione qualitativa -->
                      <p class="description" *ngIf="!currentStep.isCEO"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_SELF' | translate"></p>
                      <!-- Valutazione CEO (qualitativa + quantitativà) -->
                      <p class="description" *ngIf="currentStep.isCEO"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_SELF_QUANTITY' | translate"></p>
                    </div>
                  </div>
                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <ng-container *ngFor="let objective of toEvaluateList; let toEvaluateItemIndex = index;">
                    <text-goal [countUpdates]="objective.goalUpdates.length" [tagTitle]="'Obiettivo ' + objective.count"
                      [title]="objective.text" [evaluation]="objective.evaluation" [isEvaluation]="true"
                      [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.creationUser.forename"
                      (evaluationChanged)="onEvaluationChanged($event, objective)" [showMenu]="false"
                      [disabled]="isScheduleShared || isFinalStepCompleted"
                      [id]="'toEvaluateItemIndex' + toEvaluateItemIndex"
                      (showModal)="openUpdatesModal(toEvaluateItemIndex)" [isNotificationUpdates]="true"
                      [evaluationCEO]="currentStep.isCEO" [evaluationVote]="objective.evaluationVote"
                      (evaluationVoteChanged)="onEvaluationVoteChanged($event, objective)">
                    </text-goal>
                  </ng-container>
                </ng-container>
              </ng-container>

              <div class="person-details-main-actions-banner" id="saveGoalToEvaluateBtn">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button [disabled]="true" type="black-border" size="medium"
                      label="{{'generic.BACK' | translate}}" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs
                      fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [disabled]="true" type="black-border with-just-left-icon" size="medium" label=""
                      iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <ng-container *ngIf="!isScheduleShared && !isFinalStepCompleted">
                      <!-- Salva e continua (se ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="toEvaluateList && toEvaluateList.length"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.SAVE_AND_CONTINUE' | translate}}" (click)="saveObjectiveEvaluation()">
                      </alloy-button>
                      <!-- Va avanti di step (se non ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="!toEvaluateList || !toEvaluateList.length"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.CONTINUE' | translate}}" (click)="goToNextStep()">
                      </alloy-button>
                    </ng-container>
                  </div>
                </div>
              </div>

            </ng-container>

            <!-- 2 - Valutazione dei comportamenti organizzativi -->
            <ng-container *ngIf="currentStep.id == 'behaviourEvaluation'">
              <div class="page-header" id="secondStepPageHeader">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                  <svg-icon *ngIf="!isLoadingBehaviours" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openOrganizationalBehaviorsTutorial()">
                  </svg-icon>
                </div>
                <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR' | translate"></p>
              </div>
              <ng-container *ngIf="!isLoadingBehaviours">
                <!-- Esempio competenze -->
                <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                  <competence-evaluation [titlesRightSection]="titlesRightSection" [competenceList]="competence"
                    [id]="'competenceIndex' + competenceIndex" (infoClicked)="openCompetenceDictionaryModal($event)"
                    (ratingChanged)="onBehaviourRatingChanged($event)" [ratingScale]="competence.ratingScale"
                    [disabled]="isScheduleShared || isFinalStepCompleted">
                  </competence-evaluation>
                </ng-container>

                <!-- Media finale -->
                <competence-average *ngIf="softSkillsFinalAvg && softSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="soft-skill-final-avg" [competenceAvg]="softSkillsFinalAvg"
                  [ratingScale]="softSkillsFinalAvg.ratingScale">
                </competence-average>

                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="manageBehaviorComments && manageBehaviorComments.trim().length" [isObbligatory]="false"
                  title="{{'generic.NOTES_COMMENTS' | translate}}" [disabled]="isScheduleShared || isFinalStepCompleted"
                  id="competenceEvaluationNotes">
                  <alloy-text-area [bindValue]="manageBehaviorComments" [maxlength]="300"
                    (onModelChanged)="onManageBehaviorCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}"
                    [readonly]="isScheduleShared || isFinalStepCompleted">
                  </alloy-text-area>
                </input-container>
              </ng-container>

              <ng-container *ngIf="isLoadingBehaviours">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button [disabled]="isLoadingBehaviours" type="primary" size="medium"
                      label="{{'generic.SAVE_AND_CONTINUE' | translate}}" (click)="saveBehaviourEvaluation()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 3 - Valutazione competenze tecniche -->
            <ng-container *ngIf="currentStep.id == 'techEvaluation'">
              <div class="page-header" id="thirdStepPageHeader">
                <div class="title-container">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                    translate="stepper.TECH_SKILL_EVALUATION"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES"></p>

                  <!--<svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                    (click)="openHelpModal(currentStep.id)">
                  </svg-icon>-->
                  <svg-icon *ngIf="!isLoadingTechSkills" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openTechSkillEvaluationTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR' | translate"></p>
                <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR' | translate"></p>
              </div>

              <!-- techSkill -->
              <ng-container *ngIf="!isLoadingTechSkills">
                <ng-container *ngFor="let techSkill of techSkillArray; let techSkillIndex = index;">
                  <competence-evaluation [titlesRightSection]="titlesRightSection" [competenceList]="techSkill"
                    [id]="'competenceIndex' + techSkillIndex" (infoClicked)="openCompetenceDictionaryModal($event)"
                    (ratingChanged)="onTechSkillRatingChanged($event)" [ratingScale]="techSkill.ratingScale"
                    [disabled]="isScheduleShared || isFinalStepCompleted">
                  </competence-evaluation>
                </ng-container>

                <!-- Media finale -->
                <competence-average *ngIf="techSkillsFinalAvg && techSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="tech-skill-final-avg" [competenceAvg]="techSkillsFinalAvg"
                  [ratingScale]="techSkillsFinalAvg.ratingScale">
                </competence-average>

                <input-container id="techEvaluationNotes" [numberCharsTextArea]="300" [isFromTextArea]="true"
                  [isWithoutInfoIcon]="true" [isValued]="techSkillComment && techSkillComment.trim().length"
                  [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                  [disabled]="isScheduleShared || isFinalStepCompleted">
                  <alloy-text-area [bindValue]="techSkillComment" [maxlength]="300"
                    (onModelChanged)="onTechSkillCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}"
                    [readonly]="isScheduleShared || isFinalStepCompleted">
                  </alloy-text-area>
                </input-container>
              </ng-container>

              <!-- loader competenze tecniche -->
              <ng-container *ngIf="isLoadingTechSkills">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button [disabled]="isLoadingTechSkills" type="primary" size="medium"
                      label="{{'generic.SAVE_AND_CONTINUE' | translate}}" (click)="saveTechSkills()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 4 - Definisci obiettivi -->
            <ng-container *ngIf="currentStep.id == 'defineObjectives'">

              <div class="page-header white-bg">
                <div class="page-header" id="fourthStepPageHeader">
                  <div class="title-container">
                    <p class="title" translate="stepper.DEFINE_NEW_OBJECTIVES"></p>
                    <svg-icon *ngIf="!isLoadingDefinedObjectives && !isLoadingUserGoalToDefine"
                      src="/assets/img/icons/flag.svg" class="title-icon" [applyClass]="true"
                      (click)="openSetGoalsTutorial()">
                    </svg-icon>
                  </div>
                  <p class="description" [innerHTML]="'stepper.DEFINE_OBJECTIVES_DESCR' | translate"></p>
                </div>
                <!-- Lista obiettivi -->
                <ng-container *ngIf="!isLoadingDefinedObjectives">
                  <ng-container *ngFor="let objective of newObjectiveList; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [tagTitle]="'Obiettivo ' + objective.count" [title]="objective.title"
                        [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.creationUser.forename"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted" [editDisabled]="objective.cloned"
                        (menuOptionClicked)="onMenuOptionClicked($event, objective)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isCreateObjective]="true" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}"
                        (deleteObjective)="onMenuOptionClicked('delete', objective)" [disabled]="isScheduleShared">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'defineObjectives.INSERT_OBJECTIVE' | translate}}"
                          [readonly]="isScheduleShared">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <!-- Aggiungi obiettivo -->
                  <ng-container *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <alloy-button class="margin-bottom0" id="addGoalBtn" type="add-objective link" size="large"
                      label="{{'defineObjectives.ADD_OBJECTIVE' | translate}}"
                      iconRight="assets/img/icons/plus-square.svg" (onButtonClicked)="addNewObjective()"></alloy-button>
                    <!-- Riproponi obiettivo -->
                    <alloy-button class="margin-bottom0" id="reproposeGoalBtn" type="blue-link" size="large"
                      label="{{'defineObjectives.REPROPOSE_OBJ' | translate}}"
                      (onButtonClicked)="openReproposeObjModal()">
                    </alloy-button>
                  </ng-container>

                </ng-container>

                <!-- Loader -->
                <ng-container *ngIf="isLoadingDefinedObjectives">
                  <div class="loader spinner-small"></div>
                </ng-container>
              </div>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn" *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <alloy-button [disabled]="isLoadingDefinedObjectives || !canSaveDefinedObjectives()" type="primary"
                      size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                      (onButtonClicked)="saveDefinedObjective()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 5 - Valutazione finale -->
            <ng-container *ngIf="currentStep.id == 'finalEvaluation'">
              <ng-container *ngIf="!isLoadingFinalEvaluationData">

                <ng-container *ngIf="checkNewObjectives()">
                  <div class="notice-new-objective-container">
                    <div class="notice-container">
                      <div class="dot"></div>
                      <p>{{ newObjectiveMessage }}</p>
                    </div>
                    <p class="go-to-link" translate="finalEvaluation.NOTICE_ACTION" (click)="scrollToNewObjective()">
                    </p>
                  </div>
                </ng-container>

                <!-- con la valutazione e con la modale con le informazioni -->
                <ng-container *ngIf="finalEvaluation.pasPerformer.value">
                  <performer-evaluator [performerValue]="finalEvaluation.pasPerformer.value"
                    [enablePerformerInfo]="true" (openPerformerModal)="openPerformerModal($event)"
                    [sentToHr]="isScheduleShared" class="white-box-with-shadow"
                    [hrRemarks]="finalEvaluation.adminComment" [enableHrRemarks]="isPostCalibration"
                    [isHrRemarksVisible]="true">
                  </performer-evaluator>
                </ng-container>
                <!-- valutazione ancora da impostare e con la modale con le informazioni -->
                <ng-container *ngIf="!finalEvaluation.pasPerformer.value">
                  <performer-evaluator [hasToSetVal]="true" [enablePerformerInfo]="true"
                    (openPerformerModal)="openPerformerModal($event)" class="white-box-with-shadow">
                  </performer-evaluator>
                </ng-container>
              </ng-container>

              <!-- Recap fasi precedenti -->
              <!-- Placeholder obiettivi valutati -->
              <ng-container *ngIf="isLoadingFinalEvaluationData">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                  </div>
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </div>
              </ng-container>

              <!-- Recap valutazione obiettivi -->
              <ng-container *ngIf="!isLoadingFinalEvaluationData">
                <ng-container *ngIf="finalEvaluation.evaluatedData && finalEvaluation.evaluatedData.length">
                  <!-- Recap valutazione obiettivi di sviluppo -->
                  <div class="page-header white-bg">
                    <div class="title-container">
                      <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                    </div>

                    <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                    <div class="objective-tag-container">
                      <!-- Placeholder nessun obiettivo -->
                      <ng-container *ngIf="!selectedObjective || finalEvaluation.emptyEvaluatedObjective">
                        <p class="placeholder margin" translate="defineObjectives.OBJECTIVE_NOT_EVALUATED"></p>
                      </ng-container>

                      <!-- recap obiettivi -->
                      <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                        <ng-container *ngFor="let objective of finalEvaluation.evaluatedData; let i = index">
                          <div class="objective-tag" (click)="selectObjectiveFinalEvaluation(objective)"
                            [ngClass]="{ 'selected': selectedObjective && selectedObjective.goalId && selectedObjective.goalId == objective.goalId }">
                            <p>{{ ('finalEvaluation.OBJECTIVE' | translate) + ' ' + ( i + 1 )}}</p>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <!-- Recap obiettivo selezionato -->
                    <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                      <div class="objective-evaluation-content">
                        <!-- Obiettivo -->
                        <text-goal [title]="selectedObjective.text" [showMenu]="false"></text-goal>
                        <!-- Valutazione obiettivo people appraisal -->
                        <ng-container *ngIf="loggedUser && !selectedObjective.editObjectiveEvaluation">
                          <evaluation-rating *ngIf="!currentStep.isCEO" [user]="loggedUser"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                            [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                            [canEdit]="!isFinalStepCompleted" [isEditDisabled]="isScheduleShared"
                            (onEdit)="editCurrentObjective(selectedObjective)">
                          </evaluation-rating>
                          <evaluation-rating *ngIf="currentStep.isCEO" [user]="loggedUser"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                            [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                            [rating]="selectedObjective.evaluationVote"
                            [ratingValue]="selectedObjective.evaluationVote">
                          </evaluation-rating>
                        </ng-container>

                        <ng-container *ngIf="selectedObjective.editObjectiveEvaluation">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="selectedObjective.evaluation && selectedObjective.evaluation.trim().length"
                            [isObbligatory]="false" title="{{'textGoal.INSERT_LABEL' | translate}}">
                            <alloy-text-area [bindValue]="selectedObjective.evaluation" [maxlength]="300"
                              (onModelChanged)="onObjectiveEvaluationEdited($event)"
                              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                          </input-container>
                        </ng-container>

                        <!-- Valutazione obiettivo self-assessment-->
                        <ng-container
                          *ngIf="(isPostCalibration || isFinalStepCompleted) && selectedObjective.selfEvaluation">
                          <evaluation-rating *ngIf="!currentStep.isCEO" [user]="personDetails"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + ' ' + personDetails.forename"
                            [evaluationText]="selectedObjective.selfEvaluation">
                          </evaluation-rating>
                          <evaluation-rating *ngIf="currentStep.isCEO" [user]="personDetails"
                            [evaluationTitle]="('finalEvaluation.SELF_ASSESSMENT' | translate) + ' ' + personDetails.forename"
                            [evaluationText]="selectedObjective.selfEvaluation" [isSelf]="true"
                            [rating]="selectedObjective.selfEvaluationVote"
                            [ratingValue]="selectedObjective.selfEvaluationVote">
                          </evaluation-rating>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Recap valutazione comportamenti -->
              <ng-container *ngIf="!currentStep.isCEO">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                  </div>
                  <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR_FE' | translate"></p>

                  <ng-container *ngIf="!isLoadingFinalEvaluationData">
                    <!-- placeholder valutazione incompleta -->
                    <ng-container *ngIf="!finalEvaluation.behaviorData || !finalEvaluation.behaviorData.rating ||
                      !finalEvaluation.behaviorData.ratingValue">
                      <star-rating [disabled]="true" [forceRating]="0"
                        [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                    </ng-container>

                    <ng-container
                      *ngIf="finalEvaluation.behaviorData && finalEvaluation.behaviorData.rating && finalEvaluation.behaviorData.ratingValue">
                      <!-- tolta possibilità di modificare il commento dalla scheda di recap, come richiesto 20/03/23
                          ng-container *ngIf="editBehaviorComment">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                            [isValued]="finalEvaluation.behaviorData.comment && finalEvaluation.behaviorData.comment.trim().length"
                            [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                            <alloy-text-area [bindValue]="finalEvaluation.behaviorData.comment" [maxlength]="300"
                              (onModelChanged)="onBehaviorCommentEdited($event)"
                              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                          </input-container>
                        </ng-container
                      -->

                      <!-- Rating comportamenti people appraisal -->
                      <ng-container *ngIf="loggedUser && !editBehaviorComment">
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                          [evaluationText]="finalEvaluation.behaviorData.comment"
                          [rating]="finalEvaluation.behaviorData.rating"
                          [ratingValue]="finalEvaluation.behaviorData.ratingValue">
                        </evaluation-rating>
                      </ng-container>

                      <!-- Rating comportamenti self-assessment -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.behaviorData.selfRating && finalEvaluation.behaviorData.selfRatingValue">
                        <evaluation-rating [user]="personDetails"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                          [evaluationText]="finalEvaluation.behaviorData.selfComment"
                          [rating]="finalEvaluation.behaviorData.selfRating"
                          [ratingValue]="finalEvaluation.behaviorData.selfRatingValue" [isSelf]="true">
                        </evaluation-rating>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isLoadingFinalEvaluationData">
                    <div class="objective-tag-container">
                      <div class="loader spinner-medium"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>


              <!-- Recap valutazione competenze tecniche -->
              <ng-container *ngIf="!isManagement">
                <!-- Se non è un utente del Management-->
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                      translate="stepper.TECH_SKILL_EVALUATION"></p>
                    <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES">
                    </p>
                  </div>
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR_FE' | translate"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR_FE' | translate"></p>
                  <ng-container *ngIf="!isLoadingFinalEvaluationData">
                    <!-- placeholder valutazione incompleta -->
                    <ng-container
                      *ngIf="!finalEvaluation.competenceData || !finalEvaluation.competenceData.rating || !finalEvaluation.competenceData.ratingValue">
                      <star-rating [disabled]="true" [forceRating]="0"
                        [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                    </ng-container>

                    <ng-container
                      *ngIf="finalEvaluation.competenceData && finalEvaluation.competenceData.rating && finalEvaluation.competenceData.ratingValue">

                      <!-- Rating competenze tecniche people appraisal -->
                      <ng-container *ngIf="loggedUser && !editCompetenceComment">
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                          [evaluationText]="finalEvaluation.competenceData.comment"
                          [rating]="finalEvaluation.competenceData.rating"
                          [ratingValue]="finalEvaluation.competenceData.ratingValue">
                        </evaluation-rating>
                      </ng-container>

                      <!-- Edit commento competenze -->
                      <!-- Tolta possibilità modifica da recap 20/03/22
                         ng-container *ngIf="editCompetenceComment">
                        <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                          [isValued]="finalEvaluation.competenceData.comment && finalEvaluation.competenceData.comment.trim().length"
                          [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                          <alloy-text-area [bindValue]="finalEvaluation.competenceData.comment" [maxlength]="300"
                            (onModelChanged)="onCompetenceCommentEdited($event)"
                            placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                          </alloy-text-area>
                        </input-container>
                      </ng-container
                      -->

                      <!-- Rating competenze tecniche self-assessment -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.competenceData.selfRating && finalEvaluation.competenceData.selfRatingValue">
                        <evaluation-rating [user]="personDetails"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                          [evaluationText]="finalEvaluation.competenceData.selfComment"
                          [ratingValue]="finalEvaluation.competenceData.selfRatingValue"
                          [rating]="finalEvaluation.competenceData.selfRating" [isSelf]="true">
                        </evaluation-rating>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isLoadingFinalEvaluationData">
                    <div class="objective-tag-container">
                      <div class="loader spinner-medium"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>


              <!-- Recap obiettivi definiti -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title"> {{ ('finalEvaluation.OBJECTIVE_DEVELOPMENT' | translate) +
                    ('headerDossier.CARD_MIN' | translate) + ' '+ getYearName()}}</p>
                </div>
                <p class="description" [innerHTML]="recapDevelopmentDescription"></p>

                <!-- Lista obiettivi per il semestre successivo -->
                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <ng-container *ngFor="let objective of finalEvaluation.definedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted" [editDisabled]="objective.cloned"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective)"
                        [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.forename"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [canCancelEdit]="true"
                          (cancelEdit)="onCancelEditFinalEvaluation(objective)">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!finalEvaluation.definedObjectives || !finalEvaluation.definedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>
                </ng-container>

                <!-- Obiettivi definiti dall'utente -->
                <ng-container
                  *ngIf="isPostCalibration && finalEvaluation.selfDefinedObjectives && finalEvaluation.selfDefinedObjectives.length">

                  <div #newObjectiveContainer class="description-container margin-top40">
                    <p class="description" [innerHTML]="recapSubordinateDevelopmentDescription"></p>
                    <p class="text underline" translate="defineObjectives.NOTICE"></p>
                  </div>

                  <ng-container *ngFor="let objective of finalEvaluation.selfDefinedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted"
                        [isToApprove]="objective.goalToBeApproved" [editDisabled]="objective.cloned"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective, true)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [canCancelEdit]="true"
                          (cancelEdit)="onCancelEditFinalEvaluation(objective)">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="!finalEvaluation.selfDefinedObjectives || !finalEvaluation.selfDefinedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>


              <!-- Recap valutazione finale -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                </div>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <ng-container
                    *ngIf="(!isScheduleShared && !isPostCalibration && !isFinalStepCompleted) || editFinalEvaluationComment">
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                      [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                      <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                        (onModelChanged)="onFinalEvaluationCommentChanged($event)"
                        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                      </alloy-text-area>
                    </input-container>
                  </ng-container>


                  <ng-container *ngIf="isScheduleShared || isPostCalibration || isFinalStepCompleted">
                    <!-- Rating competenze tecniche people appraisal -->
                    <ng-container *ngIf="loggedUser && !editFinalEvaluationComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + insertByManager"
                        [evaluationText]="finalEvaluation.comment" [canEdit]="!isFinalStepCompleted"
                        [isEditDisabled]="isScheduleShared || isFinalStepCompleted"
                        (onEdit)="onEditFinalEvaluationComment()">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Rating competenze tecniche self-assessment -->
                    <ng-container *ngIf="finalEvaluation.selfComment">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + personDetails.forename"
                        [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <!-- Proponi valutazione -->
              <!-- Nascosto perché ora c'è il banner
              <ng-container *ngIf="!isScheduleShared && !isPostCalibration && !isFinalStepCompleted">
                <div class="button-container">
                  <alloy-button type="success default" size="large" label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                    [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                    (onButtonClicked)="openSendFinalEvaluation()">
                  </alloy-button>
                </div>
              </ng-container> -->

              <!-- Condivisione finale -->
              <ng-container *ngIf="isPostCalibration && !isFinalStepCompleted">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title"> {{ 'finalEvaluation.FINAL_SHARE' | translate}}</p>
                  </div>
                  <p class="description" [innerHTML]="finalShareDescripton"></p>

                  <!-- Checkbox colloquio di feedback-->
                  <input-container [isWithoutInfoIcon]="true" [isValued]="feedbackCheck" [isObbligatory]="false"
                    [title]="'finalEvaluation.CONFIRM_FEEDBACK' | translate"
                    [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()">
                    <alloy-check [label]="'finalEvaluation.CONFIRM_FEEDBACK_LABEL' | translate"
                      [checked]="feedbackCheck" (onCheck)="onFeedbackCheck()">
                    </alloy-check>
                  </input-container>

                  <!-- Nascosto perché ora c'è il banner
                  <div class="button-container">
                    <alloy-button type="success default" size="large"
                      label="{{'finalEvaluation.CONFIRM_SHARE' | translate}}" (onButtonClicked)="openFinalShareModal()">
                    </alloy-button>
                  </div> -->
                </div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn" *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <!-- Salva -->
                    <alloy-button *ngIf="!isSendEvalBtnVisibleAndEnabled() && !isConfirmShareBtnVisibleAndEnabled()"
                      [disabled]="isLoadingFinalEvaluationData" type="primary" size="medium"
                      label="{{'generic.SAVE' | translate}}" (click)="saveFinalEvaluationStep()">
                    </alloy-button>
                    <!-- Proponi valutazione -->
                    <alloy-button *ngIf="isSendEvalBtnVisibleAndEnabled()" type="primary" size="medium"
                      label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                      [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                      (onButtonClicked)="openSendFinalEvaluation()">
                    </alloy-button>
                    <!-- Condividi scheda -->
                    <alloy-button *ngIf="isConfirmShareBtnVisibleAndEnabled()" [disabled]="isLoadingFinalEvaluationData"
                      type="primary" size="medium" label="{{'finalEvaluation.CONFIRM_SHARE' | translate}}"
                      (click)="openFinalShareModal()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

          </child-content>

        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingTab">
          <div class="loader spinner-big"></div>
        </ng-container>

      </ng-container>
      <!-- Aggiornamento obiettivi -->
      <ng-container *ngIf="isGoalsUpdatePhase && !isNoteSection && !isFeedbackSection">
        <child-content *ngIf="!isLoadingGoalUpdates">
          <ng-container>
            <ng-container>
              <div class="page-header">
                <div id="firstStepPageHeader">
                  <div class="title-container">
                    <p class="title" translate="updateGoals.OBJECTIVE_ADVANCEMENT_TITLE"></p>
                    <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                      (click)="openHelpModal(currentStep.id)">
                    </svg-icon>
                    <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                      [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                    </svg-icon>
                  </div>
                  <p class="description" [innerHTML]="'updateGoals.OBJECTIVE_ADVANCEMENT_DESCR' | translate"></p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngFor="let objective of goalUpdatesData; let toUpdateItemIndex = index;">
              <text-goal [countUpdates]="objective.goalUpdates.length" [isNotificationUpdates]="true"
                [tagTitle]="'Obiettivo ' + (toUpdateItemIndex + 1)" [title]="objective.text" [isEvaluation]="true"
                (evaluationChanged)="onGoalsUpdateChanged($event, toUpdateItemIndex)"
                (showModal)="openUpdatesModal(toUpdateItemIndex)" [showMenu]="false"
                [textFooter]="('textGoal.INSERTED_BY' | translate) + objective.creationUser?.forename"
                [customPlaceholder]="('textGoal.INSERT_UPDATE_PLACEHOLDER' | translate)"
                [customTitle]="('textGoal.INSERT_UPDATE_TITLE' | translate)">
              </text-goal>
            </ng-container>
            <!-- title="{{'textGoal.INSERT_UPDATE_TITLE' | translate}}"> -->

            <div class="person-details-main-actions-banner" id="saveGoalToUpdateBtn">
              <div class="row page-content-wrapper">
                <div class="center-btn">
                  <ng-container>
                    <alloy-button [disabled]="!textExists" type="primary" size="medium"
                      label="{{'generic.SAVE_UPDATES' | translate}}"
                      (onButtonClicked)="openSaveGoalUpdatesForManagerModal()">
                    </alloy-button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </child-content>
        <ng-container *ngIf="isLoadingGoalUpdates">
          <div class="loader spinner-medium"></div>
        </ng-container>
      </ng-container>

      <!-- I miei appunti -->
      <ng-container *ngIf="isNoteSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="!isLoadingPrivateNotes">

              <ng-container *ngIf="privateNotesList && privateNotesList.length">
                <!-- Titolo -->
                <div class="title-container">
                  <p class="title" translate="headerDossier.PRIVATE_NOTE"></p>
                </div>
                <p class="description" [innerHTML]="'headerDossier.FEEDBACK_DESC' | translate"></p>

                <!-- Filtri -->
                <div class="private-notes-filter" (click)="openPrivateNotesFiltersSection()"
                  (clickOutside)="openPrivateNotesFiltersSection(true)">
                  <p class="manage-filters">{{ orderPrivateNotesFilterSelected ||
                    ('privateNotes.filters.FILTER_LAST_YEAR' | translate)}}</p>
                  <ng-container *ngIf="!isPrivateNotesFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
                  </ng-container>
                  <ng-container *ngIf="isPrivateNotesFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
                  </ng-container>

                  <div class="action">
                    <ng-container *ngIf="isPrivateNotesFilterOpened">
                      <div class="dropdown-menu-table" [ngClass]="{'open-down': true}">
                        <ng-container *ngFor="let item of menuOrderYears">
                          <alloy-dropdown-item class="full-width" [item]="item"
                            (click)="onMenuPrivateNotesFilterListClicked(item)">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="card-list-container">
                  <!-- Lista appunti privati -->
                  <ng-container *ngIf="loggedUser">
                    <card-notes *ngFor="let privateNote of privateNotesList let i = index" [note]="privateNote"
                      [loggedUser]="loggedUser" (onEdit)="goToEditPrivateNote($event)"
                      (onDelete)="openDeleteNoteModal($event)"></card-notes>
                  </ng-container>
                </div>
                <!-- Pulsante
                <alloy-button [type]="'primary'" [label]="'feedbackSection.privateNotes.LABEL_BTN' | translate"
                  (onButtonClicked)="goToSmartFeedback()"></alloy-button> -->
              </ng-container>
              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                <no-data [title]="('feedbackSection.privateNotes.PRIVATE_NOTE_TITLE' | translate)"
                  [src]="'/assets/img/section/notes-smart-feedback-not-found.svg'"
                  [description]="('feedbackSection.privateNotes.PRIVATE_NOTE_SUBTITLE' | translate)">
                </no-data>
              </ng-container>
            </ng-container>
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </child-content>
      </ng-container>

      <!-- Feedback -->
      <ng-container *ngIf="isFeedbackSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="!isLoadingFeedback">
              <!-- Titolo -->
              <div class="title-container">
                <p class="title" translate="headerDossier.FEEDBACK"></p>
              </div>
              <p class="description" [innerHTML]="'headerDossier.FEEDBACK_DESC' | translate"></p>

              <!-- Filtri -->
              <!-- Filtri main-->
              <ng-container *ngIf="filterList && filterList.length">
                <div class="filters-main">
                  <div class="filters-container">
                    <div class="filters">

                      <div class="filter" *ngFor="let filter of filterList">
                        <p>{{filter.title}}</p>
                        <alloy-select [items]="filter.list" [selectedItem]="filter.selected"
                          (onSelectedItem)="selectedItem(filter, $event)"></alloy-select>
                      </div>
                    </div>
                    <!-- Divisore -->
                    <div class="divisor-line"></div>

                    <!-- footer dei filtri  -->
                    <div class="footer-filters">
                      <div class="counter-filters">
                        <p class="counter">{{filterCounter}}
                          <span class="text-counter"
                            translate="feedbackSection.smartFeedback.filters.ACTIVE_FILTERS"></span>
                        </p>
                        <p class="counter" id="results">{{feedbackList.length}}
                          <span class="text-counter" translate="feedbackSection.smartFeedback.filters.RESULTS"></span>
                        </p>
                      </div>

                      <!-- Filtro nella sezione a destra -->
                      <div class="right-section-filter" (click)="openFeedbackFiltersSection()"
                        (clickOutside)="openFeedbackFiltersSection(true)">
                        <p class="manage-filters">{{ orderFilterSelected ||
                          ('feedbackSection.smartFeedback.filters.ORDER_LATE_OR_RECENT' |
                          translate)}}</p>
                        <ng-container *ngIf="!isFeedbackFilterOpened">
                          <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true">
                          </svg-icon>
                        </ng-container>
                        <ng-container *ngIf="isFeedbackFilterOpened">
                          <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true">
                          </svg-icon>
                        </ng-container>

                        <div class="action">
                          <ng-container *ngIf="isFeedbackFilterOpened">
                            <div class="dropdown-menu-table" [ngClass]="{'open-down': true}">
                              <ng-container *ngFor="let item of menuOrderList">
                                <alloy-dropdown-item class="full-width" [item]="item"
                                  (click)="onMenuFilterListClicked(item)">
                                </alloy-dropdown-item>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="feedbackList && feedbackList.length">
                <div class="card-list-container">
                  <!-- Lista feedback -->
                  <ng-container *ngFor="let feedback of feedbackList let i = index">
                    <!-- Card peer feedback -->
                    <card-peer *ngIf="isPeerFeedback(feedback)" [object]="feedback" [loggedUser]="loggedUser"
                      (onFeedbackSend)="goToAnswerPeerFeedback(feedback.feedbackId)" [isPeopleAppraisal]="true"
                      [userIdSelected]="userId"></card-peer>
                    <!-- Card smart feedback -->
                    <card-smart *ngIf="isSmartFeedback(feedback)" [object]="feedback" [loggedUser]="loggedUser">
                    </card-smart>
                    <!-- Card feedback al manager -->
                    <card-feedback-to-manager *ngIf="isFeedbackToManager(feedback)" [object]="feedback"
                      [loggedUser]="loggedUser" [userIdSelected]="userId"></card-feedback-to-manager>
                  </ng-container>
                </div>
              </ng-container>
              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!feedbackList || !feedbackList.length">
                <no-data [title]="('feedbackSection.smartFeedback.FEEDBACK_TITLE' | translate)"
                  [src]="'/assets/img/section/notes-smart-feedback-not-found.svg'"
                  [description]="('feedbackSection.smartFeedback.FEEDBACK_SUBTITLE' | translate)">
                </no-data>
              </ng-container>
            </ng-container>
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedback">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </child-content>
      </ng-container>
    </div>
  </ng-container>
  <!-- Loader caricamento dati -->
  <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
  (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <p class="modal-title black center" *ngIf="competenceForModal">
    {{ (competenceForModal.parentBehavior ? 'manageBehaviour.DICTIONARY_TITLE' : 'manageBehaviour.COMPETENCE_TITLE')
    |translate}}
  </p>
  <ng-container *ngIf="competenceForModal">
    <div class="competence-dictionary-container">
      <ng-container *ngIf="competenceForModal.parentCompetence">
        <div class="competence-description">
          <p class="title" [innerHTML]="competenceForModal.parentCompetence.text"></p>
          <p class="subtitle" [innerHTML]="competenceForModal.title"></p>
          <p class="descr" [innerHTML]="competenceForModal.text"></p>
        </div>
      </ng-container>
      <ng-container *ngIf="competenceForModal.parentBehavior">
        <div class="competence-description">
          <p class="title" [innerHTML]="competenceForModal.parentBehavior.text"></p>
          <p class="subtitle" [innerHTML]="competenceForModal.title"></p>
          <p class="descr" [innerHTML]="competenceForModal.text"></p>
        </div>
      </ng-container>
      <div class="competence-ratings">
        <ng-container *ngFor="let rating of competenceForModal.evaluationScale">
          <div class="rating-section">
            <star-rating [forceRating]="rating.scale"></star-rating>
            <p class="descr"> {{ rating.text }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>
<!-- Modale aggiornamento obiettivi-->
<modal id="update-added" (onClose)="closeUpdateModal()" (onConfirm)="closeUpdateModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <modal-text-content [title]="'updateGoals.modals.UPDATES_ADDED' | translate"
    [subtitle]="'updateGoals.modals.LIST_OF_UPDATES' | translate" [modalDataList]="modalDataList"
    [alignDefault]="modalDataList? true: false">
  </modal-text-content>
</modal>
<!-- Modale conferma aggiornamenti obiettivi -->
<modal id="confirm-update-added" (onCancel)="closeSaveGoalUpdatesForManagerModal()"
  (onClose)="closeSaveGoalUpdatesForManagerModal()" (onConfirm)="confirmSaveGoalUpdatesForManagerModal()"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}" [cancelLabel]="('generic.CANCEL' | translate)">
  <modal-text-content [title]="'updateGoals.modals.SAVE_UPDATES' | translate"
    [subtitle]="'updateGoals.modals.SAVE_UPDATES_SUBTITLE' | translate"
    [text]="'updateGoals.modals.SAVE_UPDATES_DESC' | translate">
  </modal-text-content>
</modal>
<!-- Conferma eliminazione obiettivo -->
<!-- <modal id="confirm-delete-saved-objective" (onClose)="closeDeleteSavedObjective()"
  (onConfirm)="closeDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal> -->

<!-- Modale che mostra le informazioni del Performer -->
<modal id="performerEvaluatorInfoModal" (onClose)="closePerformerModal()" [confirmLabel]="('generic.CLOSE' | translate)"
  (onConfirm)="closePerformerModal()">
  <performer-evaluator-info-modal-content [selectedInfoTabPerformerKey]="selectedInfoTabPerformerKey">
  </performer-evaluator-info-modal-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveObjectivesModal(false,true)" (onClose)="closeSaveObjectivesModal(true, true)"
  (onConfirm)="closeSaveObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione comportamenti -->
<modal id="save-behaviours-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveBehavioursModal(false,true)" (onClose)="closeSaveBehavioursModal(true, true)"
  (onConfirm)="closeSaveBehavioursModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione tech skill -->
<modal id="save-tech-skills-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveTechSkillsModal(false,true)" (onClose)="closeSaveTechSkillsModal(true, true)"
  (onConfirm)="closeSaveTechSkillsModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva riepilogo -->
<modal id="save-final-evaluation-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveFinalEvaluationModal(false,true)" (onClose)="closeSaveFinalEvaluationModal(true, true)"
  (onConfirm)="closeSaveFinalEvaluationModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-defined-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveDefinedObjectivesModal(false,true)" (onClose)="closeSaveDefinedObjectivesModal(true, true)"
  (onConfirm)="closeSaveDefinedObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-subordinate-saved-objective" (onClose)="closeSubordinateDeleteSavedObjective()"
  (onConfirm)="closeSubordinateDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="send-hr-schedule" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeSendFinalEvaluation()"
  (onClose)="closeSendFinalEvaluation()" (onConfirm)="closeSendFinalEvaluation(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.SHARE_SCHEDULE' | translate"
    [text]="'finalEvaluation.SHARE_SCHEDULE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="final-share-modal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeFinalShareModal()"
  (onClose)="closeFinalShareModal()" (onConfirm)="closeFinalShareModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="fs001" [title]="'finalEvaluation.CONFIRM_SHARE_TITLE' | translate"
    [subtitle]="'finalEvaluation.CONFIRM_SHARE_SUBTITLE' | translate" [text]="finalShareModalDescription">
  </modal-text-content>
</modal>

<!-- Modale blocco condivisione finale -->
<modal id="cannot-share-modal" [confirmLabel]="('generic.CLOSE' | translate)" (onConfirm)="closeCannotShareModal()"
  (onClose)="closeCannotShareModal()">
  <modal-text-content *ngIf="!evaluationCEO" modalId="fs002" [title]="'finalEvaluation.CANNOT_SHARE_TITLE' | translate"
    [text]="'finalEvaluation.CANNOT_SHARE_DESCR' | translate">
  </modal-text-content>
  <modal-text-content *ngIf="evaluationCEO" modalId="fs002" [title]="'finalEvaluation.CANNOT_SHARE_TITLE' | translate"
  [text]="'finalEvaluation.CANNOT_SHARE_DESCR_CEO' | translate">
</modal-text-content>
</modal>

<modal id="repropose-obj" (onClose)="closeReproposeObjModal()" [confirmLabel]="'defineObjectives.REPROPOSE' | translate"
  (onConfirm)="closeReproposeObjModal(true)" (onCancel)="closeReproposeObjModal()"
  [cancelLabel]="'generic.CANCEL' | translate"
  [isDisabled]="isLoadingReproposeObjData || (!reproposeObjData || !reproposeObjData.length) || (!selectedReproposeObj || !selectedReproposeObj.length)">
  <p class="modal-title center repropose-obj-title" translate='defineObjectives.REPROPOSE_OBJ'>
  </p>
  <div class="repropose-obj-modal-container">

    <ng-container *ngIf="isLoadingReproposeObjData">
      <div class="spinner-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingReproposeObjData">
      <ng-template #noData>
        <div class="no-result-container">
          <p translate="generic.NO_RESULTS"></p>
        </div>
      </ng-template>
      <ng-container *ngIf="reproposeObjData && reproposeObjData.length; else noData">
        <p class="info-text" translate="defineObjectives.REPROPOSE_INFO"></p>
        <p class="info-select" translate="defineObjectives.REPROPOSE_SELECT_OBJ"></p>
        <ng-container *ngFor="let reproposeObj of reproposeObjData">
          <!-- Checkbox -->
          <alloy-check [label]="reproposeObj.text" [checked]="reproposeObj.isSelected"
            (onCheck)="onReproposeObjChecked(reproposeObj)" [disabled]="reproposeObj.isDisabled">
          </alloy-check>
          <!-- Inserito da una persona -->
          <ng-container *ngIf="reproposeObj.formattedCreationUser && reproposeObj.formattedCreationUser.length">
            <div class="full-width">
              <p class="inserted-by">{{ 'defineObjectives.INSERTED_BY' | translate }}
                <span class="bold-text">{{ ' ' + reproposeObj.formattedCreationUser }}</span>
              </p>
            </div>
          </ng-container>
          <!-- Inserito dal sistema -->
          <ng-container *ngIf="reproposeObj.enteredBySystem">
            <div class="full-width">
              <p class="inserted-by" translate="defineObjectives.ENTERED_BY_SYSTEM"></p>
            </div>
          </ng-container>
          <!-- Inserito dal manualmente -->
          <ng-container *ngIf="reproposeObj.manuallyEntered">
            <div class="full-width">
              <p class="inserted-by" translate="defineObjectives.MANUALLY_ENTERED"></p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</modal>

<!-- Modifica obiettivo -->
<modal id="edit-objective-modal" (onClose)="closeEditObjectiveModal()" [confirmLabel]="'generic.EDIT' | translate"
  (onConfirm)="closeEditObjectiveModal(true)" (onCancel)="closeEditObjectiveModal()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="custom-modal">
    <!-- Titolo, subtitolo-->
    <modal-text-content modalId="oed001" [title]="'finalEvaluation.modals.EDIT_SUB' | translate"
      [subtitle]="'finalEvaluation.modals.EDIT_SUB' | translate" text=''>
    </modal-text-content>
    <!-- Area di testo-->
    <ng-container *ngIf="editObjectiveData">
      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
        [isValued]="editObjectiveData.title && editObjectiveData.title.trim().length" [isObbligatory]="false">
        <alloy-text-area [bindValue]="editObjectiveData.title" [maxlength]="300"
          (onModelChanged)="onObjectiveEdit($event)" placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
        </alloy-text-area>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Elimina obiettivo -->
<modal id="delete-objective-modal" (onClose)="closeDeleteObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeDeleteObjectiveModal(true)"
  (onCancel)="closeDeleteObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.modals.DELETE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.DELETE_SUB' | translate" [text]="getDeleteModalText()">
  </modal-text-content>
</modal>

<!-- Approva obiettivo -->
<modal id="approve-objective-modal" (onClose)="closeApproveObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeApproveObjectiveModal(true)"
  (onCancel)="closeApproveObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.modals.APPROVE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.APPROVE_SUB' | translate" [text]="getApproveModalText()">
  </modal-text-content>
</modal>