<div class="header-container">
  <div class="left-side">
    <!-- Se lato utente -->
    <ng-container *ngIf="!isAdminModule">

      <!-- Vista manager -->
      <ng-container *ngIf="isPeopleAppraisal && !selfAssessmentView()">
        <div class="teams peopleAppraisal-background disabled"
          [ngClass]="{'peopleAppraisal-background': isPeopleAppraisal, 'selfAssessment-background': (!isPeopleAppraisal || (isPeopleAppraisal && selfAssessmentView())), 'expand': isPeopleAppraisal && isTeamPage, 'user-active': isUserPageDetail}"
          (click)="emitOnPeopleAppraisalLogoClicked()" id="myTeamTag">
          <div class="logo-description-wrapper">
            <div class="user-logo-wrapper">
              <svg-icon src="/assets/img/icons/users.svg" class="smaller-size white-svg" [applyClass]="true">
              </svg-icon>
            </div>
            <div class="user-logo-description">
              <p translate="header.TEAM"></p>
              <svg-icon *ngIf="isProfileOrTeamPage()" src="/assets/img/icons/vector-right.svg" class="arrow-icon"
                [applyClass]="true">
              </svg-icon>
            </div>
          </div>
          <!-- Sezione che si apre con il nome della pagina -->
          <!--ng-container *ngIf="isPeopleAppraisal && isTeamPage">
                <div class="page-name-container">
                    <svg-icon src="/assets/img/icons/vector-left.svg" class="arrow-icon" [applyClass]="true"></svg-icon>
                    <p>{{ isPeopleAppraisal ? ('header.TEAM_PAGE' | translate) : ('header.DIARY_PAGE' | translate) }}</p>
                </div>
            </ng-container-->
        </div>
      </ng-container>

      <!-- Vista self assessment -->
      <ng-container *ngIf="!isPeopleAppraisal || (isPeopleAppraisal && selfAssessmentView())">
        <div class="teams selfAssessment-background" [ngClass]="{'user-active': isUserPageDetail}"
          (click)="emitOnUserLogoClicked()">
          <div class="logo-description-wrapper">
            <div class="user-logo-wrapper">
              <svg-icon src="/assets/img/icons/user.svg" class="smaller-size white-svg" [applyClass]="true">
              </svg-icon>
            </div>
            <div class="user-logo-description">
              <p translate="header.DIARY"></p>
              <svg-icon *ngIf="!redirectService.isThisCurrentPage('personDetails')"
                src="/assets/img/icons/vector-right.svg" class="arrow-icon" [applyClass]="true">
              </svg-icon>
            </div>
          </div>
          <!-- Sezione che si apre con il nome della pagina -->
          <!--ng-container *ngIf="isPeopleAppraisal && isTeamPage">
                            <div class="page-name-container">
                                <svg-icon src="/assets/img/icons/vector-left.svg" class="arrow-icon" [applyClass]="true"></svg-icon>
                                <p>{{ isPeopleAppraisal ? ('header.TEAM_PAGE' | translate) : ('header.DIARY_PAGE' | translate) }}</p>
                            </div>
                        </ng-container-->
        </div>
      </ng-container>
    </ng-container>
    <!-- Se backoffice / lato admin -->
    <ng-container *ngIf="isAdminModule">
      <!-- Vista Admin -->
      <div class="teams selfAssessment-background" (click)="emitOnAppLogoClicked()">
        <div class="logo-description-wrapper">
          <div class="user-logo-wrapper">
            <svg-icon src="/assets/img/icons/user.svg" class="smaller-size white-svg" [applyClass]="true">
            </svg-icon>
          </div>
          <div class="user-logo-description">
            <p translate="header.HOME"></p>
          </div>
        </div>
      </div>
    </ng-container>


    <div class="logo-app" (click)="emitOnAppLogoClicked()">
      <svg-icon [applyClass]="true" src="assets/img/icons/webranking-logo.svg" class="logo"></svg-icon>
    </div>
  </div>
  <div class="right-side">

    <!-- Lato destro desktop -->
    <ng-container *ngIf="!isMobile">
      <ng-container *ngIf="!isAdminModule">
        <svg-icon id="supportTagIcon" src="/assets/img/icons/help-circle.svg" class="medium-size blue-primary black-icons"
          [applyClass]="true" (click)="goToSupport()"></svg-icon>
      </ng-container>
      <div class="notification-container" id="notifications" (click)="emitOnNotificationClicked($event)">
        <svg-icon src="/assets/img/icons/bell.svg" class="medium-size blue-primary black-icons" [applyClass]="true">
        </svg-icon>
        <div class="number-container" *ngIf="notificationsCounter > 0">
          <badge-notifications [badgeNumber]="notificationsCounter" type="active"></badge-notifications>
        </div>
      </div>
      <div class="user-data-container" id="userDataHeaderContainer">
        <!-- Vecchio avatar (utente mono-ruolo) 
        <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
        <div class="user-name-wrapper">
          <p>{{ userName }}</p>
          <ng-container *ngIf="!isAdminModule">
            <p *ngIf="isPeopleAppraisal" class="peopleAppraisal"
              [innerHTML]="selfAssessmentView() ? ('header.SELFASSESSMENT' | translate) : ('header.MANAGER' | translate)">
            </p>
          </ng-container>
          <ng-container *ngIf="isAdminModule">
            <p class="peopleAppraisal" [innerHTML]="('header.ADMIN' | translate)"></p>
          </ng-container>
        </div>
        <svg-icon class="medium-size blue-primary"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>  -->

        <!-- Nuovo avatar (utente mono-ruolo) -->
        <div *ngIf="!canToggleManagerAndCollaborator" class="one-role-switch-container">
          <div class="switch-avatar-wrapper">
            <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
            <div class="user-data-wrapper">
              <p class="full-name">{{ userName }}</p>
              <!-- Manager (non admin) -->
              <ng-container *ngIf="!isAdminModule">
                <p *ngIf="selfAssessmentView()" class="role" translate="header.SELFASSESSMENT"></p>
                <p *ngIf="peopleAppraisalView()" class="role" translate="header.MANAGER"></p>
              </ng-container>
              <!-- Admin -->
              <ng-container *ngIf="isAdminModule">
                <p class="role" [innerHTML]="('header.ADMIN' | translate)"></p>
              </ng-container>
            </div>
          </div>
          <svg-icon class="medium-size hamburger-menu-icon black-icons"
            [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
            (click)="toggleSidebar()"></svg-icon>
        </div>

        <!-- Switch manager-collaboratore -->
        <div *ngIf="canToggleManagerAndCollaborator" class="switch-role-avatar-wrapper">
          <!-- Il mio team -->
          <p class="role-name" [ngClass]="{ 'active': peopleAppraisalView() }" translate="header.TEAM_PAGE"
            (click)="toggleManagerAndCollaborator()"></p>
          <!-- Avatar -->
          <div class="toggle-avatar-container"
            [ngClass]="{ 'right': selfAssessmentView(), 'left': peopleAppraisalView() }"
            (click)="toggleManagerAndCollaborator()">
            <avatar-img size="small" background="no-bg" [src]="avatar" [initials]="initials"></avatar-img>
          </div>
          <!-- La mia scheda -->
          <p class="role-name" [ngClass]="{ 'active': selfAssessmentView() }" translate="header.DIARY_PAGE"
            (click)="toggleManagerAndCollaborator()"></p>
          <svg-icon class="medium-size hamburger-menu-icon black-icons"
            [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
            (click)="toggleSidebar()"></svg-icon>
        </div>

        <!-- Nuovo switch profilo
        <ng-container *ngIf="!isLoadingProfiles && profileMenuItems && profileMenuItems.length > 1">
          <div class="enter-as-contirbutor" (click)="openChangeProfileMenu()" (clickOutside)="closeProfileMenu()"
            [ngClass]="{'disabled': isLoadingProfiles}">
            <p translate="menu.CHANGE_PROFILE"></p>
            <svg-icon src="assets/img/icons/arrow-right.svg" class="small-size blue-primary" [applyClass]="true">
            </svg-icon>
          </div>
          <ng-container *ngIf="!isLoadingProfiles && profileChangeMenuOpen">
            <div class="menu-item-wrapper">
              <ng-container *ngFor="let item of profileMenuItems">
                <alloy-dropdown-item (click)="emitOnSwitchProfile(item.id)" class="full-width" [isMenuItem]="true"
                  [item]="item">
                </alloy-dropdown-item>
              </ng-container>
            </div>
          </ng-container>
        </ng-container> -->
      </div>
    </ng-container>
    <!-- Lato destro mobile -->
    <ng-container *ngIf="isMobile">
      <!--<ng-container *ngIf="!isAdminModule">
        <svg-icon id="supportTagIcon" src="/assets/img/icons/help-circle.svg" class="medium-size blue-primary black-icons"
          [applyClass]="true" (click)="goToSupport()"></svg-icon>
      </ng-container>-->
      <div class="user-data-container">
        <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
        <svg-icon class="medium-size blue-primary black-icons"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>
      </div>
    </ng-container>
  </div>
</div>