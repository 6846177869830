import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItem } from '../../models/dropdown.model';

@Component({
  selector: 'alloy-select',
  templateUrl: 'alloy-select.component.html',
  styleUrls: ['./alloy-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropSelectComponent implements OnInit {
  @Input() items: Array<{ id: string | number, title: string | number }> = [];
  @Input() notSeeDescription: boolean = false;
  @Input() selectedItem: any;
  @Input() loadingData: boolean = false;
  @Input() clearable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() forceError: boolean = false;
  @Input() isCheckbox: boolean = false;
  @Input() placeholder: string = '';
  @Input() searchable: boolean = false;
  @Input() bindLabel: string = '';
  @Input() innerHtmlDescription: boolean = false;
  // Non serve al momento
  @Input() multiple: boolean = false;
  @Input() tablePagination: boolean = false;
  // Aggiunte nuove template
  @Input() startSearchLabel?: string;
  @Input() noDataFoundLabel?: string;
  @Input() inMinWidthForIe?: boolean = false;

  @Input() typeahead?: any;
  @Input() loading: boolean = false;

  @Output() onSelectedItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddItem: EventEmitter<any> = new EventEmitter<any>();

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.NO_DATA_FOUND'

      ])
      .subscribe(translations => {
        this.noDataObject.name = this.noDataFoundLabel || translations['generic.NO_DATA_FOUND'];
      });
  }

  emitOnSelectedItem(item: any) {
    if (item && item.disabled) {
      return;
    }
    // if (this.multiple && item && item.length) {
    //   // non serve al momento
    //   this.clearList()
    //   // for (let i = 0; i < item.length; i++) {
    //   //   for (let j = 0; j < this.items.length; j++) {
    //   //     if (this.items[i].id == item.id) {
    //   //       this.items[i].isChecked = !this.items[i].isChecked;
    //   //     }
    //   //   }
    //   // }
    // } else {
    this.selectedItem = item;
    // }
    this.onSelectedItem.emit(item);
  }

  // Clicco su un option
  onOptionClicked(event: any, isDisabled: boolean) {
    if (isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // clearList() {
  //   if (this.items && this.items.length) {
  //     for (let i = 0; i < this.items.length; i++) {
  //       this.items[i].isChecked = false;
  //     }
  //   }
  //   this.cdr.detectChanges();
  // }
}