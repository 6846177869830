import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { SenecaResponse } from 'src/commonclasses';
import * as CoreActions from "../../core/ngrx/core.actions";

@Component({
  selector: 'app-createUpdatePrivateNote',
  templateUrl: './createUpdatePrivateNote.component.html',
  styleUrls: ['./createUpdatePrivateNote.component.scss']
})
export class CreateUpdatePrivateNoteComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  modalCreateUpdatePrivateNoteId: string = '';
  modalCreateUpdatePrivateNoteTitle: string = '';
  modalCreateUpdatePrivateNoteSubtitle: string = '';
  modalCreateUpdatePrivateNoteText: string = '';
  modalCreateUpdatePrivateNoteTitleConfirmTextButton: string = '';
  modalCreateUpdatePrivateNoteTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  today = new Date();

  whoNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whatToNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whyNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  isFetchingPrivateNotes: boolean = false;
  noteId: string = '';
  note: any = {
    feedbackId: '',
    comment: '',
    motivation: '',
  };
  noteCopy: any = {
    feedbackId: '',
    comment: '',
    motivation: '',
  };

  createPrivateNote$: Subscription = new Subscription;
  getPrivateNote$: Subscription = new Subscription;
  updatePrivateNote$: Subscription = new Subscription;
  isImpersonate: boolean = false;
  loggedUser: any;
  combinedSelected$: any;

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Self Assessment - Crea o modifica appunto privato");
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const runningYear$ = this.store.select(fromApp.getRunningYear);
    this.combinedSelected$ = combineLatest([loggedUser$, runningYear$])
      .subscribe(([loggedUser, runningYear]) => {
        this.loggedUser = loggedUser && loggedUser.user;
        this.runningYear = runningYear;
        if (this.runningYear && this.runningYear.year) {
          this.route.params
            .subscribe(
              (params: Params) => {
                this.noteId = params.noteId;
                if (this.noteId) {
                  this.getNote();
                }
              }
            );
        }
      });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'privateNotes.createUpdate.WHAT_TO_NOTE',
        'privateNotes.modals.WHAT_TO_NOTE_INFO',
        'privateNotes.createUpdate.WHY_NOTE',
        'privateNotes.modals.WHY_NOTE_INFO',
        'privateNotes.createUpdate.WHO_NOTE',
        'privateNotes.modals.WHO_NOTE_INFO',
      ])
      .subscribe(translations => {
        this.whatToNoteModal = {
          modalId: "pn001",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHAT_TO_NOTE'],
          text: translations['privateNotes.modals.WHAT_TO_NOTE_INFO']
        };
        this.whyNoteModal = {
          modalId: "pn002",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHY_NOTE'],
          text: translations['privateNotes.modals.WHY_NOTE_INFO']
        };
        this.whoNoteModal = {
          modalId: "pn013",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHO_NOTE'],
          text: translations['privateNotes.modals.WHO_NOTE_INFO']
        };
      })
  }

  whatChanged(text: string) {
    this.note.comment = text;
  }

  whyChanged(text: string) {
    this.note.motivation = text;
  }

  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdatePrivateNote(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdatePrivateNote(isBack);
      this.openModalForCreateUpdatePrivateNote();
    }
  }

  formatModalCreateUpdatePrivateNote(isBack?: boolean) {
    this.modalCreateUpdatePrivateNoteId = this.getModalId(isBack);
    this.modalCreateUpdatePrivateNoteTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdatePrivateNoteSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdatePrivateNoteText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdatePrivateNoteTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
    this.modalCreateUpdatePrivateNoteTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
  }

  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'pn005';
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return 'pn006';
        } else {
          return 'pn007';
        }
      } else {
        if (this.noteId) {
          return 'pn008';
        } else {
          return 'pn009';
        }
      }
    }
  }

  getNote() {
    this.isFetchingPrivateNotes = true;

    if (this.getPrivateNote$) {
      this.getPrivateNote$.unsubscribe();
    }
    this.getPrivateNote$ = this.selfAssessmentService.getPrivateNotesList(this.runningYear.year, this.runningYear.yearCode, this.noteId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else if (data.response) {
          let tmpNote = data.response.find((item: any) => item.feedbackId == this.noteId);
          if (tmpNote) {
            this.note = {
              feedbackId: tmpNote.itemId,
              comment: tmpNote.comment,
              motivation: tmpNote.motivation
            }
            this.noteCopy = JSON.parse(JSON.stringify(this.note));
          }
        }
        this.isFetchingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn018",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
          this.isFetchingPrivateNotes = false;
        })
  }

  createPrivateNote() {
    this.closeModalCreateUpdatePrivateNote();
    if (this.isObbligatoryDataInserted()) {
      this.isFetchingPrivateNotes = true;
      if (this.createPrivateNote$) {
        this.createPrivateNote$.unsubscribe();
      }

      let note = {
        comment: this.note.comment,
        motivation: this.note.motivation
      }
      this.createPrivateNote$ = this.selfAssessmentService.createPrivateNote([note])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn019",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj = {
              modalId: "pn021",
              title: this.translate.instant("privateNotes.modals.NOTE_CREATED"),
              text: this.translate.instant("privateNotes.modals.NOTE_CREATED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser()
          }
          this.isFetchingPrivateNotes = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn020",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingPrivateNotes = false;
          })
    }
  }

  updatePrivateNote() {
    this.closeModalCreateUpdatePrivateNote();
    if (this.isObbligatoryDataInserted()) {
      this.isFetchingPrivateNotes = true;
      if (this.updatePrivateNote$) {
        this.updatePrivateNote$.unsubscribe();
      }

      let note = {
        feedbackId: this.noteId,
        comment: this.note.comment,
        motivation: this.note.motivation
      }

      this.updatePrivateNote$ = this.selfAssessmentService.createPrivateNote([note])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj = {
              modalId: "pn022",
              title: this.translate.instant("privateNotes.modals.NOTE_UPDATED"),
              text: this.translate.instant("privateNotes.modals.NOTE_UPDATED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser()
          }
          this.isFetchingPrivateNotes = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn025",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingPrivateNotes = false;
          })
    }
  }

  deletePrivateNote() {
    this.closeModalCreateUpdatePrivateNote();
    if (this.isObbligatoryDataInserted()) {
      this.isFetchingPrivateNotes = true;
      if (this.updatePrivateNote$) {
        this.updatePrivateNote$.unsubscribe();
      }
      this.updatePrivateNote$ = this.selfAssessmentService.deletePrivateNote(this.runningYear.year, this.runningYear.yearCode, this.note)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj = {
              modalId: "pn022",
              title: this.translate.instant("privateNotes.modals.NOTE_UPDATED"),
              text: this.translate.instant("privateNotes.modals.NOTE_UPDATED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser()
          }
          this.isFetchingPrivateNotes = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn025",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingPrivateNotes = false;
          })
    }
  }

  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('generic.GO_BACK');
      } else {
        if (this.noteId) {
          return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.noteId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.YOU_ARE_INSERTING_A_NOTE');
    } else if (this.noteId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.YOU_ARE_UPDATING_A_NOTE');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.noteId && this.noteId.length) {
        return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      } else {
        return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      }
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE_BACK');
        } else {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE_BACK');
        }
      } else {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateNoteModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.UPDATE_NOTE_BACK');
        } else {
          return this.translate.instant('privateNotes.modals.INSERT_NOTE_BACK');
        }
      } else {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.note && this.note.comment && this.note.comment.trim().length > 0;
  }

  checkIfDataIsChanged() {
    return this.note && this.noteCopy && this.note.comment != this.noteCopy.comment;
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdatePrivateNote() {
    this.modalService.open('modalCreateUpdatePrivateNote');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdatePrivateNote() {
    this.modalService.close('modalCreateUpdatePrivateNote');
  }

  ngOnDestroy() {
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getPrivateNote$) {
      this.getPrivateNote$.unsubscribe();
    }
    if (this.createPrivateNote$) {
      this.createPrivateNote$.unsubscribe();
    }
    if (this.updatePrivateNote$) {
      this.updatePrivateNote$.unsubscribe();
    }
  }
}