<ng-container>
  <div class="main-container">
    <div class="rectangle-container">
      <!-- Card -->
      <div class="card-container">
        <!-- header card -->
        <card-feedback-header [icon]="'assets/img/icons/clipboard.svg'" [creationDate]="creationDateText"
          [hasAvatar]="true" [avatarRight]="true" [user]="note.senderUser"
          [title]="('feedbackSection.privateNotes.PRIVATE_NOTE' | translate) +  '<b> ' + note.senderUser?.surname + ' ' + note.senderUser?.forename + '</b>'"></card-feedback-header>
        <div class="divisor-line"></div>

        <!-- Card profile -->
        <div class="information-container">
          <div class="information-text-container">
            <!-- Sezione interna alla card -->
            <ng-container *ngFor="let text of textList">
              <!-- Titolo -->
              <p class="title">{{text.title}}</p>
              <!-- Descrizione -->
              <p class="desc">{{text.desc}}</p>
            </ng-container>

            <!-- Pulsante -->
            <ng-container *ngIf="note.feedbackId">
              <div class="action-footer">
                <!-- Modifica appunto -->
                <div class="actions-container">
                  <svg-icon [src]="'assets/img/icons/edit.svg'"></svg-icon>
                  <p translate="feedbackSection.privateNotes.EDIT_NOTE" (click)="emitOnEditNote(note.feedbackId)"></p>
                </div>
                <!-- Elimina appunto -->
                <div class="actions-container">
                  <svg-icon [src]="'assets/img/icons/trash-2.svg'"></svg-icon>
                  <p translate="feedbackSection.privateNotes.DELETE_NOTE" (click)="emitOnDeleteNote(note.feedbackId)">
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>