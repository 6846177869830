<page-container *ngIf="langs && langs.length">

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content">
    <div class="page-login-content">
      <ng-container *ngIf="!showLoader">
        <div class="login-wrapper">
          <svg-icon src="assets/img/icons/webranking-logo.svg" class="login-icon" [applyClass]="true"></svg-icon>
          <p class="login-title-text" translate="login.TITLE_PAGE"></p>
          <input-container
            [errorText]="(!emailValidate() && loginForm.email && loginForm.email.trim().length) ? ('login.EMAIL_ERROR_INSERTED' | translate) : ''"
            [tooltipModalMessage]="usernameModalMessage" [isValued]="loginForm.email && loginForm.email.trim().length"
            [isObbligatory]="true" title="{{'login.MAIL' | translate}}">
            <alloy-input autocomplete="on" (onIconClicked)="onLogin()" typeInput="email" [bindValue]="loginForm.email"
              (onModelChanged)="emailChanged($event)" placeholder="{{'login.MAIL_PLACEHOLDER' | translate}}">
            </alloy-input>
          </input-container>
          <input-container [tooltipModalMessage]="passwordModalMessage"
            [isValued]="loginForm.password && loginForm.password.trim().length" [isObbligatory]="true"
            title="{{'login.PASSWORD' | translate}}">
            <alloy-input autocomplete="on" (onIconClicked)="onLogin()" typeInput="password"
              [bindValue]="loginForm.password" (onModelChanged)="passwordChanged($event)"
              placeholder="{{'login.PASSWORD_PLACEHOLDER' | translate}}"></alloy-input>
          </input-container>
          <div class="button-login-container">
            <alloy-button [disabled]="!loginButtonIsActive() || !emailValidate()" type="primary default" size="large"
              label="{{'login.SIGN_IN' | translate}}" (onButtonClicked)="onLogin()">
            </alloy-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showLoader">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>